import type { HandRanking } from "../hand-evaluation/HandRanking";

export enum PreflopSuitednessEnum {
  DoubleSuited = "Double Suited",
  TwoFlush = "2 Flush",
  ThreeFlush = "3 Flush",
  FourFlush = "4 Flush",
  Rainbow = "Rainbow",
  // AceSingleSuited = "AceSingleSuited",
}

export type PreflopSuitednessType = `${PreflopSuitednessEnum}`;

export enum HoldemPreflopEnum {
  PocketPair = "Pocket Pair",
  Suited = "Suited",
  Offsuit = "Offsuit",
}

export type HoldemPreflopType = `${HoldemPreflopEnum}`;

export enum FilterOptionEnum {
  HandRankType = "Hand Ranking",
  Suitedness = "Suitedness",
  Pairedness = "Hole Card Pairs",
  HoldemPreflop = "Preflop",
  NumLoCards = "Lo Cards",
  PFWrapFilter = "PF Wraps",
}

export type FilterOptionType = `${FilterOptionEnum}`;

export enum PreflopPairednessEnum {
  NoPairs = "No Pairs",
  OnePair = "One Pair",
  TwoPair = "Two Pair",
  Trips = "Trips",
  Quads = "Quads",
}

export type PreflopPairednessType = `${PreflopPairednessEnum}`;

export enum PreflopWrapEnum {
  FourCard = "4-Card",
  FourCardOneGap = "4-Card, 1-gap",
  ThreeCardOnePair = "3-Card + pair",
}

export type PreflopWrapType = `${PreflopWrapEnum}`;

/**
 * This is the type that contains the filter.
 * Each property corresponds to a different filter.
 * More properties will be added later for more filtering options.
 *
 * Actual filtering is done in HandFilters.ts
 */
export type FilterSet = {
  [FilterOptionEnum.HandRankType]?: HandRanking[];

  // Omaha only!
  [FilterOptionEnum.Suitedness]?: PreflopSuitednessType[];
  [FilterOptionEnum.Pairedness]?: PreflopPairednessType[];
  [FilterOptionEnum.NumLoCards]?: number[];
  [FilterOptionEnum.PFWrapFilter]?: PreflopWrapType[];

  // Holdem only!
  [FilterOptionEnum.HoldemPreflop]?: HoldemPreflopType[];
};

/**
 * Types for providing a listing of available filters
 */
export type AvailableFilterListing = FilterGroup[];

export type FilterGroup = {
  group: string;
  filters: FilterListingEntry[];
};

export type FilterListingEntry = {
  name: FilterOptionType;
  options: FilterOptionArray;
};

type FilterOptionArray =
  | HoldemPreflopType[]
  | PreflopSuitednessType[]
  | PreflopPairednessType[]
  | HandRanking[]
  | PreflopWrapType[]
  | number[];
