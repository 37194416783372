import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/icons/google-logo.svg'
import _imports_1 from '@/assets/icons/facebook-logo.svg'


const _hoisted_1 = ["type"]
const _hoisted_2 = {
  key: 0,
  class: "button__icon",
  src: _imports_0,
  alt: "Google logo"
}
const _hoisted_3 = {
  key: 1,
  class: "button__icon",
  src: _imports_1,
  alt: "Facebook logo"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["button", { 'button--outline': $props.isOutline, 'button--disabled': $props.isDisabled, 'button--outline-disabled': $props.isOutline && $props.isDisabled, 'button--social': $props.isGoogle || $props.isFacebook }]),
    onClick: $setup.emitClick,
    type: $props.type ? $props.type : 'button'
  }, [
    ($props.isGoogle)
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _createCommentVNode("", true),
    ($props.isFacebook)
      ? (_openBlock(), _createElementBlock("img", _hoisted_3))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 10, _hoisted_1))
}