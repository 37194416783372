import { getCardsBeforeSeqPointer } from "@/features/cards/boardCards";
import type { MatrixRequestParams } from "@/types/matrix";

export const getMatrixParamsOnStudy = (
  sequence: string,
  numFinishedRounds: number,
  boardCards: string[],
  upCards: string[]
): [string, MatrixRequestParams] => {
  return [
    sequence,
    {
      boardCards: numFinishedRounds
        ? getCardsBeforeSeqPointer(numFinishedRounds, boardCards)
        : undefined,
      upCards,
    },
  ];
};
