import { onBeforeUnmount, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useFocusMode } from "@/composables/useFocusMode";
import routesName from "@/constants/routesName";

export const useKeyDown = () => {
  const route = useRoute();
  const { changeFocusModeState } = useFocusMode();

  const handleKeyDown = (e: KeyboardEvent) => {
    const activeElement = document.activeElement;
    const isActiveElementEditable = () => {
      return (
        activeElement?.tagName === "INPUT" ||
        activeElement?.tagName === "TEXTAREA" ||
        (activeElement instanceof HTMLElement &&
          activeElement.isContentEditable)
      );
    };
    const isValidRouteName = () => {
      if (route) {
        return [routesName.preflop, routesName.quiz, routesName.study].includes(
          route.name as string
        );
      }
      return false;
    };
    if (isActiveElementEditable() || !isValidRouteName()) {
      return;
    }
    if (e.key === "F" || e.key === "f") {
      changeFocusModeState();
    }
  };

  onMounted(() => {
    window.addEventListener("keydown", handleKeyDown);
  });

  onBeforeUnmount(() => {
    window.removeEventListener("keydown", handleKeyDown);
  });
};
