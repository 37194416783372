import { PokerActionsEnum } from "@/poker-query-lib/enums/poker-actions.enum";
import { deepCopy } from "@/utils/deepCopy";
import {
  getEmptyAction,
  getSkippedAction,
  isActionWithData,
  isBoardCardsAction,
  isFoldAction,
} from "@/features/sequence/action";

import type { SeqAction, SeqActionAny } from "@/types/seqAction";
import type { StateInfo } from "@/types/general";
import {
  isGameEndingActionName,
  isRoundEndingActionName,
} from "@/features/action/actionGeneral";

export const createSeqWithSkippedAndEmptyActions = (
  arr: SeqAction[],
  numOfPlayers: number
) => {
  const rowsResult: SeqActionAny[][] = [];
  let oneRowResult: SeqActionAny[] = [];
  const foldedPlayersIdxs: number[] = [];

  if (arr.length < numOfPlayers) {
    oneRowResult = deepCopy(arr);
    for (let i = 0; i < numOfPlayers - arr.length; i++) {
      oneRowResult.push(getEmptyAction());
    }
    return oneRowResult;
  } else {
    for (let i = 0; i < arr.length; i += numOfPlayers) {
      rowsResult.push(arr.slice(i, i + numOfPlayers));
    }

    for (const element of rowsResult) {
      for (let j = 0; j < element.length; j++) {
        if (isFoldAction(element[j])) {
          foldedPlayersIdxs.push(j);
        } else if (foldedPlayersIdxs.includes(j)) {
          element.splice(j, 0, getSkippedAction());
        }
      }
    }
    return rowsResult;
  }
};

export const checkPreviousFoldOfPlayer = (
  actionOrder: number,
  playersNum: number,
  arr: SeqActionAny[]
) => {
  return arr
    .filter((_, index) => index % playersNum === actionOrder % playersNum)
    .some(isFoldAction);
};

export const parseSeqStrToArr = (sequence: string) => {
  return sequence.match(/[br]\d*|f[/$]?|[ck][/$]?|d[0-5]/gm) || [];
};

export const getNumOfEmptyActionThatShouldBeAddedToSeq = (
  seqStr: string,
  playersNum: number
) => {
  const actionRegex = /[br]\d*|f[/$]?|[ck][/$]?|d[0-5]/gm;

  const rounds = seqStr.endsWith("/")
    ? seqStr.split(/(?<=\/)/).concat("")
    : seqStr.split(/(?<=\/)/);

  const totalInactivePlayers = (seqStr.match(/f/g) || []).length;

  const activePlayers = playersNum - totalInactivePlayers;

  const lastRound = rounds[rounds.length - 1] || "";

  const lastRoundActions = lastRound.match(actionRegex) || [];

  const actionsInLastRound = lastRoundActions.length;

  return activePlayers - actionsInLastRound - 1;
};

export const stackForLastCall = (stateInfo: StateInfo, playerIndex: number) => {
  const { roundContrib, remainingStack } = stateInfo;
  const newContrib = Math.max(...roundContrib);
  const outstanding = newContrib - roundContrib[playerIndex];
  return remainingStack[playerIndex] - outstanding;
};

export const contribForPlayer = (stateInfo: StateInfo, playerIdx: number) => {
  const copy = deepCopy(stateInfo);
  copy.roundContrib[playerIdx] = Math.max(...stateInfo.roundContrib);
  return copy.roundContrib[playerIdx];
};

export const isRoundOrGameEnd = (action: string) => {
  return isRoundEndingActionName(action) || isGameEndingActionName(action);
};

export const getNumFoldedActions = (seqStr: string) => {
  return seqStr.match(new RegExp(PokerActionsEnum.FOLD, "g"))?.length || 0;
};

export const getNumFinishedRounds = (idx: number, sequence: SeqActionAny[]) => {
  let res = 0;
  for (let i = 0; i < idx; i++) {
    const action = sequence[i];
    if (
      isActionWithData(action) &&
      isRoundEndingActionName(action.chosenAction)
    ) {
      res += 1;
    }
  }
  return res;
};

export const isPlayerHasFoldedActions = (
  action: SeqAction,
  sequence: SeqActionAny[]
) => {
  let res = false;
  for (const element of sequence) {
    if (!isBoardCardsAction(element) && isActionWithData(element)) {
      const playerIndex = action.stateInfo.playerIndex;
      const actionData = element;
      if (
        actionData.stateInfo.playerIndex === playerIndex &&
        isFoldAction(actionData)
      ) {
        res = true;
        break;
      }
    }
  }
  return res;
};
