import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { computed, onMounted, ref, watch } from "vue";
import MatrixCard from "@/components/common/MatrixCard.vue";
import FilterComponent from "@/components/common/FilterComponent.vue";

interface Props {
  reachTransparency: number;
}
interface Emits {
  (e: "set-reach-transparency", value: number): void;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'ReachTransparencySelect',
  props: {
    reachTransparency: {}
  },
  emits: ["set-reach-transparency"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

_useCssVars(_ctx => ({
  "1ec3e223": (tooltipPosition.value)
}))

const props = __props;

const emits = __emit;

const rangeInput = ref(null);
const rangeTooltip = ref(null);
const wrapper = ref(null);
const selectedReachTransparency = ref<number>(
  Math.round(props.reachTransparency * 100)
);
const tooltipPosition = computed(() => {
  const inputRect = rangeInput.value?.getBoundingClientRect();
  const tooltipRect = rangeTooltip.value?.getBoundingClientRect();
  const parentRect = wrapper.value?.getBoundingClientRect();

  const inputMax = 100;
  const inputMin = 0;

  const rangePercentage =
    (selectedReachTransparency.value - inputMin) / (inputMax - inputMin);
  const thumbCenter =
    inputRect.left + rangePercentage * (inputRect.width - 12) + 6; // Adjust for thumb width
  let tooltipLeft = thumbCenter - tooltipRect.width / 2;

  if (tooltipLeft < parentRect.left) {
    tooltipLeft = parentRect.left;
  } else if (tooltipLeft + tooltipRect.width > parentRect.right) {
    tooltipLeft = parentRect.right - tooltipRect.width;
  }

  return `${tooltipLeft - parentRect.left}px`;
});
const transparencyOptions = [
  { label: "0%", value: 0 },
  { label: "20%", value: 20 },
  { label: "40%", value: 40 },
  { label: "60%", value: 60 },
  { label: "100%", value: 100 },
];
const colorsForCell = ref<string>(
  "background: linear-gradient(to right, rgb(16, 125, 248) 0%, rgb(16, 125, 248) 33%, rgb(3, 178, 137) 33%, rgb(3, 178, 137) 66%, rgb(231, 10, 153) 66%, rgb(231, 10, 153) 100%)"
);

const generateRangeBackground = () => {
  if (rangeInput.value) {
    const progress =
      (selectedReachTransparency.value / rangeInput.value.max) * 100;
    rangeInput.value.style.background = `linear-gradient(to right, #ffffff ${progress}%, #ffffff4d ${progress}%)`;
  }
};

watch(
  () => selectedReachTransparency.value,
  (newV) => {
    emits("set-reach-transparency", newV / 100);
    generateRangeBackground();
  }
);
watch(
  () => props.reachTransparency,
  (newV) => {
    selectedReachTransparency.value = Math.round(newV * 100);
  }
);
onMounted(() => {
  generateRangeBackground();
});

const __returned__ = { props, emits, rangeInput, rangeTooltip, wrapper, selectedReachTransparency, tooltipPosition, transparencyOptions, colorsForCell, generateRangeBackground, computed, onMounted, ref, watch, MatrixCard, FilterComponent }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})