import { storeToRefs } from "pinia";
import { parseQueryResponse } from "@/poker-query-lib";
import { useParsedLibDataStore } from "@/stores/parsedLibData";
import { useGameStore } from "@/stores/game";
import pokerApi from "@/services/pokerApi";

import type { QueryResponse } from "@/poker-query-lib";
import type { MatrixRequestParams } from "@/types/matrix";

export const getMatrix = async (
  betSequence: string,
  params?: MatrixRequestParams
) => {
  const { loading, parsedResponseInitial } = storeToRefs(
    useParsedLibDataStore()
  );
  const gameStore = useGameStore();
  const { fetchMatrix, sendMatrixActivityConfig } = pokerApi;
  loading.value = true;
  let responseCopy = {};
  const startTime = new Date().toISOString();
  parsedResponseInitial.value = await fetchMatrix(betSequence, {
    boardCards: params?.boardCards,
    upCards: params?.upCards,
  })
    .then((response) => {
      if (response.body && gameStore.configByConnectionName) {
        responseCopy = response.clone();
        return parseQueryResponse(
          gameStore.configByConnectionName.gameType,
          response.body,
          (n) => n
        );
      }
      return {} as QueryResponse;
    })
    .catch(() => {
      loading.value = false;
      return;
    });
  if (Object.keys(responseCopy).length) {
    sendMatrixActivityConfig({
      responseCopy,
      startTime,
      betSequence,
      boardCards: params?.boardCards,
    });
  }
};
