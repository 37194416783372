import { computed, ref } from "vue";
import {
  getCardsBeforeSeqPointer,
  getNumCardsByRound,
} from "@/features/cards/boardCards";
import { getNumFinishedRounds } from "@/features/sequence/utils";
import { isBoardCardsAction } from "@/features/sequence/action";

import type { Ref } from "vue";
import type { SeqActionAny } from "@/types/seqAction";
import { CardSuitEnum } from "@/enums/cardEnum";
import type { CardWithIdx } from "@/types/card";
import { deepCopy } from "@/utils/deepCopy";

export function useBoardCards(
  seqPointer: Ref<number>,
  sequence: Ref<SeqActionAny[]>
) {
  const boardCards = ref<string[]>([]);
  const maxNumTableCards = ref(3);
  const actionBeforeCardsModal = ref("");
  const modalBoardCards = ref<string[]>([]);
  const isOpenBoardCardsModal = ref(false);
  const nonUnselectableCards = ref<string[]>([]);
  const cardsActionIdx = ref(-1);

  const setBoardCards = (cards: string[]) => {
    boardCards.value = deepCopy(cards);
    modalBoardCards.value = deepCopy(cards);
  };

  const setCardsActionIdx = (idx: number) => {
    cardsActionIdx.value = idx;
  };

  const cardsModalIsDisabledConfirm = computed(() => {
    return (
      modalBoardCards.value.filter((card) => card !== CardSuitEnum.empty)
        .length < maxNumTableCards.value
    );
  });

  const setNonUnselectableCards = (numFinishedRounds: number) => {
    const map: Record<string, string[]> = {
      0: [],
      1: boardCards.value.slice(0, 3),
      2: boardCards.value.slice(0, 4),
    };
    nonUnselectableCards.value = map[numFinishedRounds] || [];
  };

  const updateBoardCards = (
    idx = cardsActionIdx.value,
    withCurrent = false
  ) => {
    const numFinishedRounds = getNumFinishedRounds(idx, sequence.value);
    const spliceIdx = getNumCardsByRound(numFinishedRounds, withCurrent);
    if (typeof spliceIdx === "number") {
      boardCards.value.splice(spliceIdx);
      modalBoardCards.value.splice(spliceIdx);
    }
  };

  const getBoardCards = () => {
    let lastCardAction = null;

    for (let i = sequence.value.length - 1; i >= 0; i--) {
      if (isBoardCardsAction(sequence.value[i])) {
        lastCardAction = sequence.value[i];
        break;
      }
    }

    return lastCardAction
      ? isBoardCardsAction(lastCardAction)
        ? lastCardAction.boardCards
        : []
      : undefined;
  };
  const getBoardCardsFromSeq = (seqArr: SeqActionAny[]) => {
    let lastCardAction = null;

    for (let i = seqArr.length - 1; i >= 0; i--) {
      if (isBoardCardsAction(seqArr[i])) {
        lastCardAction = seqArr[i];
        break;
      }
    }

    return lastCardAction
      ? isBoardCardsAction(lastCardAction)
        ? lastCardAction.boardCards
        : []
      : undefined;
  };

  const boardCardsModalHandler = () => {
    boardCards.value = deepCopy(modalBoardCards.value);
    updateBoardCards();
  };

  const initBoardCards = (boardCardsArr?: string[]) => {
    actionBeforeCardsModal.value = "";
    maxNumTableCards.value = boardCardsArr ? boardCardsArr.length : 3;
    setBoardCards(boardCardsArr ? boardCardsArr : []);
  };

  const openBoardCardsModal = (idx: number, actionName?: string) => {
    const numFinishedRounds = getNumFinishedRounds(idx - 1, sequence.value);
    modalBoardCards.value = boardCards.value.slice(0, numFinishedRounds + 3);
    maxNumTableCards.value = numFinishedRounds + 3;
    setNonUnselectableCards(numFinishedRounds);
    actionBeforeCardsModal.value = actionName ? actionName : "";
    isOpenBoardCardsModal.value = true;
    cardsActionIdx.value = idx;
  };
  const closeBoardCardsModal = () => {
    isOpenBoardCardsModal.value = false;
    modalBoardCards.value = deepCopy(boardCards.value);
  };
  const resetBoardCardsModal = () => {
    modalBoardCards.value.forEach((card, idx) => {
      if (!nonUnselectableCards.value.includes(card)) {
        modalBoardCards.value[idx] = CardSuitEnum.empty;
      }
    });
  };
  const selectCardInBoardCardsModal = (card: string) => {
    const emptyCardIdx = modalBoardCards.value.indexOf(CardSuitEnum.empty);
    const regularCardIdx = modalBoardCards.value.indexOf(card);
    if (emptyCardIdx > -1 && !modalBoardCards.value.includes(card)) {
      modalBoardCards.value[emptyCardIdx] = card;
    } else if (regularCardIdx !== -1) {
      modalBoardCards.value[regularCardIdx] = CardSuitEnum.empty;
    } else {
      modalBoardCards.value.push(card);
    }
  };
  const removeCardInBoardCardsModal = (cardData: CardWithIdx) => {
    modalBoardCards.value[cardData.idx] = CardSuitEnum.empty;
  };

  const getBoardCardsForRequest = () => {
    const numFinishedRounds = getNumFinishedRounds(
      seqPointer.value,
      sequence.value
    );
    return getCardsBeforeSeqPointer(numFinishedRounds, boardCards.value);
  };

  return {
    actionBeforeCardsModal,
    boardCards,
    boardCardsModalHandler,
    cardsActionIdx,
    cardsModalIsDisabledConfirm,
    closeBoardCardsModal,
    getBoardCards,
    getBoardCardsForRequest,
    getBoardCardsFromSeq,
    initBoardCards,
    isOpenBoardCardsModal,
    maxNumTableCards,
    modalBoardCards,
    nonUnselectableCards,
    openBoardCardsModal,
    removeCardInBoardCardsModal,
    resetBoardCardsModal,
    selectCardInBoardCardsModal,
    setBoardCards,
    setCardsActionIdx,
    setNonUnselectableCards,
    updateBoardCards,
  };
}
