import { defineComponent as _defineComponent } from 'vue'
import { computed } from "vue";
import type { Filter } from "@/features/filters";
import {
  convertStackToBb,
  convertUserForStudy,
} from "@/utils/utilityFunctions";

type ModelValue = Filter[] | null | string | number;

interface Props {
  type?: string;
  modelValue: ModelValue;
  value: Filter | string | number;
  label: string | number;
  normalizingUnit?: number;
  canUnselect?: boolean;
  isStudyPlayer?: boolean;
  isGameSetting?: boolean;
  isStack?: boolean;
  isDisabled?: boolean;
}
interface Emits {
  (e: "update:modelValue", value: ModelValue): () => void;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'FilterComponent',
  props: {
    type: {},
    modelValue: {},
    value: {},
    label: {},
    normalizingUnit: {},
    canUnselect: { type: Boolean },
    isStudyPlayer: { type: Boolean },
    isGameSetting: { type: Boolean },
    isStack: { type: Boolean },
    isDisabled: { type: Boolean }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const emits = __emit;

const model = computed({
  get() {
    return props.modelValue;
  },
  set(value: ModelValue) {
    emits("update:modelValue", value);
  },
});

const clearSelection = (filter: string | number) => {
  if (props.modelValue === filter && props.isGameSetting && props.canUnselect) {
    emits("update:modelValue", null);
  }
};
const defineOutput = (label: string | number) => {
  if (props.isStack) {
    return convertStackToBb(label, props.normalizingUnit);
  } else if (props.isStudyPlayer) {
    return convertUserForStudy(label);
  } else {
    return label;
  }
};

const __returned__ = { props, emits, model, clearSelection, defineOutput, computed, get convertStackToBb() { return convertStackToBb }, get convertUserForStudy() { return convertUserForStudy } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})