import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.matrixData.length)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["matrix__wrapper", { 'matrix__wrapper--shortdeck': $props.allStartingHands.length === 81, 'matrix__wrapper--third-matrix': $props.isThirdMatrix }])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.allStartingHands, (item, index) => {
          return (_openBlock(), _createBlock($setup["MatrixCard"], {
            key: index,
            item: item,
            "without-item": $props.withoutItem,
            "is-single-matrix": $props.isSingleMatrix,
            "action-of-card": $setup.getActionsOfCard($props.matrixData[index]),
            reach: $setup.getReachValue(index),
            "is-cell-hidden": $setup.isCellHidden(index),
            "is-cell-unavailable": !$props.matrixData[index] && !$props.omitCellModifiers,
            selected: $props.matrixData[index]?.selected,
            "reach-transparency-for-preview": $props.reachTransparencyForPreview,
            "is-text-with-shadow": $setup.isTextNeedShadow($props.matrixData[index]),
            "is-full-height-cell": $props.isThirdMatrix,
            onClick: ($event: any) => ($setup.handleMatrixClick(index, $props.matrixData[index], $props.matrixData[index]?.selected))
          }, null, 8, ["item", "without-item", "is-single-matrix", "action-of-card", "reach", "is-cell-hidden", "is-cell-unavailable", "selected", "reach-transparency-for-preview", "is-text-with-shadow", "is-full-height-cell", "onClick"]))
        }), 128))
      ], 2))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["matrix__wrapper", { 'matrix__wrapper--third-matrix': $props.isThirdMatrix }])
      }, [
        (!$props.hideNoDataScreen)
          ? (_openBlock(), _createBlock($setup["NoDataOverlay"], {
              key: 0,
              "is-dimmed": true,
              "show-label": false
            }))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.allStartingHands, (item, index) => {
          return (_openBlock(), _createBlock($setup["MatrixCard"], {
            class: "card--disabled",
            "is-single-matrix": $props.isSingleMatrix,
            key: index,
            item: item,
            "is-full-height-cell": $props.isThirdMatrix
          }, null, 8, ["is-single-matrix", "item", "is-full-height-cell"]))
        }), 128))
      ], 2))
}