import { defineComponent as _defineComponent } from 'vue'
interface Props {
  isDisabled?: boolean;
  isGoogle?: boolean;
  isFacebook?: boolean;
  isOutline?: boolean;
  type?: string;
}
interface Emits {
  (e: "on-click"): void;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AppButton',
  props: {
    isDisabled: { type: Boolean },
    isGoogle: { type: Boolean },
    isFacebook: { type: Boolean },
    isOutline: { type: Boolean },
    type: {}
  },
  emits: ["on-click"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const emits = __emit;

const emitClick = () => {
  if (!props.isDisabled) {
    emits("on-click");
  }
};

const __returned__ = { props, emits, emitClick }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})