import type { Card } from "../Combos";
import { CanonPair, Pair } from "../Combos";
import { NodeAvgs, NodeGroup } from "../DecisionStrategy";
import StrategyBuckets from "../StrategyBuckets";
import type { Matrix } from "../interfaces/matrix.interface";
import type { PokerBettingAction } from "../types/poker-betting-action.type";
import type { MatrixData } from "./MatrixData";
import {
  CellRenderData,
  NONREACHING_PROBS,
  SingleMatrixData,
} from "./MatrixData";

/**
 * The following are the possible preflop hands Razz.
 * They are sorted from low to high where Ace is low. There are 91 cells in total.
 * The upper half indices where column# > row# don't correspond to any hands.
 * The pairs are shown on the last row (since these are worse razz hands).
 *
 *     A  2  3  4  5  6  7  8  9  T  J  Q  K
 *  +---------------------------------------+
 * 2| 2A                                    |
 * 3| 3A 32                                 |
 * 4| 4A 42 43                              |
 * 5| 5A 52 53 54                           |
 * 6| 6A 62 63 64 65                        |
 * 7| 7A 72 73 74 75 76                     |
 * 8| 8A 82 83 84 85 86 87                  |
 * -+---------------------------------------| (8-qualifier divisor)
 * 9| 9A 92 93 94 95 96 97 98               |
 * T| TA T2 T3 T4 T5 T6 T7 T8 T9            |
 * J| JA J2 J3 J4 J5 J6 J7 J8 J9 JT         |
 * Q| QA Q2 Q3 Q4 Q5 Q6 Q7 Q8 Q9 QT QJ      |
 * K| KA K2 K3 K4 K5 K6 K7 K8 K9 KT KJ KQ   |
 * P| AA 22 33 44 55 66 77 88 99 TT JJ QQ KK|
 *  +---------------------------------------+
 */

/**
 * Maps from a matrix cell (row, column) to the appropriate text label for
 * the matrix cell in Razz.
 */
export function razzMatrixLabels(row: number, column: number): string | null {
  const ranks = "A23456789TJQK";
  if (row === 12) {
    return ranks[column] + ranks[column];
  }
  if (row < column) {
    return null;
  }
  const hi = row + 1;
  const lo = column;
  return ranks[hi] + ranks[lo];
}

export class RazzTriangle implements MatrixData {
  actionList: PokerBettingAction[];
  selection: number | undefined;

  stratFull = new StrategyBuckets(169);

  renderData: SingleMatrixData;

  hoverData: NodeGroup | undefined;
  totalSummary: NodeAvgs = new NodeAvgs();

  boardCards: Card[] = [];
  strat: NodeGroup | undefined;
  selectedStrat: NodeGroup | undefined;

  numRows: number;

  /**
   * Assign each pair to the correct cell in the razz matrix.
   *
   *     A  2  3  4  5  6  7  8  9  T  J  Q  K
   *  +---------------------------------------+
   * 2| 2A                                    |
   * 3| 3A 32                                 |
   * 4| 4A 42 43                              |
   * 5| 5A 52 53 54                           |
   * 6| 6A 62 63 64 65                        |
   * 7| 7A 72 73 74 75 76                     |
   * 8| 8A 82 83 84 85 86 87                  |
   * -+---------------------------------------| (8-qualifier divisor)
   * 9| 9A 92 93 94 95 96 97 98               |
   * T| TA T2 T3 T4 T5 T6 T7 T8 T9            |
   * J| JA J2 J3 J4 J5 J6 J7 J8 J9 JT         |
   * Q| QA Q2 Q3 Q4 Q5 Q6 Q7 Q8 Q9 QT QJ      |
   * K| KA K2 K3 K4 K5 K6 K7 K8 K9 KT KJ KQ   |
   * P| AA 22 33 44 55 66 77 88 99 TT JJ QQ KK|
   *  +---------------------------------------+
   */
  cellForPair(pair: CanonPair): number {
    // Convert ranks from Ace high to Ace low!
    const r0 = (pair.ranks[0] + 1) % 13;
    const r1 = (pair.ranks[1] + 1) % 13;
    const lowRank = Math.min(r0, r1);
    const hiRank = Math.max(r0, r1);
    // Pairs are on the last row!
    if (lowRank === hiRank) {
      return 13 * 12 + lowRank;
    } else {
      //Hi rank is at least TWO (1), can't be ACE since we're not paired and this is higher.
      //Rows are indexed by the higher rank, TWO to KING. This is hiRank - 1.
      return 13 * (hiRank - 1) + lowRank;
    }
  }

  constructor(actionList: PokerBettingAction[]) {
    this.numRows = 13;
    this.actionList = actionList;
    this.renderData = new SingleMatrixData(13, 13);
  }

  getActionListing(): string[] | undefined {
    return this.actionList;
  }

  // board: Card[] is to match MatrixData interface.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setStrategy(strategy: NodeGroup, board: Card[]) {
    this.totalSummary.clear();
    this.strat = strategy;
    this.renderData = new SingleMatrixData(13, 13);

    this.stratFull = new StrategyBuckets(13 * 13);

    for (const node of strategy.nodeList) {
      this.totalSummary.addNode(node);
      const pair = node.holecards;
      if (pair instanceof Pair) {
        const canPair = CanonPair.get(pair);
        this.stratFull.add(this.cellForPair(canPair), node);
      }
    }
    for (const pair of strategy.nonReaching) {
      this.totalSummary.addNonReaching();
      if (pair instanceof Pair) {
        const canPair = CanonPair.get(pair);
        const cell = this.cellForPair(canPair);
        this.stratFull.addNonReaching(cell, pair);
      }
    }

    //update renderdata!
    this.updateRenderData();
  }

  getSelectedStrat(): NodeGroup {
    return this.selectedStrat || new NodeGroup();
  }

  getSuitMatrixCell(pair: Pair): number {
    const s1 = 3 - pair.cards[0].suit;
    const s2 = 3 - pair.cards[1].suit;
    return 4 * s1 + s2;
  }

  updateStrategy() {
    this.updateRenderData();
  }

  updateRenderData() {
    this.updateSummaryInfo();

    if (this.stratFull) {
      for (let i = 0; i < 169; i++) {
        this.updateRenderDataForBucket(i);
      }
      this.renderData.selected = this.selection;
    }
  }

  updateRenderDataForBucket(index: number) {
    const bucket = this.stratFull?.data[index];
    this.renderData.cells[index] = NONREACHING_PROBS;
    if (bucket) {
      const { probs, ev } = bucket.getAvg();
      if (probs) {
        const cellData = new CellRenderData(
          this.actionList,
          probs,
          bucket.getAvg().reach,
          ev ?? 0,
          index === this.selection
        );
        this.renderData.cells[index] = cellData;
      }
    }
  }

  updateSummaryInfo() {
    if (this.selection !== undefined) {
      this.selectedStrat = this.stratFull?.data[this.selection];
    } else {
      this.selectedStrat = this.strat;
    }
  }

  getRenderData(): [Matrix] {
    return [this.renderData];
  }

  handleClicks(id: number, cell: number): void {
    // point: [number, number]
    // const cell = point[1] * 13 + point[0]
    //Clicking same cell will clear selection
    if (this.selection === cell) {
      this.selection = undefined as undefined | number;
    } else {
      this.selection = cell;
    }
    this.updateStrategy();
  }

  handleHover(id: number, point: [number, number] | undefined): void {
    if (point) {
      const n = id === 0 ? 13 : 4; //dimensions of hovered matrix
      const [x, y] = point;
      const i = n * y + x;
      if (id === 0 && this.selection === undefined) {
        this.hoverData = this.stratFull.data[i];
      }
    } else {
      this.hoverData = undefined as NodeGroup | undefined;
    }
  }

  getHoverData(): NodeGroup | undefined {
    return this.hoverData;
  }
}
