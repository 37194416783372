import { contribForPlayer, stackForLastCall } from "@/features/sequence/utils";

import type {
  PlayerWithAdditionalInfo,
  PokerTablePlayerBox,
  PokerTablePlayerWithStyles,
} from "@/types/player";
import type { StateInfo } from "@/types/general";

const quizPageActionButtonData = {
  fontSize: {
    max: 24,
    min: 16,
  },
  height: {
    max: 66,
    min: 44,
  },
};
const quizPageHandCardData = {
  fontSize: {
    max: 66,
    min: 38,
  },
  width: {
    max: 64,
    min: 34,
  },
  heightRatio: 1.375,
};

export const playerCardData = {
  width: {
    max: 30,
    min: 20,
  },
  heightRatio: 1.4,
  bottom: -5,
  margin: "",
  zIndex: 0,
};

export const getPlayerStackWithContrib = (
  playerIdx: number,
  stateInfo: StateInfo | undefined,
  isCallEnd: boolean
) => {
  if (stateInfo) {
    const { remainingStack, roundContrib } = stateInfo;
    if (isCallEnd && playerIdx === stateInfo.playerIndex) {
      return {
        stack: stackForLastCall(stateInfo, playerIdx),
        contrib: contribForPlayer(stateInfo, playerIdx),
      };
    } else {
      return {
        stack: remainingStack[playerIdx],
        contrib: roundContrib[playerIdx],
      };
    }
  }
  return {
    stack: null,
    contrib: 0,
  };
};

export const actionButtonsStyles = (
  MAX_POKER_TABLE_WIDTH: number,
  quizPokerTableRect: DOMRectReadOnly | null
) => {
  if (!quizPokerTableRect) {
    return {
      fontSize: "",
      height: "",
    };
  }
  const { fontSize, height } = quizPageActionButtonData;
  const fontSizeRatio = MAX_POKER_TABLE_WIDTH / fontSize.max;
  const heightRatio = MAX_POKER_TABLE_WIDTH / height.max;

  return {
    fontSize: `${Math.round(
      Math.max(quizPokerTableRect.width / fontSizeRatio, fontSize.min)
    )}px`,
    height: `${Math.round(
      Math.max(quizPokerTableRect.width / heightRatio, height.min)
    )}px`,
  };
};

export const handCardStyles = (
  MAX_POKER_TABLE_WIDTH: number,
  quizPokerTableRect: DOMRectReadOnly | null
) => {
  if (!quizPokerTableRect) {
    return {
      fontSize: "",
      height: "",
      transition: "",
      width: "",
    };
  }
  const { width, fontSize, heightRatio } = quizPageHandCardData;
  const fontSizeRatio = MAX_POKER_TABLE_WIDTH / fontSize.max;
  const widthRatio = MAX_POKER_TABLE_WIDTH / width.max;
  const newWidth = Math.max(width.min, quizPokerTableRect.width / widthRatio);
  return {
    fontSize: `${Math.round(
      Math.max(quizPokerTableRect.width / fontSizeRatio, fontSize.min)
    )}px`,
    height: `${Math.round(newWidth * heightRatio)}px`,
    transition: "none",
    width: `${Math.round(newWidth)}px`,
  };
};

export const getPLayerCardDynamicWidth = (
  MAX_POKER_TABLE_WIDTH: number,
  quizPokerTableRect: DOMRectReadOnly | null,
  cardData: { max: number; min: number }
) => {
  if (!quizPokerTableRect) {
    return 0;
  } else {
    const { max, min } = cardData;
    const widthRatio = MAX_POKER_TABLE_WIDTH / max;
    const newWidth = Math.max(min, quizPokerTableRect.width / widthRatio);
    return Math.round(newWidth);
  }
};

interface PlayerDataWithStylesCardSettings {
  width: number;
  heightRatio: number;
}

export const playerDataWithStyles = (
  player: PlayerWithAdditionalInfo,
  styles: PokerTablePlayerBox,
  cardSettings: PlayerDataWithStylesCardSettings
): PokerTablePlayerWithStyles => {
  const playerWidth = parseInt(styles.player.width);
  const playerXSideOffset = 5;
  const cardsNum = player.cardList.length;
  const cardLeftOffset = () => {
    const playerWidthForOffset =
      playerWidth - cardSettings.width - playerXSideOffset * 2;
    const cardsNumForOffset = cardsNum - 1;
    const diff = playerWidthForOffset - cardsNumForOffset * cardSettings.width;
    const offset = diff / cardsNumForOffset;
    if (offset > 0) {
      return "0";
    }
    return `${Math.round(offset)}px`;
  };
  const extendedCardList = player.cardList.map((card, idx) => ({
    isBack: card.isBack,
    value: card.value,
    type: card.type,
    width: `${cardSettings.width}px`,
    heightRatio: cardSettings.heightRatio,
    bottom: "-6px",
    left: idx === 0 ? "0" : cardLeftOffset(),
  }));
  return {
    ...player,
    styles: {
      cardList: extendedCardList,
      chip: styles.chip,
      dealerBtn: styles.dealerBtn,
      player: styles.player,
    },
  };
};

export const getPlayerCardStyles = (
  MAX_POKER_TABLE_WIDTH: number,
  quizPokerTableRect: DOMRectReadOnly | null,
  cardData = playerCardData
) => {
  if (!quizPokerTableRect) {
    return {
      bottom: "",
      height: "",
      width: "",
      overlap: "",
    };
  }
  const { width, heightRatio, margin, bottom, zIndex } = cardData;
  const widthRatio = MAX_POKER_TABLE_WIDTH / width.max;
  const newWidth = Math.max(width.min, quizPokerTableRect.width / widthRatio);
  return {
    bottom: `${bottom}px`,
    height: `${Math.round(newWidth * heightRatio)}px`,
    width: `${Math.round(newWidth)}px`,
    overlap: "0",
    margin,
    "z-index": zIndex,
  };
};
