import { ref } from "vue";
import { defineStore } from "pinia";
import type { AccountSettings } from "@/types/account";
import userApi from "@/services/userApi";
import { assignValues } from "@/utils/utilityFunctions";

export interface RaiseColors {
  default: string;
  small: string;
  medium: string;
  large: string;
  overbet: string;
}
export interface DrawColors {
  0: string;
  1: string;
  2: string;
  3: string;
  4: string;
  5: string;
}
export interface ActionColors {
  fold: string;
  call: string;
  check: string;
  raise: RaiseColors;
  allIn: string;
  draw: DrawColors;
}
export type ActionNameForColorSet = keyof ActionColors | keyof RaiseColors;

export const useAccountStore = defineStore(
  "account",
  () => {
    const createDefaultSettings = (): AccountSettings => ({
      cardsDesign: "variant1",
      reachTransparency: 0.2,
      actionColors: {
        fold: "rgb(16, 125, 248)",
        call: "rgb(3, 178, 137)",
        check: "rgb(3, 178, 137)",
        raise: {
          default: "rgb(231, 10, 153)",
          small: "rgb(231, 10, 153)",
          medium: "rgb(231, 10, 101)",
          large: "rgb(231, 10, 66)",
          overbet: "rgb(231, 10, 48)",
        },
        allIn: "rgb(255, 128, 35)",
        draw: {
          0: "rgb(246, 20, 21)",
          1: "rgb(58, 73, 218)",
          2: "rgb(73, 200, 88)",
          3: "rgb(255, 93, 44)",
          4: "rgb(161, 8, 255)",
          5: "rgb(7, 225, 203)",
        },
      },
    });
    const settings = ref<AccountSettings>(createDefaultSettings());
    const defaultSettings = ref<AccountSettings>(createDefaultSettings());

    const setDefaultSettings = () => {
      assignValues<AccountSettings>(settings.value, defaultSettings.value);
      userApi.updateUser({ settings: settings.value });
    };
    const setSettings = (
      settingsObj: AccountSettings,
      isSettingsFromApi = false
    ) => {
      assignValues<AccountSettings>(settings.value, settingsObj);
      if (!isSettingsFromApi) {
        userApi.updateUser({ settings: settings.value });
      }
    };

    return {
      defaultSettings,
      setDefaultSettings,
      setSettings,
      settings,
    };
  },
  {
    persist: {
      paths: ["settings"],
    },
  }
);
