export default {
  allIn: "#ff8023",
  call: "#03b289",
  fold: "#107df8",
  raise: "#e70a99",
  reach: "#757678",
  seqAction: "#3b3e44",
  actionGrade: {
    best: "115, 204, 119",
    blunder: "227, 72, 66",
    correct: "115, 204, 119",
    inaccuracy: "255, 165, 0",
    wrong: "227, 72, 66",
  },
  chip: {
    "0.01": "#e59319",
    "0.05": "#40bf73",
    "0.025": "#1ab5e5",
    "1": "#1a80e5",
    "5": "#e51919",
    "25": "#73e51a",
    "100": "#3b3e44",
  },
  tablePlayerShadow: {
    active: "#0080ff",
    default: "#71767d",
    folded: "transparent",
  },
};
