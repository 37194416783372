enum GameMode {
  spot = "spot",
  street = "street",
  fullHand = "fullHand",
}

enum BoardMode {
  current = "current",
  random = "random",
}
enum ActionGradeEnum {
  best = "best",
  correct = "correct",
  inaccuracy = "inaccuracy",
  wrong = "wrong",
  blunder = "blunder",
  unknown = "unknown",
}

export { ActionGradeEnum, GameMode, BoardMode };
