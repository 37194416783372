import type { Card, Combo } from "../Combos";
import { getCardArray, Pair, Rank } from "../Combos";
import {
  HandRankingEnum,
  evaluateHoldem,
  evaluateOmaha,
  getRankingType,
} from "../hand-evaluation/HandRanking";
import type {
  FilterSet,
  HoldemPreflopType,
  PreflopPairednessType,
  PreflopSuitednessType,
  PreflopWrapType,
} from "../types/filter-types";
import {
  PreflopPairednessEnum,
  FilterOptionEnum,
  HoldemPreflopEnum,
  PreflopSuitednessEnum,
  PreflopWrapEnum,
} from "../types/filter-types";

export type Filter = {
  passes: (combo: Combo, board: Card[]) => boolean;
};

type PreflopFilter = {
  passes: (combo: Combo) => boolean;
};

class AndFilter implements Filter {
  filterList: Filter[] = [];

  // Is it okay to add to filterList(postflop) and call with extra arguments???
  //  PreflopFilters don't take board.
  // preflop: PreflopFilter[] = [];

  passes(combo: Combo, board: Card[]): boolean {
    for (const filter of this.filterList) {
      if (!filter.passes(combo, board)) {
        return false;
      }
    }
    return true;
  }
}

/**
 * Omaha suitedness filters
 */
class OmahaSuitFilterGroup implements PreflopFilter {
  filters: SuitednessFilter[];

  constructor(options: PreflopSuitednessType[]) {
    this.filters = options.map((opt) => suitednessFilterMap[opt]);
  }

  passes(combo: Combo): boolean {
    if (combo instanceof Uint8Array) {
      const suitCounts = computeSuitCounts(getCardArray(combo));
      if (this.filters.find((f) => f(suitCounts))) {
        return true;
      }
    }
    return false;
  }
}

type SuitednessFilter = (suitCounts: SuitCounts) => boolean;

const suitednessFilterMap: {
  [key in PreflopSuitednessEnum]: SuitednessFilter;
} = {
  [PreflopSuitednessEnum.DoubleSuited]: (suitCounts: SuitCounts) =>
    suitCounts.suitedPairs === 2,
  [PreflopSuitednessEnum.TwoFlush]: (suitCounts: SuitCounts) =>
    suitCounts.max === 2 && suitCounts.suitedPairs === 1,
  [PreflopSuitednessEnum.ThreeFlush]: (suitCouns: SuitCounts) =>
    suitCouns.max === 3,
  [PreflopSuitednessEnum.FourFlush]: (suitCouns: SuitCounts) =>
    suitCouns.max === 4,
  [PreflopSuitednessEnum.Rainbow]: (suitCouns: SuitCounts) =>
    suitCouns.max === 1,
};

type SuitCounts = {
  counts: number[];
  max: number;
  suitedPairs: number;
};

function computeSuitCounts(hand: Card[]): SuitCounts {
  const counts = [0, 0, 0, 0];
  for (const c of hand) {
    counts[c.suit]++;
  }

  let suitedPairs = 0;
  let max = 0;
  for (const n of counts) {
    if (n === 2) {
      suitedPairs++;
    }
    if (n > max) {
      max = n;
    }
  }

  return { counts, max, suitedPairs };
}

/**
 * Omaha pairedness filters
 */
class OmahaPairednessFilterGroup implements PreflopFilter {
  filters: OmahaPairednessFilter[];

  constructor(options: PreflopPairednessType[]) {
    this.filters = options.map((opt) => omahaPairednessFilterMap[opt]);
  }

  passes(combo: Combo): boolean {
    if (combo instanceof Uint8Array) {
      const rankCounts = computeRankCounts(getCardArray(combo));
      if (this.filters.find((f) => f(rankCounts))) {
        return true;
      }
    }
    return false;
  }
}

type OmahaPairednessFilter = (rankCounts: RankCounts) => boolean;

const omahaPairednessFilterMap: {
  [key in PreflopPairednessEnum]: OmahaPairednessFilter;
} = {
  [PreflopPairednessEnum.NoPairs]: (ranks: RankCounts) => ranks.max === 1,
  [PreflopPairednessEnum.OnePair]: (ranks: RankCounts) => ranks.pairs === 1,
  [PreflopPairednessEnum.TwoPair]: (ranks: RankCounts) => ranks.pairs === 2,
  [PreflopPairednessEnum.Trips]: (ranks: RankCounts) => ranks.max === 3,
  [PreflopPairednessEnum.Quads]: (ranks: RankCounts) => ranks.max === 4,
};

type RankCounts = {
  counts: number[];
  pairs: number;
  max: number;
};

function computeRankCounts(hand: Card[]): RankCounts {
  const counts = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  for (const c of hand) {
    counts[c.rank]++;
  }

  let pairs = 0;
  let max = 0;
  for (const n of counts) {
    if (n === 2) {
      pairs++;
    }
    if (n > max) {
      max = n;
    }
  }

  return { counts, max, pairs };
}

/**
 * Low card filters
 */
class LoCardFilterGroup implements PreflopFilter {
  options: Set<number>;

  constructor(options: number[]) {
    this.options = new Set(options);
  }

  passes(combo: Combo): boolean {
    if (combo instanceof Uint8Array) {
      //NOTE in java implementation, these counts are baked right into the quads when we list them!
      let count = 0;
      for (const card of getCardArray(combo)) {
        if (card.rank === Rank.Ace || card.rank <= Rank.Eight) {
          count++;
        }
      }
      return this.options.has(count);
    }
    return false;
  }
}

/**
 * Preflop wrap filters
 */
class OmahaPreflopWrapFilterGroup implements PreflopFilter {
  filters: PFWrapFilter[];

  constructor(options: PreflopWrapType[]) {
    this.filters = options.map((opt) => preflopWrapFilterMap[opt]);
  }

  passes(combo: Combo): boolean {
    const data = computePFWrapGapData(getCardArray(combo));
    if (this.filters.find((f) => f(data))) {
      return true;
    }
    return false;
  }
}

type PFWrapFilter = (data: PFWrapGapData) => boolean;

const preflopWrapFilterMap: Record<PreflopWrapEnum, PFWrapFilter> = {
  [PreflopWrapEnum.FourCard]: (data: PFWrapGapData) =>
    data.numRanks === 4 && data.gap === 3,
  [PreflopWrapEnum.FourCardOneGap]: (data: PFWrapGapData) =>
    data.numRanks === 4 && data.gap === 4,
  [PreflopWrapEnum.ThreeCardOnePair]: (data: PFWrapGapData) =>
    data.numRanks === 3 && data.gap === 2,
};

type PFWrapGapData = {
  numRanks: number;
  gap: number;
};

function computePFWrapGapData(cards: Card[]): PFWrapGapData {
  const rankSet = new Set(cards.map((c) => c.rank));
  const numRanks = rankSet.size;

  const minRank = cards[cards.length - 1].rank;
  let maxRank = cards[0].rank;

  let gap = maxRank - minRank;

  //Consider gap if ace counts as low!
  if (maxRank === Rank.Ace) {
    //Find the largest rank other than Ace.
    // Cards are stored high to low, go until we find not an ace
    let i = 0;
    while (i < cards.length - 1 && cards[i].rank === Rank.Ace) {
      i++;
    }
    maxRank = cards[i].rank;

    // Update our gap if this is a smaller gap.
    // Gap size from Ace to rank will be that Rank + 1.
    //  e.g.  Rank.Four to Rank.Two would be 2 - 0 = 2
    //        Rank.Four to Rank.Ace would be one more
    gap = Math.min(gap, maxRank + 1);
  }

  return { numRanks, gap };
}

/**
 * Holdem preflop filters
 */
class HoldemPreflopFilterGroup implements PreflopFilter {
  filters: HoldemPreflopFilter[];

  constructor(options: HoldemPreflopType[]) {
    this.filters = options.map((opt) => holdemPreflopFilterMap[opt]);
  }

  passes(combo: Combo): boolean {
    if (combo instanceof Pair && this.filters.find((f) => f(combo))) {
      return true;
    }
    return false;
  }
}

type HoldemPreflopFilter = (pair: Pair) => boolean;

const holdemPreflopFilterMap: Record<HoldemPreflopEnum, HoldemPreflopFilter> = {
  [HoldemPreflopEnum.PocketPair]: (pair) =>
    pair.cards[0].rank === pair.cards[1].rank,
  [HoldemPreflopEnum.Suited]: (pair) =>
    pair.cards[0].suit === pair.cards[1].suit,
  [HoldemPreflopEnum.Offsuit]: (pair) =>
    pair.cards[0].suit !== pair.cards[1].suit,
};

/**
 * Hand rank filters!
 */
type HoldemPostflopFilter = (pair: Pair, board: Card[]) => boolean;

class HoldemPostflopFilterGroup implements Filter {
  filters: HoldemPostflopFilter[];

  constructor(filters: HoldemPostflopFilter[]) {
    this.filters = filters;
  }

  passes(combo: Combo, board: Card[]): boolean {
    if (combo instanceof Pair && this.filters.find((f) => f(combo, board))) {
      return true;
    }
    return false;
  }
}

const holdemRankingFilters: Record<HandRankingEnum, HoldemPostflopFilter> = {
  [HandRankingEnum.HIGH_CARD]: holdemHandRankFilter(HandRankingEnum.HIGH_CARD),
  [HandRankingEnum.ONE_PAIR]: holdemHandRankFilter(HandRankingEnum.ONE_PAIR),
  [HandRankingEnum.TWO_PAIR]: holdemHandRankFilter(HandRankingEnum.TWO_PAIR),
  [HandRankingEnum.THREE_OF_A_KIND]: holdemHandRankFilter(
    HandRankingEnum.THREE_OF_A_KIND
  ),
  [HandRankingEnum.STRAIGHT]: holdemHandRankFilter(HandRankingEnum.STRAIGHT),
  [HandRankingEnum.FLUSH]: holdemHandRankFilter(HandRankingEnum.FLUSH),
  [HandRankingEnum.FULL_HOUSE]: holdemHandRankFilter(
    HandRankingEnum.FULL_HOUSE
  ),
  [HandRankingEnum.FOUR_OF_A_KIND]: holdemHandRankFilter(
    HandRankingEnum.FOUR_OF_A_KIND
  ),
  [HandRankingEnum.STRAIGHT_FLUSH]: holdemHandRankFilter(
    HandRankingEnum.STRAIGHT_FLUSH
  ),
};

function holdemHandRankFilter(ranking: HandRankingEnum): HoldemPostflopFilter {
  return (pair, board) =>
    getRankingType(evaluateHoldem(pair, board)) === ranking;
}

type OmahaPostflopFilter = (quad: Uint8Array, board: Card[]) => boolean;

const omahaRankingFilters: Record<HandRankingEnum, OmahaPostflopFilter> = {
  [HandRankingEnum.HIGH_CARD]: omahaHandRankFilter(HandRankingEnum.HIGH_CARD),
  [HandRankingEnum.ONE_PAIR]: omahaHandRankFilter(HandRankingEnum.ONE_PAIR),
  [HandRankingEnum.TWO_PAIR]: omahaHandRankFilter(HandRankingEnum.TWO_PAIR),
  [HandRankingEnum.THREE_OF_A_KIND]: omahaHandRankFilter(
    HandRankingEnum.THREE_OF_A_KIND
  ),
  [HandRankingEnum.STRAIGHT]: omahaHandRankFilter(HandRankingEnum.STRAIGHT),
  [HandRankingEnum.FLUSH]: omahaHandRankFilter(HandRankingEnum.FLUSH),
  [HandRankingEnum.FULL_HOUSE]: omahaHandRankFilter(HandRankingEnum.FULL_HOUSE),
  [HandRankingEnum.FOUR_OF_A_KIND]: omahaHandRankFilter(
    HandRankingEnum.FOUR_OF_A_KIND
  ),
  [HandRankingEnum.STRAIGHT_FLUSH]: omahaHandRankFilter(
    HandRankingEnum.STRAIGHT_FLUSH
  ),
};
function omahaHandRankFilter(ranking: HandRankingEnum): OmahaPostflopFilter {
  return (quad, board) =>
    getRankingType(evaluateOmaha(quad, board)) === ranking;
}

class OmahaPostflopFilterGroup implements Filter {
  filters: OmahaPostflopFilter[];

  constructor(filters: OmahaPostflopFilter[]) {
    this.filters = filters;
  }

  passes(combo: Combo, board: Card[]): boolean {
    if (
      combo instanceof Uint8Array &&
      this.filters.find((f) => f(combo, board))
    ) {
      return true;
    }
    return false;
  }
}

/**
 * Creates a filter, given a set of enums specifying it
 */
export function createFilter(game: string, filterSet: FilterSet): Filter {
  const result = new AndFilter();

  if (game.startsWith("omaha")) {
    // Omaha preflop filters
    const suitednessOptions = filterSet[FilterOptionEnum.Suitedness];
    if (suitednessOptions) {
      result.filterList.push(new OmahaSuitFilterGroup(suitednessOptions));
    }

    const pairednessOptions = filterSet[FilterOptionEnum.Pairedness];
    if (pairednessOptions) {
      result.filterList.push(new OmahaPairednessFilterGroup(pairednessOptions));
    }

    const numLoOptions = filterSet[FilterOptionEnum.NumLoCards];
    if (numLoOptions) {
      result.filterList.push(new LoCardFilterGroup(numLoOptions));
    }

    const pfWrapOptions = filterSet[FilterOptionEnum.PFWrapFilter];
    if (pfWrapOptions) {
      result.filterList.push(new OmahaPreflopWrapFilterGroup(pfWrapOptions));
    }

    //Hand ranking
    const handRankOptions = filterSet[FilterOptionEnum.HandRankType];
    if (handRankOptions) {
      const handRankingFilter = new OmahaPostflopFilterGroup(
        handRankOptions.map((opt) => omahaRankingFilters[opt])
      );
      result.filterList.push(handRankingFilter);
    }
  } else {
    // Holdem Preflop
    const holdemPreflopOptions = filterSet[FilterOptionEnum.HoldemPreflop];
    if (holdemPreflopOptions) {
      result.filterList.push(
        new HoldemPreflopFilterGroup(holdemPreflopOptions)
      );
    }

    // Hand Ranking
    const handRankOptions = filterSet[FilterOptionEnum.HandRankType];
    if (handRankOptions) {
      const handRankingFilter = new HoldemPostflopFilterGroup(
        handRankOptions.map((opt) => holdemRankingFilters[opt])
      );
      result.filterList.push(handRankingFilter);
    }
  }

  return result;
}
