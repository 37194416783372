export enum SeqActionTypeEnum {
  boardCards = "boardCards",
  singleUpCard = "singleUpCard",
  action = "action",
  discardAction = "discardAction",
  terminated = "terminated",
}

export enum SeqActionStateEnum {
  empty = "empty",
  performed = "performed",
  skipped = "skipped",
  toAct = "toAct",
  uiEmpty = "uiEmpty",
}

export enum SeqActionUiStateEnum {
  hidden = "hidden",
  visible = "visible",
}
