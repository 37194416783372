const stateInfoForPreview = {
  pot: 1000,
  playerIndex: 0,
  remainingStack: [9750, 9750, 9750, 9750],
  folded: [false, false, false, false],
  roundContrib: [0, 0, 0, 0],
};
const actionListingForPreview = ["k", "b", "b", "b", "b"];

const matrixDataForPreview = [
  {
    actions: [
      ["k", 0.1],
      ["b750", 0.3],
      ["b9750", 0.6],
    ],
    reach: 0.85,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.3],
      ["b750", 0.2],
      ["b9750", 0.5],
    ],
    reach: 0.75,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.4],
      ["b750", 0.3],
      ["b9750", 0.4],
    ],
    reach: 0.65,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.5],
      ["b750", 0.4],
      ["b9750", 0.1],
    ],
    reach: 0.7,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.4],
      ["b750", 0.4],
      ["b9750", 0.2],
    ],
    reach: 0.5,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.3],
      ["b750", 0.6],
      ["b9750", 0.1],
    ],
    reach: 0.6,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.4],
      ["b750", 0.6],
    ],
    reach: 0.55,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.7],
      ["b333", 0.3],
    ],
    reach: 0.45,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.8],
      ["b333", 0.2],
    ],
    reach: 0.5,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.9],
      ["b333", 0.1],
    ],
    reach: 0.2,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.95],
      ["b333", 0.05],
    ],
    reach: 0.1,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.97],
      ["b333", 0.03],
    ],
    reach: 0.2,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.99],
      ["b333", 0.01],
    ],
    reach: 0.1,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.2],
      ["b750", 0.2],
      ["b9750", 0.6],
    ],
    reach: 0.9,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.1],
      ["b550", 0.6],
      ["b9750", 0.3],
    ],
    reach: 0.75,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.2],
      ["b550", 0.4],
      ["b9750", 0.4],
    ],
    reach: 0.65,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.4],
      ["b550", 0.3],
      ["b9750", 0.4],
    ],
    reach: 0.65,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.5],
      ["b550", 0.4],
      ["b9750", 0.1],
    ],
    reach: 0.6,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.6],
      ["b550", 0.3],
      ["b9750", 0.1],
    ],
    reach: 0.5,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.6],
      ["b333", 0.35],
      ["b9750", 0.05],
    ],
    reach: 0.5,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.7],
      ["b333", 0.3],
    ],
    reach: 0.65,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.75],
      ["b333", 0.25],
    ],
    reach: 0.6,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.8],
      ["b333", 0.2],
    ],
    reach: 0.45,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.6],
      ["b333", 0.4],
    ],
    reach: 0.6,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["f", 0.6],
      ["k", 0.3],
      ["b333", 0.1],
    ],
    reach: 0.7,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["f", 0.4],
      ["k", 0.5],
      ["b333", 0.1],
    ],
    reach: 0.9,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.5],
      ["b2250", 0.2],
      ["b9750", 0.3],
    ],
    reach: 0.8,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.4],
      ["b2250", 0.3],
      ["b9750", 0.3],
    ],
    reach: 0.65,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.3],
      ["b2250", 0.6],
      ["b9750", 0.1],
    ],
    reach: 0.85,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.4],
      ["b2250", 0.4],
      ["b9750", 0.2],
    ],
    reach: 0.7,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.45],
      ["b2250", 0.45],
      ["b9750", 0.1],
    ],
    reach: 0.5,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.4],
      ["b2250", 0.6],
    ],
    reach: 0.6,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.6],
      ["b2250", 0.4],
    ],
    reach: 0.4,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.7],
      ["b2250", 0.3],
    ],
    reach: 0.55,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.85],
      ["b2250", 0.15],
    ],
    reach: 0.4,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.9],
      ["b333", 0.1],
    ],
    reach: 0.6,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.7],
      ["b2250", 0.3],
    ],
    reach: 0.3,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["f", 0.5],
      ["k", 0.4],
      ["b333", 0.1],
    ],
    reach: 0.8,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["f", 0.1],
      ["k", 0.8],
      ["b333", 0.1],
    ],
    reach: 0.9,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.3],
      ["b333", 0.1],
      ["b1250", 0.1],
      ["b9750", 0.5],
    ],
    reach: 0.25,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.2],
      ["b333", 0.2],
      ["b1250", 0.2],
      ["b9750", 0.4],
    ],
    reach: 0.4,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.4],
      ["b333", 0.1],
      ["b1250", 0.2],
      ["b9750", 0.3],
    ],
    reach: 0.65,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.4],
      ["b333", 0.2],
      ["b1250", 0.3],
      ["b9750", 0.1],
    ],
    reach: 0.45,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.6],
      ["b333", 0.1],
      ["b1250", 0.2],
      ["b9750", 0.1],
    ],
    reach: 0.8,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.65],
      ["b333", 0.1],
      ["b1250", 0.15],
      ["b9750", 0.1],
    ],
    reach: 0.45,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.7],
      ["b333", 0.2],
      ["b1250", 0.1],
    ],
    reach: 0.55,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.7],
      ["b333", 0.3],
    ],
    reach: 0.65,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.65],
      ["b333", 0.35],
    ],
    reach: 0.35,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["f", 0.1],
      ["k", 0.7],
      ["b333", 0.2],
    ],
    reach: 0.85,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["f", 0.2],
      ["k", 0.7],
      ["b333", 0.1],
    ],
    reach: 0.9,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["f", 0.2],
      ["k", 0.7],
      ["b333", 0.1],
    ],
    reach: 0.55,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["f", 0.4],
      ["k", 0.5],
      ["b333", 0.1],
    ],
    reach: 0.7,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.6],
      ["b333", 0.1],
      ["b1250", 0.2],
      ["b9750", 0.1],
    ],
    reach: 0.8,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.5],
      ["b333", 0.1],
      ["b1250", 0.3],
      ["b9750", 0.1],
    ],
    reach: 0.6,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.4],
      ["b333", 0.1],
      ["b1250", 0.3],
      ["b9750", 0.2],
    ],
    reach: 0.9,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.3],
      ["b333", 0.1],
      ["b1250", 0.5],
      ["b9750", 0.1],
    ],
    reach: 0.65,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.45],
      ["b333", 0.1],
      ["b1250", 0.35],
      ["b9750", 0.1],
    ],
    reach: 0.95,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.7],
      ["b333", 0.1],
      ["b1250", 0.1],
      ["b9750", 0.1],
    ],
    reach: 0.75,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.6],
      ["b1250", 0.3],
      ["b9750", 0.1],
    ],
    reach: 0.5,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["k", 0.6],
      ["b333", 0.4],
    ],
    reach: 0.8,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["f", 0.2],
      ["k", 0.7],
      ["b333", 0.1],
    ],
    reach: 0.3,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["f", 0.45],
      ["k", 0.5],
      ["b333", 0.05],
    ],
    reach: 0.6,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["f", 0.8],
      ["b2250", 0.2],
    ],
    reach: 0.7,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["f", 0.7],
      ["b2250", 0.3],
    ],
    reach: 0.9,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["f", 0.9],
      ["b2250", 0.1],
    ],
    reach: 0.2,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["с", 0.4],
      ["b333", 0.1],
      ["b550", 0.3],
      ["b9750", 0.2],
    ],
    reach: 0.6,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["с", 0.2],
      ["b333", 0.1],
      ["b550", 0.5],
      ["b9750", 0.2],
    ],
    reach: 0.95,
    selected: false,
    expectedValue: 0,
  },
  null,
  {
    actions: [
      ["с", 0.7],
      ["b550", 0.3],
    ],
    reach: 0.6,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["с", 0.5],
      ["b550", 0.5],
    ],
    reach: 0.75,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["с", 0.7],
      ["b550", 0.2],
      ["b9750", 0.1],
    ],
    reach: 0.6,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["с", 0.7],
      ["b750", 0.3],
    ],
    reach: 0.75,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["с", 0.7],
      ["b750", 0.3],
    ],
    reach: 0.6,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["с", 0.3],
      ["b750", 0.7],
    ],
    reach: 0.2,
    selected: false,
    expectedValue: 0,
  },
  null,
  null,
  null,
  null,
  {
    actions: [
      ["с", 0.3],
      ["b750", 0.2],
      ["b1250", 0.1],
      ["b9750", 0.4],
    ],
    reach: 0.9,
    selected: false,
    expectedValue: 0,
  },
  null,
  null,
  {
    actions: [
      ["с", 0.5],
      ["b750", 0.5],
    ],
    reach: 0.4,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["с", 0.6],
      ["b750", 0.4],
    ],
    reach: 0.1,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["с", 0.4],
      ["b750", 0.6],
    ],
    reach: 0.6,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["с", 0.1],
      ["b750", 0.5],
      ["b9750", 0.3],
    ],
    reach: 0.4,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["f", 0.5],
      ["b333", 0.5],
    ],
    reach: 0.8,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["f", 0.6],
      ["b333", 0.4],
    ],
    reach: 0.3,
    selected: false,
    expectedValue: 0,
  },
  null,
  null,
  null,
  null,
  {
    actions: [
      ["c", 0.5],
      ["b750", 0.5],
    ],
    reach: 0.8,
    selected: false,
    expectedValue: 0,
  },
  null,
  null,
  null,
  null,
  null,
  {
    actions: [
      ["f", 0.5],
      ["c", 0.5],
    ],
    reach: 0.4,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["c", 0.5],
      ["b550", 0.5],
    ],
    reach: 0.55,
    selected: false,
    expectedValue: 0,
  },
  {
    actions: [
      ["c", 0.8],
      ["b550", 0.2],
    ],
    reach: 0.75,
    selected: false,
    expectedValue: 0,
  },
  null,
  null,
  null,
  null,
  {
    actions: [
      ["c", 0.6],
      ["b550", 0.4],
    ],
    reach: 0.55,
    selected: false,
    expectedValue: 0,
  },
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  {
    actions: [
      ["c", 0.5],
      ["b550", 0.5],
    ],
    reach: 0.55,
    selected: false,
    expectedValue: 0,
  },
  null,
  null,
  null,
  null,
  {
    actions: [
      ["c", 0.8],
      ["b550", 0.2],
    ],
    reach: 0.35,
    selected: false,
    expectedValue: 0,
  },
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  {
    actions: [
      ["c", 0.5],
      ["b550", 0.5],
    ],
    reach: 0.55,
    selected: false,
    expectedValue: 0,
  },
  null,
  null,
  null,
  {
    actions: [
      ["c", 0.9],
      ["b550", 0.1],
    ],
    reach: 0.75,
    selected: false,
    expectedValue: 0,
  },
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  {
    actions: [
      ["c", 0.5],
      ["b550", 0.5],
    ],
    reach: 0.55,
    selected: false,
    expectedValue: 0,
  },
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  {
    actions: [
      ["c", 0.5],
      ["b550", 0.5],
    ],
    reach: 0.55,
    selected: false,
    expectedValue: 0,
  },
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  {
    actions: [
      ["c", 0.5],
      ["b550", 0.5],
    ],
    reach: 0.55,
    selected: false,
    expectedValue: 0,
  },
];

export { matrixDataForPreview, stateInfoForPreview, actionListingForPreview };
