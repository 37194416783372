import tinycolor from "tinycolor2";

export const rgbToRgba = (rgb: string, alpha: number) => {
  const match = rgb.match(/^rgb\((\d+),\s*(\d+),\s*([\d.]+)\)$/);

  if (match) {
    const r = parseInt(match[1], 10);
    const g = parseInt(match[2], 10);
    const b = parseFloat(match[3]);

    alpha = Math.max(0, Math.min(1, alpha));

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return null;
};

export const extractLastNumber = (rgbString: string) => {
  const match = rgbString.match(/^rgb\(\s*(\d+),\s*(\d+),\s*(\d+)\s*\)$/);
  if (match) {
    const r = parseInt(match[1], 10);
    const g = parseInt(match[2], 10);
    const b = parseInt(match[3], 10);
    return { r, g, b };
  }
  throw new Error("Invalid RGB string format");
};

export const isWhiteTextReadableOnBackground = (rgbString: string): boolean => {
  const backgroundColor = tinycolor(rgbString);
  const whiteColor = tinycolor("#FFFFFF");

  return tinycolor.readability(backgroundColor, whiteColor) >= 4.5;
};
