import { useLayoutStore } from "@/stores/layout";

export const useFocusMode = () => {
  const layoutStore = useLayoutStore();
  let focusModeTimer: ReturnType<typeof setTimeout> | null;

  const changeFocusModeState = () => {
    layoutStore.changeFocusModeToastVisibility(!layoutStore.isActiveFocusMode);
    layoutStore.changeFocusModeState();
    if (focusModeTimer) {
      clearTimeout(focusModeTimer);
    }
    focusModeTimer = setTimeout(() => {
      layoutStore.changeFocusModeToastVisibility(false);
      focusModeTimer = null;
    }, 3000);
  };

  return {
    changeFocusModeState,
  };
};
