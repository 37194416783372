import pokerApi from "@/services/pokerApi";
import type { SelectedGameStrategy } from "@/stores/game";
import { useGameStore } from "@/stores/game";
import type {
  AvailableSettings,
  AvailableSettingsForSelected,
  ConfigMap,
  GameConfig,
  GameSetting,
  GroupedGames,
  SelectedGameSettings,
} from "@/types/gameSettings";
import { GameCategoryEnum } from "@/enums/gameEnum";

const parseGameConfigs = (
  gameConfigs: GameConfig[],
  fields: Array<keyof GameConfig>
): ConfigMap => {
  const allFieldValues: { [key: string]: Set<string> } = {};
  fields.forEach((field) => {
    allFieldValues[field] = new Set(
      gameConfigs.map((gameConfig) => gameConfig[field].toString())
    );
  });

  return gameConfigs.reduce((configMap: ConfigMap, gameConfig) => {
    const gameName = `${gameConfig.gameName}`;

    if (!configMap[gameName]) {
      configMap[gameName] = {};
    }

    fields.forEach((field) => {
      const value = gameConfig[field].toString();

      if (!configMap[gameName][field]) {
        configMap[gameName][field] = {};
      }

      if (!configMap[gameName][field][value]) {
        configMap[gameName][field][value] = {
          availableSettings: {
            [field]: Array.from(allFieldValues[field]),
          },
        };
      }

      fields
        .filter((f) => f !== field)
        .forEach((f) => {
          if (!configMap[gameName][field][value].availableSettings[f]) {
            configMap[gameName][field][value].availableSettings[f] = [];
          }

          const settingValue = gameConfig[f];

          if (
            !configMap[gameName][field][value].availableSettings[f].includes(
              settingValue.toString()
            )
          ) {
            configMap[gameName][field][value].availableSettings[f].push(
              settingValue.toString()
            );
          }
        });
    });

    return configMap;
  }, {});
};

export const isFlopGameCategory = (category?: GameCategoryEnum) => {
  if (category) {
    return (
      category === GameCategoryEnum.holdem ||
      category === GameCategoryEnum.omaha
    );
  }
  return false;
};

export const isDrawGameCategory = (category?: GameCategoryEnum) => {
  if (category) {
    return category === GameCategoryEnum.draw;
  }
  return false;
};

export const isStudGameCategory = (category?: GameCategoryEnum) => {
  if (category) {
    return category === GameCategoryEnum.stud;
  }
  return false;
};

export const isConnectionNameValid = (connectionName: string) => {
  const gameStore = useGameStore();
  const config = gameStore.availableGameConfigs.find(
    (el) => el.name === connectionName
  );

  if (config) {
    return true;
  } else {
    gameStore.setIsSharingLinkInvalid(true);
    return false;
  }
};

export const getStrategyFromConfig = (
  connectionName: string,
  settingsKeys: string[]
): SelectedGameStrategy | void => {
  const gameStore = useGameStore();
  const config = gameStore.availableGameConfigs.find(
    (el) => el.name === connectionName
  );

  if (config) {
    const selectedGameCategory = config.gameName;
    const selectedGameSettings = settingsKeys.reduce(
      (result: SelectedGameSettings, field: string) => {
        if (Object.prototype.hasOwnProperty.call(config, field)) {
          result[field] = String(config[field as keyof GameConfig]);
        }
        return result;
      },
      {}
    );
    return {
      selectedGameSettings,
      selectedGameCategory,
    };
  } else {
    gameStore.setIsSharingLinkInvalid(true);
  }
};

export const getNormalizingUnitFromConfig = (gameName?: string): number => {
  const gameStore = useGameStore();

  if (gameName) {
    const normalizingUnit = gameStore.availableGameConfigs.find(
      (config) => config.gameName === gameName
    )?.normalizingUnit;

    return normalizingUnit ? normalizingUnit : 100;
  } else {
    return 100;
  }
};

export const findAndSetConnectionName = async (
  isPreflopPage = false,
  callback?: (gameType: string) => void
) => {
  const gameStore = useGameStore();

  let gameType = null;
  if (isPreflopPage) {
    if (
      gameStore.preflopGameStrategy?.selectedGameCategory &&
      gameStore.preflopGameStrategy?.selectedGameSettings
    ) {
      gameType = findGameConnection(
        gameStore.preflopGameStrategy?.selectedGameCategory,
        gameStore.preflopGameStrategy?.selectedGameSettings
      );
      gameStore.setConnectionName(gameType);
    }
  } else {
    if (
      gameStore.studyGameStrategy?.selectedGameCategory &&
      gameStore.studyGameStrategy?.selectedGameSettings
    ) {
      gameType = findGameConnection(
        gameStore.studyGameStrategy?.selectedGameCategory,
        gameStore.studyGameStrategy?.selectedGameSettings
      );
      gameStore.setConnectionName(gameType);
    }
  }
  if (callback && gameType) {
    callback(gameType);
  }
};

export const findGameConnection = (
  selectedGameCategory: string,
  selectedGameSettings: SelectedGameSettings
): string | null => {
  const gameStore = useGameStore();

  for (const config of gameStore.availableGameConfigs) {
    if (config.gameName === selectedGameCategory) {
      const settingsKeys: string[] = Object.keys(selectedGameSettings);

      if (
        settingsKeys.every((key) => {
          return (
            String(config[key as keyof GameConfig]) ===
            String(selectedGameSettings[key])
          );
        })
      ) {
        return config.name;
      }
    }
  }
  return null;
};

export const getGameConfigs = async (fields: (keyof GameConfig)[]) => {
  const gameStore = useGameStore();

  const modifyGameConfigs = (gameConfigs: GameConfig[]): GameConfig[] => {
    return gameConfigs.map((config: GameConfig): GameConfig => {
      if (config.rakePercent === 0 && config.rakeCap === 0) {
        return {
          ...config,
          stake: "MTT",
        };
      } else {
        return {
          ...config,
          stake: `${config.rakePercent * config.normalizingUnit}% ${
            config.rakeCap / config.normalizingUnit
          }bb`,
        };
      }
    });
  };

  await pokerApi.fetchGameConfigs().then((res) => {
    const modifiedConfigs = modifyGameConfigs(res.data.data);
    gameStore.setAvailableGameConfigs(modifiedConfigs);
    gameStore.setGameSettings(parseGameConfigs(modifiedConfigs, fields));
    gameStore.setGameCategories(groupGameNamesByCategory(res.data.data));
  });
};

export const groupGameNamesByCategory = (
  configs: GameConfig[]
): GroupedGames => {
  return configs.reduce((acc: GroupedGames, config: GameConfig) => {
    const { gameCategory, gameName } = config;
    const gameCategoryTitle = `${gameCategory} Games`;

    if (!acc[gameCategoryTitle]) {
      acc[gameCategoryTitle] = [];
    }

    if (!acc[gameCategoryTitle].includes(gameName)) {
      acc[gameCategoryTitle].push(gameName);
    }
    return acc;
  }, {});
};

export const getAvailableSettings = (
  configMap: GameSetting,
  fields: Array<keyof SelectedGameSettings>
): AvailableSettings => {
  const output: AvailableSettings = {};

  for (const field of fields) {
    output[field] = Object.keys(configMap[field]).map((option) => ({
      value: option.toString(),
      isDisabled: false,
    }));
  }

  return output;
};

export const setAvailableSettings = (
  selectedFields: SelectedGameSettings,
  settingsForSelected: GameSetting,
  availableSettings: AvailableSettings
): AvailableSettings => {
  const selectedKeys: Array<keyof SelectedGameSettings> = Object.keys(
    selectedFields
  ).filter((key) => selectedFields[key] !== null);

  if (!selectedKeys.length) {
    return getAvailableSettings(
      settingsForSelected,
      Object.keys(settingsForSelected)
    );
  }

  const mergedAvailableSettings: AvailableSettingsForSelected = {};

  for (const key of selectedKeys) {
    const currentAvailableSettings =
      settingsForSelected[key][selectedFields[key] as string].availableSettings;

    for (const settingKey in currentAvailableSettings) {
      if (
        mergedAvailableSettings[settingKey] &&
        currentAvailableSettings[settingKey]
      ) {
        mergedAvailableSettings[settingKey] = mergedAvailableSettings[
          settingKey
        ].filter((value) =>
          currentAvailableSettings[settingKey].includes(value)
        );
      } else if (currentAvailableSettings[settingKey]) {
        mergedAvailableSettings[settingKey] =
          currentAvailableSettings[settingKey];
      }
    }
  }

  const newSettings = { ...availableSettings };
  for (const key in newSettings) {
    newSettings[key] = newSettings[key].map((setting) => {
      const isSettingAvailable =
        mergedAvailableSettings[key] &&
        mergedAvailableSettings[key].includes(setting.value);
      return { value: setting.value, isDisabled: !isSettingAvailable };
    });
  }

  return newSettings;
};

export const setDefaultSelectedSettings = (
  gameSetting: GameSetting
): { [index: string]: null | string } => {
  const obj: { [index: string]: null | string } = {};
  Object.keys(gameSetting).forEach((key) => {
    obj[key] = null;
    if (Object.keys(gameSetting[key]).length === 1) {
      obj[key] = Object.keys(gameSetting[key])[0];
    }
  });
  return obj;
};
