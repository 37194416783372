import type { QueryResponse } from ".";
import type { Card } from "./Combos";
import {
  filterDecisionStrat,
  type DecisionStrategy,
  type NodeData,
  type NodeGroup,
} from "./DecisionStrategy";
import { type MatrixData } from "./matrix/MatrixData";
import { HandRankingEnum } from "./hand-evaluation/HandRanking";
import type { MatrixTabData } from "./interfaces/matrix-tab-data.interface";
import type {
  AvailableFilterListing,
  FilterGroup,
  FilterListingEntry,
  FilterSet,
} from "./types/filter-types";
import {
  FilterOptionEnum,
  HoldemPreflopEnum,
  PreflopPairednessEnum,
  PreflopSuitednessEnum,
  PreflopWrapEnum,
} from "./types/filter-types";
import type { MatrixCellAction } from "./types/matrix-cell-action.type";
import { makeComboSearchFilter } from "./filters/ComboSearch";
import type { Filter } from "./filters/HandFilters";
import { createFilter } from "./filters/HandFilters";
import type { ActionListing } from "./interfaces/action-listing.interface";

/**
 * Wrap DecisionStrategy and MatrixData, and implement QueryResponse interface.
 *
 * This is what will be returned by the main parsing function.
 */
export class QueryWrapper implements QueryResponse {
  fullStrat: NodeGroup;
  afterFilters: NodeGroup;
  filterStrat: NodeGroup;
  comboSearchFilter: Filter | null = null;
  actionListing: ActionListing;
  matrix: MatrixData;
  gameType: string;
  board: Card[];
  percentRange = 100;
  percentReach = 100;
  isDiscardAction = false;

  constructor(
    game: string,
    board: Card[],
    ds: DecisionStrategy,
    matrix: MatrixData
  ) {
    this.gameType = game.toLowerCase();
    this.board = board;

    this.fullStrat = ds.decisionNodes;
    this.afterFilters = ds.decisionNodes;
    this.filterStrat = ds.decisionNodes;
    this.actionListing = ds.actionListing;

    this.matrix = matrix;
    this.isDiscardAction = ds.isDiscardAction;
    this.percentReach = this.filterStrat.getPercentReach();
  }

  getActionListing(): ActionListing {
    return this.actionListing;
  }

  getOverallTuple(): MatrixCellAction[] {
    const actions = this.actionListing.betting;
    const probs = this.matrix.getSelectedStrat().summary.getAvg().probs;
    return probs.map((p, i) => [actions[i], p]);
  }

  getMatrixTabData(): MatrixTabData {
    return {
      actionListing: this.actionListing,
      matrices: this.matrix.getRenderData(),
      percentReach: this.matrix.getSelectedStrat().getPercentReach(),
    };
  }

  getRangeTabData(): NodeData[] {
    return this.matrix.getSelectedStrat().nodeList;
  }

  handleMatrixClicks(matrixIndex: number, cellIndex: number): void {
    this.matrix.handleClicks(matrixIndex, cellIndex);
    this.updateSummaryValues();
  }

  applyFilter(filterSet: FilterSet) {
    const filter = createFilter(this.gameType, filterSet);
    this.afterFilters = filterDecisionStrat(
      this.gameType,
      this.fullStrat,
      this.board,
      filter
    );
    this.applyComboSearch();
  }

  setComboSearch(search: string): boolean {
    this.comboSearchFilter = makeComboSearchFilter(search);
    if (!this.comboSearchFilter) {
      return false;
    }

    this.applyComboSearch();
    return true;
  }

  applyComboSearch() {
    const comboSearch = this.comboSearchFilter;
    if (comboSearch) {
      this.filterStrat = filterDecisionStrat(
        this.gameType,
        this.afterFilters,
        this.board,
        comboSearch
      );
    } else {
      this.filterStrat = this.afterFilters;
    }

    this.matrix.setStrategy(this.filterStrat, this.board);
    this.updateSummaryValues();
  }

  resetFilters(): void {
    this.comboSearchFilter = null;
    this.afterFilters = this.fullStrat;
    this.filterStrat = this.afterFilters;
    this.matrix.setStrategy(this.fullStrat, this.board);
  }

  updateSummaryValues() {
    this.percentReach = this.matrix.getSelectedStrat().getPercentReach();
    this.percentRange =
      (100.0 * this.matrix.getSelectedStrat().getTotalReach()) /
      this.fullStrat.getTotalReach();
  }

  availableFilters(): AvailableFilterListing {
    const result: AvailableFilterListing = [];

    if (this.gameType.startsWith("omaha")) {
      //NOTE will be different for different omaha games. 6cPLO has lots of different suit options.
      const suitFilters: FilterGroup = {
        group: "Suit filter",
        filters: [
          {
            name: FilterOptionEnum.Suitedness,
            options: Object.values(PreflopSuitednessEnum),
          },
        ],
      };
      const cardFilters: FilterGroup = {
        group: "Card filter",
        filters: [
          {
            name: FilterOptionEnum.Pairedness,
            options: Object.values(PreflopPairednessEnum),
          },
          {
            name: FilterOptionEnum.NumLoCards,
            options: [0, 1, 2, 3, 4],
          },
          {
            name: FilterOptionEnum.PFWrapFilter,
            options: Object.values(PreflopWrapEnum),
          },
        ],
      };
      result.push(suitFilters);
      result.push(cardFilters);
      if (this.board.length > 0) {
        const postFlop: FilterGroup = {
          group: "Postflop",
          filters: [
            {
              name: FilterOptionEnum.HandRankType,
              options: Object.values(HandRankingEnum),
            },
          ],
        };
        result.push(postFlop);
      }
    } else {
      const holdemFilters: FilterListingEntry[] = [];
      holdemFilters.push({
        name: FilterOptionEnum.HoldemPreflop,
        options: Object.values(HoldemPreflopEnum),
      });
      if (this.board.length > 0) {
        holdemFilters.push({
          name: FilterOptionEnum.HandRankType,
          options: Object.values(HandRankingEnum),
        });
      }
      result.push({ group: "", filters: holdemFilters });
    }
    return result;
  }
}
