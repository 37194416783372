import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "dropdown" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["dropdown__trigger", { 'is-active': $setup.isOpen }]),
      onClickCapture: _cache[0] || (_cache[0] = ($event: any) => ($setup.isOpen = !$setup.isOpen))
    }, [
      _renderSlot(_ctx.$slots, "trigger", {}, undefined, true)
    ], 34)), [
      [_directive_click_outside, () => ($setup.isOpen = false)]
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["dropdown__menu", [{ 'dropdown__menu--hide': !$setup.isOpen }, $setup.props.position]])
    }, [
      _renderSlot(_ctx.$slots, "options", {}, undefined, true)
    ], 2)
  ]))
}