import { computed, ref } from "vue";
import { defineStore } from "pinia";
import { GameTypeEnum } from "@/enums/gameEnum";
import type {
  ConfigMap,
  GameConfig,
  GroupedGames,
  SelectedGameSettings,
} from "@/types/gameSettings";
import type { RouteRecordNameGeneric } from "vue-router";
import { useRouter } from "vue-router";
import { deepEqual } from "@/utils/utilityFunctions";
import {
  isDrawGameCategory,
  isStudGameCategory,
} from "@/features/gameSettings";

export interface SelectedGameStrategy {
  selectedGameCategory: string;
  selectedGameSettings: SelectedGameSettings;
}

export const useGameStore = defineStore(
  "game",
  () => {
    const availableGameConfigs = ref<GameConfig[]>([]);
    const savedLastActivePreflopStrategyIdx = ref<number | null>(null);
    const savedPreflopStrategies = ref<SelectedGameStrategy[]>([]);
    const savedLastActiveStudyStrategyIdx = ref<number | null>(null);
    const savedStudyStrategies = ref<SelectedGameStrategy[]>([]);

    const gameSettings = ref<ConfigMap>({});
    const gameCategories = ref<GroupedGames>({});
    const preflopGameStrategy = ref<SelectedGameStrategy | null>(null);
    const studyGameStrategy = ref<SelectedGameStrategy | null>(null);
    const connectionName = ref<string | null>(null);
    const isApiAvailable = ref<boolean>(true);
    const isServerResponding = ref<boolean>(true);
    const currentPageName = ref<RouteRecordNameGeneric | null>(null);
    const isSharingLinkInvalid = ref<boolean>(false);

    const router = useRouter();

    const isCurrentGameStud = computed(() => {
      return isStudGameCategory(configByConnectionName.value?.gameCategory);
    });
    const isCurrentGameDraw = computed(() => {
      return isDrawGameCategory(configByConnectionName.value?.gameCategory);
    });
    const lastActivePreflopStrategy = computed(() => {
      return savedPreflopStrategies.value[
        savedLastActivePreflopStrategyIdx.value
          ? savedLastActivePreflopStrategyIdx.value
          : 0
      ];
    });
    const lastActiveStudyStrategy = computed(() => {
      return savedStudyStrategies.value[
        savedLastActiveStudyStrategyIdx.value
          ? savedLastActiveStudyStrategyIdx.value
          : 0
      ];
    });
    const isPreflopOnlyStrategy = computed(() => {
      return configByConnectionName.value?.maxRounds === 1;
    });
    const getActiveGameCategoryForRequest = (
      connection = connectionName.value
    ) => {
      return getConfigByConnectionName(connection)
        ?.gameCategory.toLowerCase()
        .replace(/'/g, "");
    };
    const isGameFL = computed(() => {
      return configByConnectionName.value?.betType === "FL";
    });
    const isGameTypeShortDeck = computed(() => {
      return configByConnectionName.value?.gameType === GameTypeEnum.shortdeck;
    });
    const activeGameType = computed(() => {
      return configByConnectionName.value?.gameType;
    });
    const configByConnectionName = computed(() => {
      return availableGameConfigs.value.find(
        (config) => config.name === connectionName.value
      );
    });
    const setIsSharingLinkInvalid = (value: boolean) => {
      isSharingLinkInvalid.value = value;
    };
    const getConfigByConnectionName = (connection = connectionName.value) => {
      return availableGameConfigs.value.find(
        (config) => config.name === connection
      );
    };
    const playerNames = computed(() => {
      return configByConnectionName.value?.playerNames || [];
    });
    const setApiAvailability = (value: boolean) => {
      isApiAvailable.value = value;
    };
    const setIsServerResponding = (value: boolean) => {
      isServerResponding.value = value;
    };
    const setGameSettings = (value: ConfigMap) => {
      gameSettings.value = value;
    };
    const setAvailableGameConfigs = (value: GameConfig[]) => {
      availableGameConfigs.value = value;
    };
    const setGameCategories = (value: GroupedGames) => {
      gameCategories.value = value;
    };
    const setPreflopGameStrategy = (value: SelectedGameStrategy) => {
      preflopGameStrategy.value = value;
      updateSavedStrategies(
        preflopGameStrategy.value,
        savedPreflopStrategies.value
      );
      savedPreflopStrategies.value.forEach((strat, index) => {
        if (deepEqual<SelectedGameStrategy>(value, strat)) {
          savedLastActivePreflopStrategyIdx.value = index;
        }
      });
    };
    const setStudyGameStrategy = (value: SelectedGameStrategy) => {
      studyGameStrategy.value = value;
      updateSavedStrategies(
        studyGameStrategy.value,
        savedStudyStrategies.value
      );
      savedStudyStrategies.value.forEach((strat, index) => {
        if (deepEqual<SelectedGameStrategy>(value, strat)) {
          savedLastActiveStudyStrategyIdx.value = index;
        }
      });
    };
    const setConnectionName = (value: string | null) => {
      connectionName.value = value;
    };
    const updateSavedStrategies = (
      updatedGameStrategy: SelectedGameStrategy,
      savedStrategies: SelectedGameStrategy[]
    ) => {
      if (updatedGameStrategy.selectedGameSettings) {
        const index = savedStrategies.findIndex(
          (obj) =>
            obj.selectedGameCategory ===
            updatedGameStrategy.selectedGameCategory
        );

        if (index !== -1) {
          if (
            JSON.stringify(savedStrategies[index].selectedGameSettings) !==
            JSON.stringify(updatedGameStrategy.selectedGameSettings)
          ) {
            savedStrategies.splice(index, 1, updatedGameStrategy);
          }
        } else {
          savedStrategies.push(updatedGameStrategy);
        }
      }
    };
    const setLastActivePreflopStrat = () => {
      preflopGameStrategy.value = savedPreflopStrategies.value
        ? savedPreflopStrategies.value[
            savedLastActivePreflopStrategyIdx.value as number
          ]
        : null;
    };
    const setLastActiveStudyStrat = () => {
      studyGameStrategy.value = savedStudyStrategies.value
        ? savedStudyStrategies.value[
            savedLastActiveStudyStrategyIdx.value as number
          ]
        : null;
    };
    const clearStrategies = () => {
      preflopGameStrategy.value = null;
      studyGameStrategy.value = null;
      savedPreflopStrategies.value = [];
      savedStudyStrategies.value = [];
    };
    const updatePageQueries = (
      seq: string,
      params?: {
        boardCards?: string[];
        upCards?: string[];
      }
    ) => {
      router.replace({
        query: {
          connectionName: connectionName.value,
          seq,
          boardCards: params?.boardCards?.length
            ? params?.boardCards.join("")
            : undefined,
          upCards: params?.upCards?.length
            ? params.upCards.join(",")
            : undefined,
        },
      });
    };

    const setCurrentPageName = (routName: RouteRecordNameGeneric) => {
      currentPageName.value = routName;
    };

    return {
      activeGameType,
      availableGameConfigs,
      clearStrategies,
      configByConnectionName,
      connectionName,
      currentPageName,
      gameCategories,
      gameSettings,
      getActiveGameCategoryForRequest,
      getConfigByConnectionName,
      isApiAvailable,
      isCurrentGameDraw,
      isCurrentGameStud,
      isGameFL,
      isGameTypeShortDeck,
      isPreflopOnlyStrategy,
      isServerResponding,
      isSharingLinkInvalid,
      lastActivePreflopStrategy,
      lastActiveStudyStrategy,
      playerNames,
      preflopGameStrategy,
      savedLastActivePreflopStrategyIdx,
      savedLastActiveStudyStrategyIdx,
      savedPreflopStrategies,
      savedStudyStrategies,
      setApiAvailability,
      setAvailableGameConfigs,
      setConnectionName,
      setCurrentPageName,
      setGameCategories,
      setGameSettings,
      setIsServerResponding,
      setIsSharingLinkInvalid,
      setLastActivePreflopStrat,
      setLastActiveStudyStrat,
      setPreflopGameStrategy,
      setStudyGameStrategy,
      studyGameStrategy,
      updatePageQueries,
    };
  },
  {
    share: {
      omit: [
        "gameSettings",
        "preflopGameStrategy",
        "connectionName",
        "studyGameStrategy",
        "currentPageName",
        "isSharingLinkInvalid",
      ],
    },
    persist: {
      paths: [
        "savedLastActivePreflopStrategyIdx",
        "savedPreflopStrategies",
        "savedLastActiveStudyStrategyIdx",
        "savedStudyStrategies",
        "availableGameConfigs",
      ],
    },
  }
);
