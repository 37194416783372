import { ComboIterator } from "../ComboIterator";
import { getCardArray, type Card, type Pair } from "../Combos";
import { FOUR_CARD_PAIRS } from "../matrix/FourCardMatrixU8";
import { flushLookup } from "./flush-lookup-table";
import { productLookup } from "./product-lookup-table";
import { uniqueLookup } from "./unique-lookup-table";
import { valueLookup } from "./value-lookup-table";

export enum HandRankingTypeThresholds {
  HIGH_CARD = 1278,
  ONE_PAIR = 4138,
  TWO_PAIR = 4996,
  THREE_OF_A_KIND = 5854,
  STRAIGHT = 5864,
  FLUSH = 7141,
  FULL_HOUSE = 7297,
  FOUR_OF_A_KIND = 7453,
  STRAIGHT_FLUSH = 7463,
}

const HandRankingThresholdList = [
  HandRankingTypeThresholds.HIGH_CARD,
  HandRankingTypeThresholds.ONE_PAIR,
  HandRankingTypeThresholds.TWO_PAIR,
  HandRankingTypeThresholds.THREE_OF_A_KIND,
  HandRankingTypeThresholds.STRAIGHT,
  HandRankingTypeThresholds.FLUSH,
  HandRankingTypeThresholds.FULL_HOUSE,
  HandRankingTypeThresholds.FOUR_OF_A_KIND,
  HandRankingTypeThresholds.STRAIGHT_FLUSH,
];

export enum HandRankingEnum {
  HIGH_CARD = "High Card",
  ONE_PAIR = "One Pair",
  TWO_PAIR = "Two Pair",
  THREE_OF_A_KIND = "Three of a Kind",
  STRAIGHT = "Straight",
  FLUSH = "Flush",
  FULL_HOUSE = "Full House",
  FOUR_OF_A_KIND = "Four of a Kind",
  STRAIGHT_FLUSH = "Straight Flush",
}

export type HandRanking = `${HandRankingEnum}`;

class FiveCardEvaluator {
  primes: Uint32Array;
  deck: Uint32Array;

  //Singleton - there's a lot of static data that we only want to assemble once.
  private constructor() {
    this.primes = Uint32Array.from([
      2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41,
    ]);

    this.deck = this.initDeck();
  }

  private static instance: FiveCardEvaluator;

  static get(): FiveCardEvaluator {
    if (!FiveCardEvaluator.instance) {
      FiveCardEvaluator.instance = new FiveCardEvaluator();
    }
    return FiveCardEvaluator.instance;
  }

  eval5(c1: number, c2: number, c3: number, c4: number, c5: number): number {
    let q: number; // the rank
    let s: number;

    q = (c1 | c2 | c3 | c4 | c5) >> 16;

    // check for Flushes and StraightFlushes -
    if ((c1 & c2 & c3 & c4 & c5 & 0xf000) !== 0) {
      s = flushLookup[q];
      if (s === 0) {
        throw new Error("Duplicate card in hand (FLUSH CHECK)");
      } else {
        return s;
      }
    }

    // check for Straights and HighCard hands
    s = uniqueLookup[q];
    if (s > 0) {
      return s;
    }

    // let's do it the hard way - multiply the prime nos
    q = (c1 & 0xff) * (c2 & 0xff) * (c3 & 0xff) * (c4 & 0xff) * (c5 & 0xff);
    q = this.findit(q);

    return valueLookup[q];
  }

  findit(key: number): number {
    let low = 0,
      high = 4887,
      mid;

    while (low <= high) {
      mid = (high + low) >> 1; // divide by two
      if (key < productLookup[mid]) {
        high = mid - 1;
      } else if (key > productLookup[mid]) {
        low = mid + 1;
      } else {
        return mid;
      }
    }
    throw new Error("Duplicate card in hand (findit)");
  }

  // Convert card to numeric value.
  // NOTE, can't use card ordinal, because this.deck is built using some different
  // card ordering! Can probably skip this method and fix that, once things are working.
  private convertCard(card: Card): number {
    return this.deck[card.rank + card.suit * 13];
  }

  evaluate(hand: Card[]): number {
    const cards = hand.map((c) => this.convertCard(c));
    return 7463 - this.eval5(cards[0], cards[1], cards[2], cards[3], cards[4]);
  }

  initDeck(): Uint32Array {
    const deck = new Uint32Array(52);
    let n = 0;
    let suit = 0x8000;

    for (let i = 0; i < 4; i++, suit >>= 1) {
      for (let j = 0; j < 13; j++, n++) {
        deck[n] = this.primes[j] | (j << 8) | suit | (1 << (16 + j));
      }
    }
    return deck;
  }
}

export function evaluateOmaha(quad: Uint8Array, board: Card[]): number {
  let result = 0;

  // Iterate over all combinations of 3 cards from the board
  const boardIter = new ComboIterator(board, 3);
  while (boardIter.hasNext()) {
    const cardsFromBoard = boardIter.next();
    const holecards = getCardArray(quad);
    for (const [i, j] of FOUR_CARD_PAIRS) {
      const handScore = FiveCardEvaluator.get().evaluate([
        holecards[i],
        holecards[j],
        ...cardsFromBoard,
      ]);
      result = Math.max(result, handScore);
    }
  }

  return result;
}

// Take all 5 card combinations using holecards and boards and
// take the best
export function evaluateHoldem(pair: Pair, board: Card[]): number {
  let result = 0;

  const handIter = new ComboIterator([...pair.cards, ...board], 5);
  while (handIter.hasNext()) {
    const hand = handIter.next();
    const handScore = FiveCardEvaluator.get().evaluate(hand);
    result = Math.max(result, handScore);
  }
  return result;
}

export function evaluateFiveCards(cards: Card[]): number {
  return FiveCardEvaluator.get().evaluate(cards);
}

function rankingThreshold(score: number): number {
  for (const ranking of HandRankingThresholdList) {
    if (score < ranking) {
      return ranking;
    }
  }

  throw new Error("Hand score is above straight flush!?");
}

export function getRankingType(score: number): HandRanking {
  const rank = rankingThreshold(score);
  switch (rank) {
    case HandRankingTypeThresholds.HIGH_CARD:
      return "High Card";
    case HandRankingTypeThresholds.ONE_PAIR:
      return "One Pair";
    case HandRankingTypeThresholds.TWO_PAIR:
      return "Two Pair";
    case HandRankingTypeThresholds.THREE_OF_A_KIND:
      return "Three of a Kind";
    case HandRankingTypeThresholds.STRAIGHT:
      return "Straight";
    case HandRankingTypeThresholds.FLUSH:
      return "Flush";
    case HandRankingTypeThresholds.FULL_HOUSE:
      return "Full House";
    case HandRankingTypeThresholds.FOUR_OF_A_KIND:
      return "Four of a Kind";
    case HandRankingTypeThresholds.STRAIGHT_FLUSH:
      return "Straight Flush";
    default:
      return "High Card"; //Unreachable
  }
}
