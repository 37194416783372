import { defineComponent as _defineComponent } from 'vue'
import MatrixCard from "@/components/common/MatrixCard.vue";
import type { CellRenderData } from "@/poker-query-lib/matrix/MatrixData";
import { useParsedLibDataStore } from "@/stores/parsedLibData";
import { storeToRefs } from "pinia";
import NoDataOverlay from "@/components/common/NoDataOverlay.vue";
import type { ActionColors } from "@/stores/account";
import type { StateInfo } from "@/types/general";
import { getActionColor } from "@/features/action/actionColor";
import { isWhiteTextReadableOnBackground } from "@/utils/colors";
import { getActionPercentage } from "@/utils/utilityFunctions";

interface Props {
  actionListingForPreview?: string[];
  withoutItem?: boolean;
  allStartingHands?: string[];
  matrixData?: CellRenderData[];
  hideNoDataScreen?: boolean;
  omitCellModifiers?: boolean;
  isSingleMatrix?: boolean;
  isThirdMatrix?: boolean;
  stateInfo: StateInfo;
  reachTransparencyForPreview?: number;
  actionColorsForPreview?: ActionColors;
}

interface Emits {
  (e: "handleMatrixClick", index: number, selected: boolean): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MatrixComponent',
  props: {
    actionListingForPreview: {},
    withoutItem: { type: Boolean },
    allStartingHands: {},
    matrixData: {},
    hideNoDataScreen: { type: Boolean },
    omitCellModifiers: { type: Boolean },
    isSingleMatrix: { type: Boolean },
    isThirdMatrix: { type: Boolean },
    stateInfo: {},
    reachTransparencyForPreview: {},
    actionColorsForPreview: {}
  },
  emits: ["handleMatrixClick"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const { actionListing } = storeToRefs(useParsedLibDataStore());

const props = __props;
const emits = __emit;

const getActionsOfCard = (matrixData: CellRenderData) => {
  const numberOfActions = props.actionListingForPreview
    ? props.actionListingForPreview.length
    : actionListing.value.length;
  if (
    numberOfActions > 0 &&
    matrixData &&
    matrixData.actions &&
    matrixData.actions.length > 0
  ) {
    return [generateGradient(matrixData.actions)];
  }
  return [];
};
const isTextNeedShadow = (matrixData: CellRenderData): boolean => {
  const numberOfActions = props.actionListingForPreview
    ? props.actionListingForPreview.length
    : actionListing.value.length;
  if (
    numberOfActions > 0 &&
    matrixData &&
    matrixData.actions &&
    matrixData.actions.length > 0
  ) {
    return matrixData.actions.some((action) => {
      const [actionName, percentage] = action;
      const actionColor = getActionColor(actionName, props.stateInfo);
      if (actionColor !== "transparent") {
        return (
          percentage > 0.25 &&
          !isWhiteTextReadableOnBackground(
            getActionColor(actionName, props.stateInfo)
          )
        );
      } else {
        return false;
      }
    });
  } else {
    return false;
  }
};

const generateGradient = (actions: [string, number][]): string => {
  let gradient = "background: linear-gradient(to right, ";
  let percentageSum = 0;
  const isStateInfo = props.stateInfo;
  if (isStateInfo) {
    for (let i = 0; i < actions.length; i++) {
      const color = getActionColor(
        actions[i][0],
        props.stateInfo,
        props.actionColorsForPreview
      );
      const percentage = getActionPercentage(actions[i][1]);
      gradient += `${color} ${percentageSum}%, ${color} ${
        percentageSum + percentage
      }%`;
      if (i < actions.length - 1) {
        gradient += ", ";
      }
      percentageSum += percentage;
    }
  }
  gradient += ")";
  return gradient;
};

const getReachValue = (index: number) => {
  return props.matrixData &&
    props.matrixData[index] &&
    props.matrixData[index]?.reach
    ? props.matrixData[index].reach
    : 0;
};
const isCellHidden = (index: number) => {
  if (!props.omitCellModifiers) {
    if (props.matrixData && props.matrixData[index]) {
      return (
        !props.matrixData[index]?.selected &&
        props.matrixData.some((cell) => cell?.selected)
      );
    }
    return true;
  }
  return false;
};

const handleMatrixClick = (
  index: number,
  item: CellRenderData,
  selected: boolean
) => {
  if (item) {
    emits("handleMatrixClick", index, selected);
  }
};

const __returned__ = { actionListing, props, emits, getActionsOfCard, isTextNeedShadow, generateGradient, getReachValue, isCellHidden, handleMatrixClick, MatrixCard, get useParsedLibDataStore() { return useParsedLibDataStore }, get storeToRefs() { return storeToRefs }, NoDataOverlay, get getActionColor() { return getActionColor }, get isWhiteTextReadableOnBackground() { return isWhiteTextReadableOnBackground }, get getActionPercentage() { return getActionPercentage } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})