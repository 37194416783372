import type { Card, Combo, Pair } from "../Combos";
import { getCardArray, getMatrixCell } from "../Combos";
import type { NodeData, NodeGroup } from "../DecisionStrategy";
import { NodeAvgs } from "../DecisionStrategy";
import type { Matrix } from "../interfaces/matrix.interface";
import type { MatrixData } from "../matrix/MatrixData";
import {
  CellRenderData,
  EMPTY_NODE_GROUP,
  NONREACHING_PROBS,
  SingleMatrixData,
} from "../matrix/MatrixData";
import StrategyBuckets from "../StrategyBuckets";
import type { PokerBettingAction } from "../types/poker-betting-action.type";

export const FOUR_CARD_PAIRS: [number, number][] = [
  [0, 1],
  [0, 2],
  [0, 3],
  [1, 2],
  [1, 3],
  [2, 3],
];

const FOUR_CARD_TWO_TWO: [number[], number[]][] = [
  [
    [0, 1],
    [2, 3],
  ],
  [
    [0, 2],
    [1, 3],
  ],
  [
    [0, 3],
    [1, 2],
  ],
  [
    [1, 2],
    [0, 3],
  ],
  [
    [1, 3],
    [0, 2],
  ],
  [
    [2, 3],
    [0, 1],
  ],
];

export class FourCardMatrixU8 implements MatrixData {
  actionList: PokerBettingAction[];
  leftSelection: number | undefined; //Java does this with a PreflopPair. Let's just use a cell index for now???
  rightSelection: number | undefined; // ditto.

  leftSelectedPair: Pair | undefined; // Exact selection, not just Q7 suited, but Qd7d.

  // new StrategyBuckets(169) will give us 13x13 cells worth of data. This stores the *full*
  // DecisionStrategy in the buckets. When selections are applied, left and right may be
  // set to some filtered version of these, below.
  leftStratFull = new StrategyBuckets(169);
  rightStratFull = new StrategyBuckets(169); // Not used! This is only for rank ordered mode!

  // These represent the strats that we're going to display in the left and right matrices.
  leftStrat: StrategyBuckets | undefined; // Kinda not used! Will always equal leftStratFull in "normal" rankFilterMode
  rightStrat: StrategyBuckets | undefined;

  // Not currently supported, but this could also be "RankOrdered".
  // This determines whether the left matrix is selecting
  // ANY Pair, or the highest Pair in the four cards
  rankFilterMode = "Normal";

  // Current data to be displayed in the left/right matrices.
  // These will be averaged action probabilities or evs, from leftStrat / rightStrat
  // depending on whether we currently want to show evs.
  leftRenderData: SingleMatrixData;
  rightRenderData: SingleMatrixData;

  totalSummary: NodeAvgs = new NodeAvgs();

  // Cell data for the cell being hovered over - this would let us do popups that
  // show all of the hands in the current cell
  hoverData: NodeGroup | undefined;

  // Board cards can be specified here. Whenever we update cell data, we'll filter
  // out any combos that overlap with the cards listed here
  boardCards: Card[] = [];

  decisionStrategy: NodeGroup | undefined;
  selectedStrategy: NodeGroup | undefined;
  percentReach = 0;
  displayFreqs: number[] = [];

  constructor(actionList: PokerBettingAction[], board: Card[]) {
    this.actionList = actionList;
    this.leftRenderData = new SingleMatrixData(13, 13);
    this.rightRenderData = new SingleMatrixData(13, 13);
    this.boardCards = board;
  }

  getActionListing(): string[] | undefined {
    return this.actionList;
  }

  /**
   * Call changed when we query a new DecisionStrategy and would like to display it.
   * This fills out leftStratFull to be the data for the full DecisionStrategy.
   *
   * @param strategy The DecisionStrategy that we would like to display
   */
  setStrategy(strategy: NodeGroup, board: Card[]) {
    this.decisionStrategy = strategy;
    this.boardCards = board;
    this.leftRenderData = new SingleMatrixData(13, 13);
    this.rightRenderData = new SingleMatrixData(13, 13);

    this.leftStratFull = new StrategyBuckets(169);
    this.totalSummary.clear();

    // Iterate over all data in the Decision strategy. Each entry has a QUAD and NodeData.
    // For each pair in the quad, we want to add the NodeData to matrix cell corresponding
    // to that pair.
    for (const node of strategy.nodeList) {
      this.totalSummary.addNode(node);
      const combo = node.holecards;
      if (combo instanceof Uint8Array) {
        const cards = getCardArray(combo);
        for (const [i, j] of FOUR_CARD_PAIRS) {
          // Card order matters here, and we seem to be different than comments
          // would indicate!!!! TRIPLE CHECK THIS! Pretty sure it's backwards, but
          // that should be clear when we run it!
          const cell = getMatrixCell(cards[i], cards[j]);
          this.leftStratFull.addLocked(cell, node);
        }
        //Done of this combo, so unlock, and allow addition to all cells again.
        this.leftStratFull.unlock();
      }
    }

    //
    for (const combo of strategy.nonReaching) {
      this.totalSummary.addNonReaching();
      const cards = getCardArray(combo);
      for (const [i, j] of FOUR_CARD_PAIRS) {
        const matrixCell = getMatrixCell(cards[i], cards[j]);
        this.leftStratFull.addNonReachingLocked(matrixCell, combo);
      }
      this.leftStratFull.unlock();
    }

    this.leftStrat = this.leftStratFull;

    // Maintain existing selections if we can.
    this.updateStrategyForSelection();
  }

  /**
   * This updates the values leftStrat and rightStrat. This will happen whenever we
   * make a new selection in the matrix.
   */
  updateStrategyForSelection() {
    // If we have a selection, fill out the right hand matrix
    if (this.leftSelection !== undefined) {
      const right = new StrategyBuckets(169);

      // Grab the matrix cell currently selected in the left matrix, and iterate over
      // all the data in it.
      const bucket = this.leftStratFull.data[this.leftSelection];
      if (bucket) {
        bucket.nodeList.forEach((node) =>
          this.processNodeForSelection(node, right)
        );
        bucket.nonReaching.forEach((combo) =>
          this.processNonReachingComboForSelection(combo, right)
        );
      }
      this.rightStrat = right;
    } else {
      this.rightStrat = undefined as StrategyBuckets | undefined;
    }

    this.updateRenderData();
  }

  processNonReachingComboForSelection(combo: Combo, right: StrategyBuckets) {
    const cards = getCardArray(combo);
    for (const [[i, j], [x, y]] of FOUR_CARD_TWO_TWO) {
      const thisCoord = getMatrixCell(cards[i], cards[j]);
      //NOTE we used to do a check against a SPECIFIC COMBO filter here too!
      if (thisCoord === this.leftSelection) {
        const thatCoord = getMatrixCell(cards[x], cards[y]);
        right.addNonReachingLocked(thatCoord, combo);
      }
    }
    right.unlock();
  }

  // This is similar to how we filled the left matrix from the full DecisionStrategy.
  // However, in this case we want to determine matrix cells according to the
  // pair of cards NOT in the left selection. Do this by iterating over
  // "groupedPairs"
  private processNodeForSelection(
    node: NodeData,
    right: StrategyBuckets
  ): void {
    const combo = node.holecards;
    if (!(combo instanceof Uint8Array)) {
      return;
    }

    const cards = getCardArray(node.holecards);

    for (const [thisIndex, thatIndex] of FOUR_CARD_TWO_TWO) {
      const thisCoord = getMatrixCell(cards[thisIndex[0]], cards[thisIndex[1]]);
      if (
        thisCoord === this.leftSelection &&
        this.leftSelectedPair === undefined
      ) {
        const thatCoord = getMatrixCell(
          cards[thatIndex[0]],
          cards[thatIndex[1]]
        );
        right.addLocked(thatCoord, node);
      }
    }
    right.unlock();
  }

  /**
   * Called after updateStrategy or setStrategy. This builds the RenderData that we
   * pass on to the rendering code
   */
  updateRenderData() {
    this.updateSummaryInfo();
    if (this.leftStrat) {
      this.updateLeftRenderData();
    }

    if (this.rightStrat) {
      this.updateRightRenderData();
    }
  }

  private updateLeftRenderData() {
    for (let i = 0; i < 169; i++) {
      this.leftRenderData.cells[i] = NONREACHING_PROBS;
      const bucket = this.leftStrat?.data[i];
      if (bucket) {
        const cellData = this.getCellRenderData(
          bucket,
          i === this.leftSelection
        );
        this.leftRenderData.cells[i] = cellData;
      }
    }
    this.leftRenderData.selected = this.leftSelection;
  }

  private updateRightRenderData() {
    for (let i = 0; i < 169; i++) {
      this.rightRenderData.cells[i] = NONREACHING_PROBS;
      const bucket = this.rightStrat?.data[i];
      if (bucket) {
        const cellData = this.getCellRenderData(
          bucket,
          i === this.rightSelection
        );
        this.rightRenderData.cells[i] = cellData;
      }
    }
    this.rightRenderData.selected = this.rightSelection;
  }

  private getCellRenderData(
    bucket: NodeGroup,
    selected: boolean
  ): CellRenderData | null {
    const avg = bucket.getAvg();
    const probs = avg.probs;
    const ev = avg.ev || 0;
    if (probs) {
      return new CellRenderData(
        this.actionList,
        probs,
        avg.reach,
        ev,
        selected
      );
    }
    return NONREACHING_PROBS;
  }

  /**
   * This gets the tuple to show as an average summary for the whole strat.
   *
   * If there's a left sellection, rightStrat will be set to the contents of the selected
   * left cell, so show that. We're showing a summary of the clicked cell, in that case.
   *
   * Otherwise, leftStrat will be leftStratFull, and summarize
   * the overall DecisionStrategy.
   */
  updateSummaryInfo() {
    if (this.rightSelection !== undefined) {
      this.selectedStrategy = this.rightStrat?.data[this.rightSelection];
    } else if (this.leftSelection !== undefined) {
      this.selectedStrategy = this.leftStrat?.data[this.leftSelection];
    } else {
      this.selectedStrategy = this.decisionStrategy;
    }
  }

  getSelectedStrat(): NodeGroup {
    return this.selectedStrategy || EMPTY_NODE_GROUP;
  }

  /**
   * Get the render data for one of the matrices. 0=Left, 1=Right
   */
  getRenderData(): [Matrix, Matrix | null] {
    const rightDisplay =
      this.leftSelection !== undefined ? this.rightRenderData : null;
    return [this.leftRenderData, rightDisplay];
  }

  /**
   * Handle a "click" in a given cell.
   */
  handleClicks(id: number, cellItem: number): void {
    if (id === 0) {
      this.leftSelection =
        this.leftSelection !== cellItem ? cellItem : undefined;
      this.rightSelection = undefined as number | undefined;
    }
    if (id === 1 && this.rightStrat) {
      this.rightSelection =
        this.rightSelection !== cellItem ? cellItem : undefined;
    }
    this.updateStrategyForSelection();
  }

  /**
   * Update hover data, when mouse hover in a certain cell
   */
  handleHover(id: number, point: [number, number] | undefined): void {
    if (point) {
      const [x, y] = point;
      const i = 13 * y + x;
      if (id === 0 && this.leftSelection === undefined) {
        this.hoverData = this.leftStratFull.data[i];
      } else if (id === 1 && this.rightStrat !== undefined) {
        this.hoverData = this.rightStrat.data[i];
      }
    } else {
      this.hoverData = undefined as NodeGroup | undefined;
    }
  }

  getHoverData(): NodeGroup | undefined {
    return this.hoverData;
  }
}
