import { defineComponent as _defineComponent } from 'vue'
import { ref } from "vue";
import AppDropdown from "@/components/common/app/AppDropdown.vue";
import AppSettingsModal from "@/components/AppSettingsModal.vue";
import { useUserStore } from "@/stores/user";
import { useRouter } from "vue-router";
import { useFocusMode } from "@/composables/useFocusMode";
import { useLayoutStore } from "@/stores/layout";


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderSettings',
  setup(__props, { expose: __expose }) {
  __expose();

const userStore = useUserStore();
const router = useRouter();
const { changeFocusModeState } = useFocusMode();
const layoutStore = useLayoutStore();

const isShowSettingsModal = ref(false);

const showSettingsModal = () => {
  isShowSettingsModal.value = true;
};
const onFullScreenBtn = () => {
  layoutStore.changeAppHeaderInAbsolutePosState(false);
  changeFocusModeState();
};
const logout = () => {
  return userStore.logout().then(() => {
    return router.push("/login");
  });
};

const __returned__ = { userStore, router, changeFocusModeState, layoutStore, isShowSettingsModal, showSettingsModal, onFullScreenBtn, logout, ref, AppDropdown, AppSettingsModal, get useUserStore() { return useUserStore }, get useRouter() { return useRouter }, get useFocusMode() { return useFocusMode }, get useLayoutStore() { return useLayoutStore } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})