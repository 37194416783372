import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "toaster" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "toaster__content" }
const _hoisted_4 = {
  key: 0,
  class: "toaster__title"
}
const _hoisted_5 = { class: "toaster__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "toaster__icon",
      src: require(`@/assets/icons/${$props.toastProps?.type}-circle.svg`)
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      ($props.toastProps.data.title)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString($props.toastProps.data.title), 1))
        : _createCommentVNode("", true),
      _createElementVNode("p", _hoisted_5, _toDisplayString($props.toastProps.data.description), 1)
    ])
  ]))
}