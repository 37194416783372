import { SoundEnum } from "@/enums/soundEnum";

const soundPaths = {
  [SoundEnum.pressActionBtn]: "sounds/press-action-btn.mp3",
};

const sounds: Record<string, HTMLAudioElement> = {};

export const initSounds = () => {
  Object.entries(soundPaths).forEach(([name, path]) => {
    if (!sounds[name]) {
      const audio = new Audio(path);
      audio.load();
      sounds[name] = audio;
    }
  });
};

export const playSound = (name: string) => {
  const audio = sounds[name];
  if (audio) {
    audio.currentTime = 0;
    audio.play();
  }
};
