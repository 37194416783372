import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  class: "wrapper",
  ref: "wrapper"
}
const _hoisted_2 = { class: "options" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["MatrixCard"], {
      class: "reach-demo-cell",
      "action-of-card": [$setup.colorsForCell],
      reach: 0.5,
      "reach-transparency-for-preview": $setup.selectedReachTransparency / 100
    }, null, 8, ["action-of-card", "reach-transparency-for-preview"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.transparencyOptions, (option) => {
        return _createVNode($setup["FilterComponent"], {
          key: option,
          label: option.label,
          value: option.value,
          type: "radio",
          modelValue: $setup.selectedReachTransparency,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedReachTransparency) = $event))
        }, null, 8, ["label", "value", "modelValue"])
      }), 64))
    ]),
    _withDirectives(_createElementVNode("input", {
      class: "range-input",
      ref: "rangeInput",
      type: "range",
      min: "0",
      max: "100",
      step: "1",
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.selectedReachTransparency) = $event))
    }, null, 512), [
      [_vModelText, $setup.selectedReachTransparency]
    ]),
    _createElementVNode("div", {
      class: "range-input__tooltip",
      ref: "rangeTooltip"
    }, _toDisplayString($setup.selectedReachTransparency) + "%", 513)
  ], 512))
}