import { ref, shallowRef } from "vue";
import { defineStore } from "pinia";
import type { QueryResponse } from "@/poker-query-lib";
import type {
  AvailableFilterListing,
  FilterSet,
} from "@/poker-query-lib/types/filter-types";
import type { NodeData } from "@/poker-query-lib/DecisionStrategy";
import type { CellRenderData } from "@/poker-query-lib/matrix/MatrixData";
import type { MatrixCellAction } from "@/poker-query-lib/types/matrix-cell-action.type";
import { useLayoutStore } from "@/stores/layout";

export const useParsedLibDataStore = defineStore(
  "generalData",
  () => {
    let parsedResponse: QueryResponse | void | null;
    const parsedResponseInitial = shallowRef<QueryResponse | void | null>();
    const availableFilters = shallowRef<AvailableFilterListing>([]);
    const percentReach = ref<number>(0);
    const percentRange = ref<number>(0);
    const rangeData = shallowRef<NodeData[]>([]);
    const actionListing = shallowRef([]);
    const firstMatrixData = ref<CellRenderData[]>([]);
    const secondMatrixData = ref<CellRenderData[]>([]);
    const thirdMatrixData = ref<CellRenderData[]>([]);
    const frequencyActions = shallowRef<MatrixCellAction[]>([]);
    const frequencyActionsWithoutFilters = shallowRef<MatrixCellAction[]>([]);
    const loading = ref<boolean>(false);
    const searchIsValid = ref<boolean>(true);
    const summaryRangeDataCopies = shallowRef<NodeData[][]>([]);
    const layoutStore = useLayoutStore();

    const setMatrixData = (isRequest = false) => {
      if (!parsedResponse) {
        parsedResponse = parsedResponseInitial.value;
        parsedResponseInitial.value = null;
      }
      if (
        parsedResponse &&
        typeof parsedResponse.availableFilters === "function"
      ) {
        loading.value = true;
        availableFilters.value = parsedResponse.availableFilters();
        const matrixTabData = parsedResponse.getMatrixTabData();
        percentReach.value = matrixTabData.percentReach;
        percentRange.value = parsedResponse.percentRange;
        actionListing.value = matrixTabData.actionListing?.betting.map(
          (item) => item[0]
        ) as [];
        firstMatrixData.value = [
          ...((matrixTabData.matrices[0]?.cells as CellRenderData[]) || []),
        ];
        secondMatrixData.value = [
          ...((matrixTabData.matrices[1]?.cells as CellRenderData[]) || []),
        ];
        thirdMatrixData.value = [
          ...((matrixTabData.matrices[2]?.cells as CellRenderData[]) || []),
        ];

        if (isRequest) {
          frequencyActionsWithoutFilters.value =
            parsedResponse.getOverallTuple();
        }
        frequencyActions.value = parsedResponse.getOverallTuple();
        rangeData.value = parsedResponse.getRangeTabData();
        getSummaryRangeDataCopies();
        layoutStore.setIsQueryAreaEnabled(false);
        setTimeout(() => {
          loading.value = false;
        });
      }
    };

    const getSummaryRangeDataCopies = () => {
      summaryRangeDataCopies.value = [];
      frequencyActions.value.forEach(() => {
        summaryRangeDataCopies.value.push(rangeData.value);
      });
    };

    const getSummaryRangeDataCopyByIndex = (index: number) => {
      return summaryRangeDataCopies.value[index] || [];
    };

    const clearMatrixData = () => {
      layoutStore.setIsQueryAreaEnabled(true);
      availableFilters.value = [];
      firstMatrixData.value = [];
      secondMatrixData.value = [];
      thirdMatrixData.value = [];
      frequencyActionsWithoutFilters.value = [];
      frequencyActions.value = [];
      rangeData.value = [];
      summaryRangeDataCopies.value = [];
      parsedResponse = null;
    };

    const applyFilter = (value: FilterSet) => {
      if (parsedResponse) {
        parsedResponse.applyFilter(value);
      }
    };

    const setComboSearch = (value: string) => {
      if (parsedResponse) {
        searchIsValid.value = parsedResponse.setComboSearch(value);
      }
    };

    const handleMatrixClicks = (id: number, index: number) => {
      if (parsedResponse) {
        parsedResponse.handleMatrixClicks(id, index);
      }
    };

    const isLeftMatrixHasSelected = () => {
      return firstMatrixData.value.some((cell) => cell?.selected);
    };

    const setRangeData = (rangeArr: NodeData[]) => {
      rangeData.value = [...rangeArr];
    };

    return {
      actionListing,
      applyFilter,
      availableFilters,
      clearMatrixData,
      firstMatrixData,
      frequencyActions,
      frequencyActionsWithoutFilters,
      getSummaryRangeDataCopyByIndex,
      handleMatrixClicks,
      isLeftMatrixHasSelected,
      loading,
      parsedResponseInitial,
      percentRange,
      percentReach,
      rangeData,
      secondMatrixData,
      setComboSearch,
      setMatrixData,
      setRangeData,
      thirdMatrixData,
    };
  },
  {
    persist: false,
    share: {
      enable: false,
    },
  }
);
