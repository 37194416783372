import { defineComponent as _defineComponent } from 'vue'
import HeaderSettings from "@/components/HeaderSettings.vue";
import NavHeader from "@/components/NavHeader.vue";

interface Props {
  isPreflop?: boolean;
  isEmptyLayout?: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderComponent',
  props: {
    isPreflop: { type: Boolean },
    isEmptyLayout: { type: Boolean }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();



const __returned__ = { HeaderSettings, NavHeader }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})