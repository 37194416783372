import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "settings__wrapper" }
const _hoisted_3 = { class: "settings" }
const _hoisted_4 = { class: "settings__section" }
const _hoisted_5 = { class: "settings__section--actions" }
const _hoisted_6 = { class: "settings__title" }
const _hoisted_7 = { class: "settings__section--reach" }
const _hoisted_8 = { class: "settings__title" }
const _hoisted_9 = { class: "preview-wrapper" }
const _hoisted_10 = { class: "settings__title" }
const _hoisted_11 = { class: "settings__title" }
const _hoisted_12 = { class: "cards-wrapper" }
const _hoisted_13 = { class: "settings-page__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("actionColors")), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.actionConfigs, (action, index) => {
              return (_openBlock(), _createBlock($setup["ActionColorPicker"], {
                key: index,
                color: action.color,
                "action-label": action.actionLabel,
                "action-key": action.actionKey,
                "with-raise-tooltip": action.withRaiseTooltip,
                "is-rectangle": action.isRectangle,
                onSelectColor: $setup.setActionColor
              }, null, 8, ["color", "action-label", "action-key", "with-raise-tooltip", "is-rectangle"]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("p", _hoisted_8, "Reach " + _toDisplayString(_ctx.$t("transparency")), 1),
            _createVNode($setup["ReachTransparencySelect"], {
              "reach-transparency": $setup.selectedSettings.reachTransparency,
              onSetReachTransparency: $setup.setReachTransparency
            }, null, 8, ["reach-transparency"])
          ])
        ]),
        _createVNode($setup["CardsDesignSelect"], {
          "selected-cards-design": $setup.selectedSettings.cardsDesign,
          onSetSelectedCardsDesign: $setup.setSelectedCardsDesign
        }, null, 8, ["selected-cards-design"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t("matrixPreview")), 1),
        _createVNode($setup["MatrixComponent"], {
          "state-info": $setup.stateInfoForPreview,
          "matrix-data": $setup.matrixDataForPreview,
          "all-starting-hands": $setup.allStartingHands,
          "action-listing-for-preview": $setup.actionListingForPreview,
          "reach-transparency-for-preview": $setup.selectedSettings.reachTransparency,
          "action-colors-for-preview": $setup.selectedSettings.actionColors,
          "without-item": true
        }, null, 8, ["state-info", "matrix-data", "all-starting-hands", "action-listing-for-preview", "reach-transparency-for-preview", "action-colors-for-preview"]),
        _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t("cardsPreview")), 1),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", {
            class: _normalizeClass(["clubs preview", $setup.selectedSettings.cardsDesign])
          }, "A", 2),
          _createElementVNode("div", {
            class: _normalizeClass(["diamonds preview", $setup.selectedSettings.cardsDesign])
          }, "A", 2),
          _createElementVNode("div", {
            class: _normalizeClass(["hearts preview", $setup.selectedSettings.cardsDesign])
          }, "A", 2),
          _createElementVNode("div", {
            class: _normalizeClass(["spades preview", $setup.selectedSettings.cardsDesign])
          }, "A", 2)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createVNode($setup["AppButton"], {
        class: "settings-page__reset-btn",
        "is-outline": true,
        onOnClick: $setup.resetSettingsToDefault
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("resetToDefaults")), 1)
        ]),
        _: 1
      }),
      _createVNode($setup["AppButton"], {
        class: "settings-page__save-btn",
        onOnClick: $setup.saveChanges,
        "is-disabled": $setup.isSaveDisabled
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("saveChanges")), 1)
        ]),
        _: 1
      }, 8, ["is-disabled"])
    ])
  ]))
}