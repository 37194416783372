import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/icons/logo.svg'
import _imports_1 from '@/assets/images/cards-bg-gray.png'


const _hoisted_1 = {
  key: 0,
  class: "wrapper--with-filters"
}
const _hoisted_2 = { class: "wrapper__title" }
const _hoisted_3 = { class: "wrapper__subtitle" }
const _hoisted_4 = {
  key: 1,
  class: "wrapper__title"
}
const _hoisted_5 = {
  key: 1,
  class: "wrapper--query-area"
}
const _hoisted_6 = { class: "wrapper--with-filters" }
const _hoisted_7 = { class: "wrapper__title-bg" }
const _hoisted_8 = { class: "wrapper__subtitle-bg" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "wrapper--with-filters" }
const _hoisted_11 = { class: "wrapper__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!$props.queryAreaMode)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["wrapper", { 'wrapper--dimmed-effect': $props.isDimmed }])
      }, [
        _cache[4] || (_cache[4] = _createElementVNode("img", {
          class: "wrapper__logo",
          src: _imports_0,
          alt: "Vertex logo"
        }, null, -1)),
        ($props.isActiveFilters && $props.showLabel)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("noDataTitle")), 1),
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("noDataSubtitle")), 1),
              _createElementVNode("button", {
                class: "wrapper__button",
                onClick: $setup.resetFilters
              }, _toDisplayString(_ctx.$t("resetFilters")), 1)
            ]))
          : (!$props.isActiveFilters && $props.showLabel)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t("noData")), 1))
            : _createCommentVNode("", true)
      ], 2))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
        ($props.isGameEnd)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["wrapper__content", { 'wrapper__content--end-of-the-game': $props.isGameEnd }])
            }, [
              _cache[5] || (_cache[5] = _createElementVNode("img", {
                class: "wrapper__logo-cards",
                src: _imports_1,
                alt: "cards-bg"
              }, null, -1)),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("handOver")), 1),
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("endOfTheGame")), 1),
                _createElementVNode("button", {
                  class: "wrapper__button-reset",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.onReset()))
                }, _toDisplayString(_ctx.$t("startNewGame")), 1)
              ])
            ], 2))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "wrapper__content",
              onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.emits('click-on-query-area'))),
              onMouseover: _cache[2] || (_cache[2] = ($event: any) => ($setup.isWrapperHover = true)),
              onMouseleave: _cache[3] || (_cache[3] = ($event: any) => ($setup.isWrapperHover = false))
            }, [
              _createElementVNode("img", {
                class: "wrapper__logo",
                src: $setup.logoSrc,
                alt: "Vertex logo"
              }, null, 8, _hoisted_9),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t("clickToViewRange")), 1)
              ])
            ], 32))
      ]))
}