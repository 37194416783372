import { axiosNoCache } from "@/services/api/index";
import type { ApiClientResponse } from "@/types/api/ApiClientResponse";
import type { UserInfo } from "@/types/user";

export default {
  getMe(): Promise<ApiClientResponse<UserInfo>> {
    return axiosNoCache.get("users/me");
  },
  updateUser(data: Partial<UserInfo>): Promise<ApiClientResponse<UserInfo>> {
    return axiosNoCache.patch("users", data);
  },
};
