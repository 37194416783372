import type { DirectiveBinding } from "vue";

interface ClickOutsideElement extends HTMLElement {
  clickOutsideEvent?: (event: Event) => void;
}

export const clickOutside = {
  beforeMount(el: ClickOutsideElement, binding: DirectiveBinding) {
    el.clickOutsideEvent = function (event: Event) {
      if (!(el === event.target || el.contains(event.target as Node))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted(el: ClickOutsideElement) {
    document.body.removeEventListener(
      "click",
      el.clickOutsideEvent as EventListener
    );
  },
};
