/**
 * Handy wrapper of a Uint8Array. Keep track of how much we've read,
 *  and offer convenvience functions for reading a bunch of different
 *  formats.
 */
export class UorgBuff {
  size: number;
  pos = 0;
  dataView: DataView;

  constructor(bytes: Uint8Array, size: number) {
    this.dataView = new DataView(bytes.buffer);
    this.size = size;
  }

  readF64(): number {
    const result = this.dataView.getFloat64(this.pos, true);
    this.pos += 8;
    return result;
  }

  readF32(): number {
    const result = this.dataView.getFloat32(this.pos, true);
    this.pos += 4;
    return result;
  }

  readF16(): number {
    const bytes = this.readU16();
    // repack the bytes into a u32 buffer, and reinterpret as a float-32.
    const sign = (bytes & 0x8000) >> 15;
    const exponent16 = (bytes & 0x7c00) >> 10; //5 bits
    const mantissa16 = bytes & 0x3ff; //10 bits

    //Shift our 10-bit mantissa up 13 bits to be the most significant of 23-bit mantissa
    const mantissa32 = mantissa16 << 13;
    const exponent32 = exponent16 - 15 + 127;

    const resultAsInt = (sign << 31) | (exponent32 << 23) | mantissa32;

    //Reinterpret as float
    return new Float32Array(new Uint32Array([resultAsInt]).buffer)[0];
  }

  readU16(): number {
    const result = this.dataView.getUint16(this.pos, true);
    this.pos += 2;
    return result;
  }

  readU32(): number {
    const result = this.dataView.getUint32(this.pos, true);
    this.pos += 4;
    return result;
  }

  readU64(): bigint {
    const result = this.dataView.getBigUint64(this.pos, true);
    this.pos += 8;
    return result;
  }

  readU8(): number {
    const result = this.dataView.getUint8(this.pos);
    this.pos++;
    return result;
  }

  readUInt(numBytes: number): number {
    switch (numBytes) {
      case 1:
        return this.readU8();
      case 2:
        return this.readU16();
      case 4:
        return this.readU32();
      default: {
        throw new Error("Unexpected int size: " + numBytes);
      }
    }
  }

  readFloat32Array(n: number): number[] {
    const result = [];
    for (let i = 0; i < n; i++) {
      result.push(this.readF32());
    }

    return result;
  }

  readBytesToArray(n: number): number[] {
    const result = [];
    for (let i = 0; i < n; i++) {
      result.push(this.readU8());
    }
    return result;
  }

  readScaledArray(probMultiplier: number, length: number): number[] {
    if (probMultiplier !== 100) {
      return this.readFloat32Array(length).map((x) => x / probMultiplier);
    } else {
      return this.readBytesToArray(length);
    }
  }

  readScaledNumber(probMultiplier: number): number {
    return this.readScaledArray(probMultiplier, 1)[0];
  }

  isDone(): boolean {
    return this.pos >= this.size;
  }
}

/**
 * More parser helpers. A FloatReader reads a float from a Buff.
 * Each implementation reads a different precision/format.
 * (See: NodeSerializer in ParseQueryV2.ts.)
 */
export interface FloatReader {
  get(buff: UorgBuff): number;
}

export const F32Reader: FloatReader = {
  get(buff): number {
    return buff.readF32();
  },
};

export const F16Reader: FloatReader = {
  get(buff): number {
    return buff.readF16();
  },
};

export const F8Lookup = [
  0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0, 1.1, 1.2, 1.302,
  1.4127, 1.5327, 1.663, 1.8044, 1.9578, 2.1242, 2.3047, 2.5006, 2.7132, 2.9438,
  3.194, 3.4655, 3.7601, 4.0797, 4.4265, 4.8027, 5.2109, 5.6539, 6.1345, 6.6559,
  7.2216, 7.8355, 8.5015, 9.2241, 10.0082, 10.8589, 11.7819, 12.7833, 13.8699,
  15.0488, 16.328, 17.7159, 19.2217, 20.8556, 22.6283, 24.5517, 26.6386,
  28.9029, 31.3596, 34.0252, 36.9173, 40.0553, 43.46, 47.1541, 51.1622, 55.511,
  60.2294, 65.3489, 70.9036, 76.9304, 83.4695, 90.5644, 98.2623, 106.6146,
  115.6769, 125.5094, 136.1777, 147.7528, 160.3118, 173.9383, 188.7231,
  204.7646, 222.1695, 241.054, 261.5435, 283.7747, 307.8956, 334.0667, 362.4624,
  393.2717, 426.6998, 462.9692, 502.3216, 545.019, 591.3456, 641.61, 696.1469,
  755.3193, 819.5215, 889.1808, 964.7612, 1046.7659, 1135.741, 1232.2789,
  1337.0227, 1450.6697, 1573.9766, 1707.7645, 1852.9246, 2010.4231, 2181.3091,
  2366.7205, 2567.8916, 2786.1624, 3022.9861, 3279.9399, 3558.7349, 3861.2273,
  4189.4316, 4545.5332, 4931.9033, 5351.1152, 5805.96, 6299.4668, 6834.9214,
  7415.8896, 8046.2402, 8730.1709, 9472.2354, 10277.375, 11150.9521, 12098.7832,
  13127.1797, 14242.9902,
];

export const F8Reader: FloatReader = {
  get(buff): number {
    const byte = buff.readU8();
    const sign = (byte & 0x80) !== 0 ? -1 : 1;
    const value = F8Lookup[byte % 128];

    return sign * value;
  },
};

export const U16Reader: FloatReader = {
  get(buff): number {
    return buff.readU16() / 65535.0;
  },
};

export const U8Reader: FloatReader = {
  get(buff): number {
    return buff.readU8() / 255.0;
  },
};
