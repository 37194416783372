import { positionMap } from "@/features/pokerTablePositions";
import { createFilledArray } from "@/utils/array";
import { PokerTablePositionEnum } from "@/enums/pokerTableEnum";
import { PlayerCardTypeEnum, PlayerStateEnum } from "@/enums/playerEnum";
import type { PlayerWithAdditionalInfo } from "@/types/player";
import type { GameConfig } from "@/types/gameSettings";

export const changePlayerListForQuiz = (
  arr: PokerTablePositionEnum[],
  newBottomCenterIdx: number
) => {
  const bottomCenterIdx = arr.indexOf(PokerTablePositionEnum.bottomCenter);
  const copy = [...arr];
  const numShifts = newBottomCenterIdx - bottomCenterIdx;
  if (numShifts > 0) {
    const shiftedElements = copy.splice(-numShifts);
    copy.unshift(...shiftedElements);
  } else if (numShifts < 0) {
    const shiftedElements = copy.splice(0, -numShifts);
    copy.push(...shiftedElements);
  }
  return copy;
};

export const createPlayers = (
  gameConfig: GameConfig,
  positionList?: PokerTablePositionEnum[]
) => {
  const { firstPlayer, numPlayers, playerNames, numCards } = gameConfig;
  const dealerIdx = (firstPlayer[1] + numPlayers - 1) % numPlayers;

  return playerNames.map((name, playerIndex) => ({
    name,
    playerIndex,
    position: positionList
      ? positionList[playerIndex]
      : positionMap[numPlayers][playerIndex],
    isDealer: playerIndex === dealerIdx,
    cardList: createFilledArray(numCards, {
      isBack: true,
      value: "",
      type: PlayerCardTypeEnum.regular,
    }),
  }));
};

export const isPlayerFolded = (player: PlayerWithAdditionalInfo) => {
  return player.state === PlayerStateEnum.folded;
};

export const isPlayerActive = (player: PlayerWithAdditionalInfo) => {
  return player.state === PlayerStateEnum.active;
};
