export enum PokerTablePositionEnum {
  bottomCenter = "bottom-center",
  bottomLeft = "bottom-left",
  bottomRight = "bottom-right",
  middleLeft = "middle-left",
  middleRight = "middle-right",
  topCenter = "top-center",
  topLeft = "top-left",
  topRight = "top-right",
}
