import type { Matrix } from "../interfaces/matrix.interface";
import type { MatrixCellAction } from "../types/matrix-cell-action.type";
import type { PokerBettingAction } from "../types/poker-betting-action.type";
import { SingleMatrixPlusSuitsData } from "./SingleMatrixData";
import { FiveCardMatrixU8 } from "./FiveCardMatrixU8";
import type { DecisionStrategy } from "../DecisionStrategy";
import { NodeGroup } from "../DecisionStrategy";
import type { Card } from "../Combos";
import {
  getMatrixCellForCanonPair,
  getMatrixCellForCanonPairShortdeck,
} from "../Combos";
import { FourCardMatrixU8 } from "./FourCardMatrixU8";
import { RazzTriangle } from "./RazzTriangle";

/**
 * Data passed to matrix to render a single cell
 */
export class CellRenderData {
  actions: MatrixCellAction[];
  reach: number;
  selected: boolean;
  expectedValue: number;

  constructor(
    actionList: PokerBettingAction[],
    tuple: number[],
    reach: number,
    ev: number,
    selected: boolean
  ) {
    // this.actions = tuple
    this.actions = actionList.map((a, i) => [a, tuple[i]]);
    this.reach = reach;
    this.expectedValue = ev;
    this.selected = selected;
  }
}

/**
 * This version is used for render ACTION PROBABILITIES
 *
 * Stores matrix dimensions, list of actions,
 * and one ArrayCellData (=tuple + reach + render mode) per cell
 */
export class SingleMatrixData {
  selected?: number; // Currently selected cell (target to highlight)
  cells: Array<CellRenderData | null>;

  constructor(rows: number, columns: number) {
    this.cells = Array.from({ length: rows * columns });
  }
}

export const NONREACHING_PROBS = null;
export const EMPTY_NODE_GROUP = new NodeGroup();

//Ahhhh, see also the interface type! Let's not have both. Let's just have a function that makes these.
/*
export class ActionListing {
  betting: PokerBettingAction[] = [];
  fullMasks?: PokerBettingAction[];
  isDiscardAction: boolean = false;

  constructor(actions: PokerBettingAction[]) {
    this.betting = actions;
  }
}*/

/**
 * Matrix data is a VIEW of a DecisionStrategy, which manages clicks
 * and returns data in a way that can be visualized in the matrix components
 *
 * I.e. this will do things like, stores aggregate data PER CELL. Or for Omaha,
 *  manage what ought be displayed in each cell. This is the abstract matrix state,
 *  where as the matrix rendering should take, more or less, just a big arrray of colors
 *  to draw, more or less.
 *
 */
export interface MatrixData {
  // Access main data for rendering matrix
  getRenderData(): (Matrix | null)[];

  // Process mouse events
  handleClicks(matrixIndex: number, cellIndex: number): void;

  // Set the strategy to display in the matrix
  setStrategy(decisionStrategy: NodeGroup, board: Card[]): void;

  // Get the strategy after being filtered by matrix selection
  getSelectedStrat(): NodeGroup;
}

/**
 *  Generate matrix data of the appropriate type, based on game
 */
export function createMatrixData(
  game: string,
  board: Card[],
  ds: DecisionStrategy
): MatrixData {
  game = game.toLocaleLowerCase();
  if (game.startsWith("fivecardomaha") || game.startsWith("draw")) {
    const result = new FiveCardMatrixU8(ds.actionListing.betting, board);
    result.setStrategy(ds.decisionNodes, board);
    return result;
  } else if (game.startsWith("omaha") || game === "badugi") {
    const result = new FourCardMatrixU8(ds.actionListing.betting, board);
    result.setStrategy(ds.decisionNodes, board);
    return result;
  } else if (game.startsWith("holdem") || game.startsWith("studhi")) {
    const result = new SingleMatrixPlusSuitsData(
      ds.actionListing.betting,
      13,
      getMatrixCellForCanonPair
    );
    result.setStrategy(ds.decisionNodes, board);
    return result;
  } else if (game.startsWith("shortdeck")) {
    const result = new SingleMatrixPlusSuitsData(
      ds.actionListing.betting,
      7,
      getMatrixCellForCanonPairShortdeck
    );
    result.setStrategy(ds.decisionNodes, board);
    return result;
  } else if (game.startsWith("razz")) {
    const result = new RazzTriangle(ds.actionListing.betting);
    result.setStrategy(ds.decisionNodes, board);
    return result;
  }

  throw new Error("Unhandled game type: " + game);
}
