import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "settings" }
const _hoisted_2 = { class: "settings__header-wr" }
const _hoisted_3 = { class: "settings__header" }
const _hoisted_4 = { class: "settings__wr" }
const _hoisted_5 = { class: "settings__sidebar" }
const _hoisted_6 = { class: "settings__sidebar-list" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "settings__page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["AppModal"], {
    class: "modal__app-settings",
    "is-open": true,
    onClose: $setup.close
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("settings")), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("ul", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.tabsList, (item) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: _normalizeClass(["settings__sidebar-list-item", [{ active: item.id === $setup.currentTab.id }, item.class]]),
                  key: item.id,
                  onClick: ($event: any) => ($setup.currentTab = item)
                }, _toDisplayString(item.label), 11, _hoisted_7))
              }), 128))
            ]),
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "settings__sidebar-footer" }, null, -1))
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_Transition, {
              name: "fade",
              mode: "out-in"
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent($setup.currentTab.component), { onClose: $setup.close }, null, 32))
              ]),
              _: 1
            })
          ])
        ])
      ])
    ]),
    _: 1
  }))
}