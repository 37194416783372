import { ref } from "vue";
import type { PlayerList } from "@/entities/Player";
import { PlayerCardTypeEnum } from "@/enums/playerEnum";

export const useUpCards = () => {
  const upCards = ref<string[]>([]);

  const initUpCards = (upCardsArr?: string[]) => {
    setUpCards(upCardsArr ? upCardsArr : []);
  };

  const setUpCards = (cards: string[]) => {
    upCards.value = cards;
  };

  const setPlayerListInitialUpCards = (
    cards: string[],
    playerListObj: PlayerList
  ): PlayerList => {
    cards.forEach((card, idx) => {
      playerListObj.data[idx].addCard({
        isBack: false,
        value: card,
        type: PlayerCardTypeEnum.upCard,
      });
    });
    return playerListObj;
  };

  return { upCards, setUpCards, initUpCards, setPlayerListInitialUpCards };
};
