import type { Combo } from "./Combos";
import type { NodeData } from "./DecisionStrategy";
import { NodeGroup } from "./DecisionStrategy";

export default class StrategyBuckets {
  count: number;
  locked: boolean[]; //Can avoid the need for this by caching which canonical pairs appear in each quad.
  data: NodeGroup[]; //This is an array... one for each cell, EACH OF THESE has its own summary

  constructor(size: number) {
    this.count = size;

    this.locked = Array.from({ length: size });
    this.locked.fill(false);

    this.data = [];
  }

  unlock() {
    this.locked.fill(false);
  }

  add(cell: number, node: NodeData) {
    if (this.data[cell] === undefined) {
      this.data[cell] = new NodeGroup();
    }
    this.data[cell].add(node);
  }

  addLocked(cell: number, node: NodeData) {
    if (this.data[cell] === undefined) {
      this.data[cell] = new NodeGroup();
    }
    if (!this.locked[cell]) {
      this.data[cell].add(node);
    }
    this.locked[cell] = true;
  }

  addNonReaching(cell: number, combo: Combo) {
    if (this.data[cell]) {
      this.data[cell].addNonReaching(combo);
    }
  }

  addNonReachingLocked(cell: number, combo: Combo) {
    if (!this.locked[cell] && this.data[cell]) {
      this.data[cell].addNonReaching(combo);
      this.locked[cell] = true;
    }
  }
}
