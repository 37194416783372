import { PokerActionsEnum } from "@/poker-query-lib/enums/poker-actions.enum";

export const isRaiseOrBetActionName = (action: string) => {
  return (
    action[0] === PokerActionsEnum.RAISE || action[0] === PokerActionsEnum.BET
  );
};

export const isTerminalActionName = (str?: string) => {
  if (str) {
    return str.endsWith("$");
  }
  return false;
};

export const isFoldActionName = (action: string) => {
  return action.startsWith(PokerActionsEnum.FOLD);
};

export const isCheckActionName = (action: string) => {
  return action.startsWith(PokerActionsEnum.CHECK);
};

export const isDiscardActionName = (actionName: string) => {
  return actionName.startsWith(PokerActionsEnum.DISCARD);
};

export const isGameEndingActionName = (actionName?: string) => {
  if (actionName) {
    return actionName.endsWith("$");
  }
  return false;
};
export const isRoundEndingActionName = (actionName?: string) => {
  if (actionName) {
    return actionName.endsWith("/");
  }
  return false;
};
