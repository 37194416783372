import {
  settingsFieldsPreflop,
  settingsFieldsStudy,
} from "@/constants/gameSettings";
import { useGameStore } from "@/stores/game";
import { useSequenceStore } from "@/stores/sequence";
import { useStudySequenceStore } from "@/stores/studySequence";
import {
  SeqActionStateEnum,
  SeqActionTypeEnum,
  SeqActionUiStateEnum,
} from "@/enums/sequenceEnum";
import type { GameCategoryEnum } from "@/enums/gameEnum";
import { GameTypeEnum } from "@/enums/gameEnum";
import pokerApi from "@/services/pokerApi";
import {
  getBoardCardsAction,
  getEmptyActions,
  getOpenAction,
  getTerminatedAction,
} from "@/features/sequence/action";
import { parseCardsFromString } from "@/features/cards/cards";
import {
  getStrategyFromConfig,
  isDrawGameCategory,
  isFlopGameCategory,
  isStudGameCategory,
} from "@/features/gameSettings";
import {
  createSeqWithSkippedAndEmptyActions,
  getNumOfEmptyActionThatShouldBeAddedToSeq,
} from "@/features/sequence/utils";

import type { SeqStateObject } from "@/types/sequence";
import type { QuizSetup } from "@/types/quiz";
import { BoardMode, GameMode } from "@/enums/quizEnum";
import type {
  SeqAction,
  SeqActionAny,
  SeqActionTerminated,
} from "@/types/seqAction";
import router from "@/router";

export interface QuizPageQueries {
  connectionName: string;
  seq: string;
  boardCards?: string;
  quizSetup: QuizSetup;
  cameFrom: string;
}

const validateBoardCardsFromQuery = (
  boardCards: string,
  validationParams: {
    gameType?: GameTypeEnum;
    seq?: string;
  }
): boolean => {
  const getCardList = (boardCardsStr: string): string[] | null => {
    return boardCardsStr.match(/.{1,2}/g);
  };
  const getRequiredAmountOfCards = (seqStr?: string): number => {
    if (!seqStr) {
      return 0;
    }
    const slashCount = (seqStr.match(/\//g) || []).length;
    return slashCount === 1
      ? 3
      : slashCount === 2
      ? 4
      : slashCount === 3
      ? 5
      : 0;
  };
  const areCardsValid = (
    cardListArr: string[],
    gameTypeVar?: GameTypeEnum
  ): boolean => {
    const cardSet = new Set();
    const cardRegex = /^[2-9TJQKA][dchs]$/;
    const shortDeckCardRegex = /^[6-9TJQKA][dchs]$/;
    const currentCardRegex =
      gameTypeVar === GameTypeEnum.shortdeck ? shortDeckCardRegex : cardRegex;

    for (const card of cardListArr) {
      if (!currentCardRegex.test(card) || cardSet.has(card)) {
        return false;
      }
      cardSet.add(card);
    }

    return true;
  };

  const cardList = getCardList(boardCards);
  if (!cardList || cardList.length === 0) {
    return false;
  }

  const requiredCards = getRequiredAmountOfCards(validationParams.seq);
  if (requiredCards > 0 && cardList.length < requiredCards) {
    return false;
  }

  const cardsAreValid = areCardsValid(cardList, validationParams.gameType);
  if (!cardsAreValid) {
    return false;
  }

  if (validationParams.seq === "open") {
    // Add any special handling for 'open' if required
    return true;
  }

  return true;
};

export const handlePreflopSettingsQuery = (connectionName: string) => {
  const gameStore = useGameStore();
  const seqStore = useSequenceStore();

  if (connectionName) {
    const gameStrategy = getStrategyFromConfig(
      connectionName,
      settingsFieldsPreflop
    );
    if (gameStrategy) {
      gameStore.setPreflopGameStrategy(gameStrategy);
    }
  } else {
    gameStore.setLastActivePreflopStrat();
    gameStore.updatePageQueries(seqStore.seqForRequest);
  }
};
export const handleStudySettingsQuery = (connectionName: string) => {
  const gameStore = useGameStore();
  const studySeqStore = useStudySequenceStore();

  if (connectionName) {
    const gameStrategy = getStrategyFromConfig(
      connectionName,
      settingsFieldsStudy
    );
    if (gameStrategy) {
      gameStore.setStudyGameStrategy(gameStrategy);
    }
  } else {
    gameStore.setLastActiveStudyStrat();
    gameStore.updatePageQueries(studySeqStore.seqForRequest);
  }
};

export const getSeqFromSeqStateReq = (
  inputArray: SeqStateObject[],
  options?: {
    isPreflop?: boolean;
    withSkippedAndEmptyActions?: boolean;
    boardCards?: string[];
  }
) => {
  const numOfPlayers = inputArray[0].stateInfo.folded.length;
  const seq: (SeqAction | SeqActionTerminated)[] = inputArray.map(
    (item, idx) => {
      const { sequence, actionsList, stateInfo, isDiscardAction } = item;

      const nextItemSequence = inputArray[idx + 1]?.sequence;
      const seqForAction = nextItemSequence
        ? nextItemSequence.match(/[br]\d*|f[/$]?|[ck][/$]?|d[0-5]/gm) ?? []
        : [];

      const chosenAction = seqForAction.length
        ? seqForAction[seqForAction.length - 1]
        : "";
      const actionType = isDiscardAction
        ? SeqActionTypeEnum.discardAction
        : SeqActionTypeEnum.action;
      const actionState = chosenAction
        ? SeqActionStateEnum.performed
        : SeqActionStateEnum.toAct;

      if (options?.isPreflop && inputArray[idx].sequence.endsWith("/")) {
        return getTerminatedAction(inputArray[idx].sequence, stateInfo);
      } else {
        return {
          type: actionType,
          state: actionState,
          uiState: SeqActionUiStateEnum.visible,
          sequence: sequence.length ? sequence : "open",
          chosenAction,
          actionsList,
          stateInfo,
        };
      }
    }
  );
  const seqWithSkippingAndPreActions = createSeqWithSkippedAndEmptyActions(
    seq as SeqAction[],
    numOfPlayers
  );

  if (!options?.boardCards && !options?.boardCards?.length) {
    return options?.withSkippedAndEmptyActions
      ? seqWithSkippingAndPreActions.flat()
      : seq;
  }

  const cards = options.boardCards;
  let slashCount = 0;
  const newSeq: SeqActionAny[] = options?.withSkippedAndEmptyActions
    ? seqWithSkippingAndPreActions.flat()
    : seq;

  for (let i = 0; i < newSeq.length; i++) {
    const actionItem = newSeq[i] as SeqAction;

    if (actionItem.chosenAction.includes("/")) {
      slashCount++;

      let cardsToAdd: string[] = [];
      if (slashCount === 1) {
        cardsToAdd = cards.slice(0, 3);
      } else if (slashCount === 2) {
        cardsToAdd = cards.slice(0, 4);
      } else if (slashCount === 3) {
        cardsToAdd = cards.slice(0, 5);
      }

      const newAction = getBoardCardsAction(cardsToAdd);

      newSeq.splice(i + 1, 0, newAction);
      i++;
    }
  }

  if (options?.withSkippedAndEmptyActions) {
    const lastAction = newSeq[newSeq.length - 1] as SeqAction;
    newSeq.push(
      ...getEmptyActions(
        getNumOfEmptyActionThatShouldBeAddedToSeq(
          lastAction.sequence,
          numOfPlayers
        )
      )
    );
  }

  return newSeq;
};

export const handlePreflopSeqQuery = async (
  seq: string,
  options: {
    upCards?: string;
    gameCategory: GameCategoryEnum;
  }
) => {
  const seqStore = useSequenceStore();
  const gameStore = useGameStore();
  if (isStudGameCategory(options.gameCategory)) {
    if (options.upCards) {
      const upCards = parseCardsFromString(options.upCards);
      if (seq && seq !== "open") {
        seqStore.setSeqStateFromQuery(seq, {
          upCards,
        });
      } else {
        await seqStore.initPreflopSeqStore({
          upCards,
        });
        gameStore.updatePageQueries(seqStore.seqForRequest, {
          upCards: seqStore.upCardsModule.cards,
        });
      }
    } else {
      gameStore.setIsSharingLinkInvalid(true);
    }
  } else if (seq) {
    seqStore.setSeqStateFromQuery(seq);
  } else {
    await seqStore.initPreflopSeqStore();
    gameStore.updatePageQueries(seqStore.seqForRequest);
  }
};

export const handleStudySeqQuery = async (
  seq: string,
  options: {
    boardCards?: string;
    gameCategory?: GameCategoryEnum;
    gameType?: GameTypeEnum;
    upCards?: string;
  }
) => {
  const studySeqStore = useStudySequenceStore();
  const gameStore = useGameStore();
  if (isStudGameCategory(options.gameCategory)) {
    if (options.upCards) {
      const upCards = parseCardsFromString(options.upCards);
      if (seq && seq !== "open") {
        studySeqStore.setSeqStateFromQuery(seq, {
          upCards,
        });
      } else {
        await studySeqStore.initStudySeqStore({
          upCards,
        });
        gameStore.updatePageQueries(studySeqStore.seqForRequest, {
          upCards: studySeqStore.upCardsModule.cards,
        });
      }
    } else {
      gameStore.setIsSharingLinkInvalid(true);
    }
  } else if (seq) {
    if (isDrawGameCategory(options.gameCategory)) {
      studySeqStore.setSeqStateFromQuery(seq);
    } else if (isFlopGameCategory(options.gameCategory) && seq.includes("/")) {
      if (
        options.boardCards &&
        validateBoardCardsFromQuery(options.boardCards, {
          gameType: options.gameType,
          seq,
        })
      ) {
        studySeqStore.setSeqStateFromQuery(seq, {
          boardCards: parseCardsFromString(options.boardCards),
        });
      } else {
        gameStore.setIsSharingLinkInvalid(true);
      }
    } else {
      studySeqStore.setSeqStateFromQuery(seq);
    }
  } else {
    await studySeqStore.initStudySeqStore();
    gameStore.updatePageQueries(studySeqStore.seqForRequest);
  }
};

export const initQuizSequence = async (): Promise<SeqActionAny[]> => {
  const res = await pokerApi.fetchActionListingJson("open");
  const startAction = getOpenAction(res.data.data);
  return [startAction];
};

export const initQuizSequenceFromQuery = async (
  seq: string,
  options?: {
    boardCards?: string;
  }
): Promise<SeqActionAny[] | void> => {
  try {
    const res = await pokerApi.fetchSeqStateHistory(seq);
    return getSeqFromSeqStateReq(res.data.data, {
      boardCards: parseCardsFromString(options?.boardCards),
    });
  } catch {
    const gameStore = useGameStore();

    gameStore.setIsSharingLinkInvalid(true);
    return undefined;
  }
};

export const handleQuizSeqQuery = async (
  pageQueries: QuizPageQueries,
  options: {
    gameType?: GameTypeEnum;
    gameCategory?: GameCategoryEnum;
  }
): Promise<SeqActionAny[] | void> => {
  const gameStore = useGameStore();

  if (pageQueries.seq) {
    if (isDrawGameCategory(options.gameCategory)) {
      return initQuizSequenceFromQuery(pageQueries.seq);
    } else if (
      isFlopGameCategory(options.gameCategory) &&
      pageQueries.seq.includes("/")
    ) {
      if (
        pageQueries.boardCards &&
        validateBoardCardsFromQuery(pageQueries.boardCards, {
          gameType: options.gameType,
          seq: pageQueries.seq,
        })
      ) {
        return initQuizSequenceFromQuery(pageQueries.seq, {
          boardCards: pageQueries.boardCards,
        });
      } else {
        gameStore.setIsSharingLinkInvalid(true);
        return undefined;
      }
    } else if (pageQueries.seq === "open") {
      return initQuizSequence();
    } else {
      return initQuizSequenceFromQuery(pageQueries.seq);
    }
  } else {
    gameStore.setIsSharingLinkInvalid(true);
    return undefined;
  }
};

export const parseQueryString = (
  queryString: string
): { [key: string]: string | undefined } => {
  const queryStart = queryString.indexOf("?");

  if (queryStart === -1 || queryStart === queryString.length - 1) {
    return {};
  }

  const query = queryString.slice(queryStart + 1);

  return query.split("&").reduce((acc, param) => {
    const [key, value] = param.split("=");

    if (key !== undefined && key !== "") {
      const decodedKey = decodeURIComponent(key);
      acc[decodedKey] = value ? decodeURIComponent(value) : undefined;
    }
    return acc;
  }, {} as { [key: string]: string | undefined });
};

export const isQuizSetupFromQueryValid = (quizSetup: {
  gameMode: string;
  boardMode: string;
}): boolean => {
  const gameStore = useGameStore();

  const { gameMode, boardMode } = quizSetup;

  const validGameModes = [GameMode.spot, GameMode.street, GameMode.fullHand];
  const validBoardModes = [BoardMode.random, BoardMode.current];

  if (
    validGameModes.includes(gameMode as GameMode) &&
    validBoardModes.includes(boardMode as BoardMode)
  ) {
    return true;
  } else {
    gameStore.setIsSharingLinkInvalid(true);
    return false;
  }
};

export const updateQuizPageQueries = (pageQueries: QuizPageQueries) => {
  router.replace({
    query: {
      connectionName: pageQueries.connectionName,
      seq: pageQueries.seq,
      boardCards: pageQueries.boardCards?.length
        ? pageQueries.boardCards
        : undefined,
      gameMode: pageQueries.quizSetup.gameMode,
      boardMode: pageQueries.quizSetup.boardMode,
      cameFrom: pageQueries.cameFrom,
    },
  });
};
