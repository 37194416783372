import type { RouteRecordRaw } from "vue-router";
import routesName from "@/constants/routesName";

const loadView = (path: string) => {
  return () => import(`@/views/${path}.vue`);
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/preflop",
  },
  {
    path: "/login",
    name: routesName.login,
    component: loadView("auth/LoginView"),
    meta: {
      isUnauthorizedUserPage: true,
    },
  },
  {
    path: "/registration",
    name: routesName.registration,
    component: loadView("auth/RegistrationView"),
    meta: {
      isUnauthorizedUserPage: true,
    },
  },
  {
    path: "/preflop",
    name: routesName.preflop,
    component: loadView("PreflopView"),
    meta: {
      isProtected: true,
    },
  },
  {
    path: "/game-setup",
    name: routesName.gameSetup,
    component: loadView("GameSetupView"),
    meta: {
      isProtected: true,
      withoutSidebar: true,
    },
  },
  {
    path: "/quiz",
    name: routesName.quiz,
    component: loadView("QuizView"),
    meta: {
      isProtected: true,
      isWithoutLayout: true,
    },
  },
  {
    path: "/study",
    name: routesName.study,
    component: loadView("StudyView"),
    meta: {
      isProtected: true,
    },
  },
  {
    path: "/reset-password",
    name: routesName.resetPassword,
    component: loadView("auth/ResetPasswordView"),
  },
  {
    path: "/forgot-password",
    name: routesName.forgotPassword,
    component: loadView("auth/ForgotPasswordView"),
    meta: {
      isUnauthorizedUserPage: true,
    },
  },
  {
    path: "/verify-email",
    name: routesName.verifyEmail,
    component: loadView("auth/VerifyEmailView"),
  },
  {
    path: "/auth/:authProvider/redirect",
    name: routesName.authRedirect,
    component: loadView("auth/AuthRedirectView"),
  },
  {
    path: "/settings",
    name: routesName.settings,
    component: loadView("settings/SettingsSidebarView"),
    meta: {
      isProtected: true,
    },
    redirect: { name: routesName.accountSettings },
    children: [
      {
        path: "/settings/account",
        name: routesName.accountSettings,
        component: loadView("settings/AccountInfoView"),
        meta: {
          isProtected: true,
        },
      },
      {
        path: "/settings/subscription",
        name: routesName.subscription,
        component: loadView("settings/SubscriptionView"),
        meta: {
          isProtected: true,
        },
      },
    ],
  },
];

export default routes;
