// for title using key values for translate
export const navigation = [
  {
    title: "preflop",
    link: "/preflop",
    slug: "preflop",
  },
  {
    title: "study",
    link: "/study",
    slug: "study",
  },
];
