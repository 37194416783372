import type { NodeData } from "./DecisionStrategy";
import { createMatrixData } from "./matrix/MatrixData";
import { parseQueryV2 } from "./ParseQueryV2";
import { UorgStreamReader } from "./UorgByteReader";
import { QueryWrapper } from "./QueryWrapper";
import type { ActionListing } from "./interfaces/action-listing.interface";

import type { MatrixTabData } from "./interfaces/matrix-tab-data.interface";
import type { AvailableFilterListing, FilterSet } from "./types/filter-types";
import type { MatrixCellAction } from "./types/matrix-cell-action.type";

/**
 * Parses v2 queries. QueryResponse object allows retrieval of range and matrix data,
 * and manipulation of matrix state. Also filters!
 *
 *  Response versions:
 * v2.0  Initial version
 * v2.1  Merge INFO, ACTIONS, and OPTIONS into a single PROP chunk.
 */

const MAX_SUPPORTED_QUERY_VERSION = 1;
const MIN_SUPPORTED_QUERY_VERSION = 0;

export interface QueryResponse {
  // List actions
  getActionListing(): ActionListing;

  // Summary tuple for current filters/selection
  getOverallTuple(): MatrixCellAction[];
  readonly percentRange: number;
  readonly percentReach: number;

  // Data!!
  getMatrixTabData(): MatrixTabData;
  getRangeTabData(): NodeData[];

  // Filters, and matrix clicks, which are just another sort of filter.
  handleMatrixClicks(id: number, index: number): void;
  applyFilter(filterSet: FilterSet): void;
  setComboSearch(search: string): boolean;
  resetFilters(): void;

  availableFilters(): AvailableFilterListing;
}

export async function parseQueryResponse(
  game: string,
  body: ReadableStream<Uint8Array>,
  progressCallback: (n: number) => void
): Promise<QueryResponse> {
  game = game.toLowerCase();

  let result: QueryWrapper | undefined;

  const reader = body.getReader();
  const uorgReader = new UorgStreamReader(reader);
  const header = await uorgReader.readLine();
  if (header.startsWith("ERROR")) {
    throw new Error(header);
  } else if (header.startsWith("QUERY,v")) {
    const versionTxt = header.split(",v")[1];
    const versionTokens = versionTxt.split(".");
    const majaorVersion = Number(versionTokens[0]);
    const minorVersion = Number(versionTokens[1]);

    if (majaorVersion === 2) {
      if (minorVersion > MAX_SUPPORTED_QUERY_VERSION) {
        throw new Error("Response version too high: " + versionTxt);
      }
      if (minorVersion < MIN_SUPPORTED_QUERY_VERSION) {
        throw new Error("Response version too low: " + versionTxt);
      }

      const [decisionStrategy, board] = await parseQueryV2(
        uorgReader,
        progressCallback
      );
      const matrixData = createMatrixData(game, board, decisionStrategy);
      result = new QueryWrapper(game, board, decisionStrategy, matrixData);
    } else {
      throw new Error("Only V2 support now!");
    }
  } else {
    throw new Error("Invalid response header: " + header);
  }

  return result;
}
