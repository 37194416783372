import { extractLastNumber } from "@/utils/colors";
import type { DrawColors, RaiseColors } from "@/stores/account";
import { useAccountStore } from "@/stores/account";
import { ScopeEnum } from "@/enums/scopeEnum";
import colors from "@/constants/colors";
import { PokerActionsEnum } from "@/poker-query-lib/enums/poker-actions.enum";
import { allInLabel } from "@/features/action/actionLabel";
import { getRaisePercentOfPot } from "@/features/action/actionValue";
import type { StateInfo } from "@/types/general";
import { isRaiseOrBetActionName } from "@/features/action/actionGeneral";

export enum RaiseSize {
  medium = "medium",
  large = "large",
  overbet = "overbet",
}

interface ActionColorsParams {
  fold?: string;
  call?: string;
  check?: string;
  raise?: RaiseColors;
  allIn?: string;
  draw?: Partial<DrawColors>;
}

const getRaiseColorBasedOnPercent = (
  raisePercent: string | number,
  actionColors?: ActionColorsParams
) => {
  const accountStore = useAccountStore();

  let pinkShade;

  if (typeof raisePercent === "string" && raisePercent.startsWith(allInLabel)) {
    pinkShade = actionColors?.allIn
      ? actionColors.allIn
      : accountStore.settings.actionColors.allIn;
  } else if (raisePercent <= 41) {
    // Small: under 40%
    pinkShade = actionColors?.raise?.small
      ? actionColors.raise.small
      : accountStore.settings.actionColors.raise.small;
  } else if (raisePercent <= 69) {
    // Medium: 41% - 69%
    pinkShade = actionColors?.raise?.medium
      ? actionColors.raise.medium
      : accountStore.settings.actionColors.raise.medium;
  } else if (raisePercent <= 100) {
    // Large: 70% - 100%
    pinkShade = actionColors?.raise?.large
      ? actionColors.raise.large
      : accountStore.settings.actionColors.raise.large;
  } else {
    // OVERbet: > 100%
    pinkShade = actionColors?.raise?.overbet
      ? actionColors.raise.overbet
      : accountStore.settings.actionColors.raise.overbet;
  }
  return pinkShade;
};
const getDrawColor = (
  drawNumber: number,
  actionColors?: ActionColorsParams
) => {
  const accountStore = useAccountStore();

  return actionColors?.draw?.[drawNumber as keyof DrawColors]
    ? (actionColors.draw[drawNumber as keyof DrawColors] as string)
    : accountStore.settings.actionColors.draw[drawNumber as keyof DrawColors];
};

export const getActionColor = (
  action: string,
  stateInfo: StateInfo,
  actionColors?: ActionColorsParams
): string => {
  const accountStore = useAccountStore();

  const calculateGradient = (actionStr: string) => {
    const raisePercent = getRaisePercentOfPot(actionStr, stateInfo);

    let pinkShade = actionColors?.raise?.small
      ? actionColors.raise.small
      : accountStore.settings.actionColors.raise.small;

    if (raisePercent) {
      pinkShade = getRaiseColorBasedOnPercent(raisePercent, actionColors);
    }

    return pinkShade;
  };
  const getColorBasedOnAction = (actionName: string) => {
    if (actionName === ScopeEnum.reach) {
      return colors.reach;
    }
    if (isRaiseOrBetActionName(actionName)) {
      return calculateGradient(actionName);
    }
    if (actionName.startsWith(PokerActionsEnum.FOLD)) {
      return actionColors?.fold
        ? actionColors.fold
        : accountStore.settings.actionColors.fold;
    }
    if (actionName.startsWith(PokerActionsEnum.CHECK)) {
      return actionColors?.check
        ? actionColors.check
        : accountStore.settings.actionColors.check;
    }
    if (actionName.startsWith(PokerActionsEnum.OMIT)) {
      return "transparent";
    }
    if (actionName.startsWith(PokerActionsEnum.DISCARD)) {
      const drawAmount = +actionName[1];
      return getDrawColor(drawAmount, actionColors);
    }

    return actionColors?.call
      ? actionColors.call
      : accountStore.settings.actionColors.call;
  };

  return getColorBasedOnAction(action);
};

export const generateRaiseColor = (
  rgbString: string,
  raiseSize: RaiseSize
): string => {
  const { r, g, b } = extractLastNumber(rgbString);

  const step = 50;
  const minChannelValue = 30; // Threshold to avoid reaching pure black
  const colorDifferenceThreshold = 30; // Minimum difference between large and overbet colors

  const getColorShade = (size: RaiseSize) => {
    switch (size.toLowerCase()) {
      case RaiseSize.medium:
        return step * 1.5;
      case RaiseSize.large:
        return step * 3;
      case RaiseSize.overbet:
        return step * 4.5;
      default:
        throw new Error("Invalid size parameter");
    }
  };

  const adjustGreyColor = (channelValue: number, colorShade: number) => {
    if (channelValue <= 128) {
      return Math.min(255, channelValue + colorShade);
    } else {
      return Math.max(0, channelValue - colorShade);
    }
  };

  const applyAdjustment = (channelValue: number, adjustment: number) => {
    return Math.max(minChannelValue, Math.min(255, channelValue - adjustment));
  };

  const isBlack = r === 0 && g === 0 && b === 0;
  const isWhite = r === 255 && g === 255 && b === 255;
  const isGrey = r === g && g === b;

  const handleBlackWhiteGrey = (colorShade: number) => {
    if (isBlack || isWhite) {
      const newShade = isBlack
        ? Math.min(255, colorShade)
        : Math.max(0, 255 - colorShade);
      return `rgb(${newShade}, ${newShade}, ${newShade})`;
    }

    if (isGrey) {
      const greyShade = adjustGreyColor(r, colorShade / 3);
      return `rgb(${greyShade}, ${greyShade}, ${greyShade})`;
    }

    return null;
  };

  const determineDominantChannel = (
    red: number,
    green: number,
    blue: number
  ) => {
    if (red >= green && red >= blue) {
      return "r";
    } else if (green >= red && green >= blue) {
      return "g";
    } else {
      return "b";
    }
  };

  const determineSecondAndThirdChannels = (
    dominant: string,
    red: number,
    green: number,
    blue: number
  ) => {
    if (dominant === "r") {
      return green >= blue ? ["g", "b"] : ["b", "g"];
    } else if (dominant === "g") {
      return red >= blue ? ["r", "b"] : ["b", "r"];
    } else {
      return red >= green ? ["r", "g"] : ["g", "r"];
    }
  };

  const handleOtherColors = (
    red: number,
    green: number,
    blue: number,
    colorShade: number
  ) => {
    const dominantChannel = determineDominantChannel(red, green, blue);
    const [secondCh, thirdCh] = determineSecondAndThirdChannels(
      dominantChannel,
      red,
      green,
      blue
    );

    let newRed =
      dominantChannel === "r" ? applyAdjustment(red, colorShade) : red;
    let newGreen =
      dominantChannel === "g" ? applyAdjustment(green, colorShade) : green;
    let newBlue =
      dominantChannel === "b" ? applyAdjustment(blue, colorShade) : blue;

    if (
      newRed <= minChannelValue ||
      newGreen <= minChannelValue ||
      newBlue <= minChannelValue
    ) {
      if (secondCh === "g") {
        newGreen = applyAdjustment(green, step);
      } else if (secondCh === "b") {
        newBlue = applyAdjustment(blue, step);
      } else if (secondCh === "r") {
        newRed = applyAdjustment(red, step);
      }
    }

    return {
      newR: newRed,
      newG: newGreen,
      newB: newBlue,
      secondChannel: secondCh,
      thirdChannel: thirdCh,
    };
  };

  const colorShadeVariable = getColorShade(raiseSize);
  const greyResult = handleBlackWhiteGrey(colorShadeVariable);

  if (greyResult) {
    return greyResult;
  }

  // eslint-disable-next-line prefer-const
  let { newR, newG, newB, secondChannel, thirdChannel } = handleOtherColors(
    r,
    g,
    b,
    colorShadeVariable
  );

  const handleOverbetColor = () => {
    const largeColor = generateRaiseColor(rgbString, RaiseSize.large);
    const { r: lr, g: lg, b: lb } = extractLastNumber(largeColor);

    const colorDifference =
      Math.abs(newR - lr) + Math.abs(newG - lg) + Math.abs(newB - lb);

    if (colorDifference < colorDifferenceThreshold) {
      if (secondChannel === "g") {
        newG = applyAdjustment(newG, step);
      } else if (secondChannel === "b") {
        newB = applyAdjustment(newB, step);
      } else if (secondChannel === "r") {
        newR = applyAdjustment(newR, step);
      }

      if (
        Math.abs(newR - lr) + Math.abs(newG - lg) + Math.abs(newB - lb) <
        colorDifferenceThreshold
      ) {
        if (thirdChannel === "g") {
          newG = applyAdjustment(newG, step);
        } else if (thirdChannel === "b") {
          newB = applyAdjustment(newB, step);
        } else if (thirdChannel === "r") {
          newR = applyAdjustment(newR, step);
        }
      }
    }
  };

  if (raiseSize === RaiseSize.overbet) {
    handleOverbetColor();
  }

  return `rgb(${newR}, ${newG}, ${newB})`;
};
