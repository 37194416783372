import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { ColorPicker } from "vue3-colorpicker";
import { computed, ref, watch } from "vue";
import AppButton from "@/components/common/app/AppButton.vue";
import type { ColorFormat } from "vue3-colorpicker/types/utils/color";

interface Props {
  format?: ColorFormat;
  withRaiseTooltip?: boolean;
  isRectangle?: boolean;
  color: string;
  actionLabel: string;
  actionKey: string;
}
interface Emits {
  (e: "select-color", actionName: string, color: string): void;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'ActionColorPicker',
  props: {
    format: {},
    withRaiseTooltip: { type: Boolean },
    isRectangle: { type: Boolean },
    color: {},
    actionLabel: {},
    actionKey: {}
  },
  emits: ["select-color"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

_useCssVars(_ctx => ({
  "3b84ad6f": (_ctx.color)
}))

const props = __props;

const emits = __emit;

const isShowTooltip = ref<boolean>(false);
const isShowPicker = ref<boolean>(false);
const selectedColor = ref<string>(props.color);
const isDisabledConfirm = computed(() => {
  return selectedColor.value === props.color;
});

const resetColor = () => {
  selectedColor.value = props.color;
};
const confirmSelectedColor = () => {
  emits("select-color", props.actionKey, selectedColor.value);
  toggleShowPicker();
};
const toggleShowPicker = () => {
  isShowPicker.value = !isShowPicker.value;
};
const showPicker = () => {
  // hack for avoid immediate close after showing
  setTimeout(() => (isShowPicker.value = true), 0);
};

watch(
  () => props.color,
  (newV) => {
    selectedColor.value = newV;
  }
);

const __returned__ = { props, emits, isShowTooltip, isShowPicker, selectedColor, isDisabledConfirm, resetColor, confirmSelectedColor, toggleShowPicker, showPicker, get ColorPicker() { return ColorPicker }, computed, ref, watch, AppButton }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})