import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/icons/light-gray-logo-icon.svg'


const _hoisted_1 = { class: "overlay" }
const _hoisted_2 = {
  key: 0,
  class: "overlay__content"
}
const _hoisted_3 = { class: "overlay__title" }
const _hoisted_4 = { class: "overlay__text" }
const _hoisted_5 = {
  key: 1,
  class: "overlay__content"
}
const _hoisted_6 = { class: "overlay__title" }
const _hoisted_7 = { class: "overlay__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$setup.gameStore.isServerResponding)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("img", {
            class: "overlay__icon",
            src: _imports_0,
            alt: "Vertex logo"
          }, null, -1)),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("tryLater")), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("fixesSorry")), 1)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode($setup["SpinnerComponent"]),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("apiUnavailable")), 1),
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("workingOnIt")), 1)
        ]))
  ]))
}