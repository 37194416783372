import { ref } from "vue";
import { defineStore } from "pinia";

enum CollapsedState {
  study = "study",
  preflop = "preflop",
}
type SeqHeaderCollapsedStateValue = boolean | number | undefined;

export const useLayoutStore = defineStore(
  "layout",
  () => {
    const isVisibleFocusModeToast = ref(false);
    const isActiveFocusMode = ref(false);
    const isPreflopAsideCollapsed = ref(false);
    const isPreflopSeqHeaderCollapsed = ref(false);
    const isQueryAreaEnabled = ref(true);
    const isStudyAsideCollapsed = ref(false);
    const isStudySeqHeaderCollapsed = ref(false);
    const isAppHeaderInAbsolutePos = ref(false);

    const isWidthLessThanMediumView = (width: number) => {
      return 1000 > width;
    };
    const changeFocusModeToastVisibility = (
      value: undefined | boolean = undefined
    ) => {
      if (value === undefined) {
        isVisibleFocusModeToast.value = !isVisibleFocusModeToast.value;
      } else {
        isVisibleFocusModeToast.value = value;
      }
    };
    const changeFocusModeState = (value: undefined | boolean = undefined) => {
      if (value === undefined) {
        isActiveFocusMode.value = !isActiveFocusMode.value;
      } else {
        isActiveFocusMode.value = value;
      }
      if (!isActiveFocusMode.value) {
        isAppHeaderInAbsolutePos.value = false;
      }
    };
    const changeAppHeaderInAbsolutePosState = (
      value: undefined | boolean = undefined
    ) => {
      if (value === undefined) {
        isAppHeaderInAbsolutePos.value = !isAppHeaderInAbsolutePos.value;
      } else {
        isAppHeaderInAbsolutePos.value = value;
      }
    };
    const changePreflopSeqHeaderCollapsedState = (
      value?: SeqHeaderCollapsedStateValue
    ) => {
      changeSeqHeaderCollapsedState(CollapsedState.preflop, value);
    };
    const changeStudySeqHeaderCollapsedState = (
      value?: SeqHeaderCollapsedStateValue
    ) => {
      changeSeqHeaderCollapsedState(CollapsedState.study, value);
    };
    const changePreflopAsideCollapsedState = () => {
      changeAsideCollapsedState(CollapsedState.preflop);
    };
    const changeStudyAsideCollapsedState = () => {
      changeAsideCollapsedState(CollapsedState.study);
    };
    const changeSeqHeaderCollapsedState = (
      type: CollapsedState,
      value?: SeqHeaderCollapsedStateValue
    ) => {
      const isSeqHeaderCollapsed =
        type === CollapsedState.preflop
          ? isPreflopSeqHeaderCollapsed
          : isStudySeqHeaderCollapsed;

      if (typeof value === "boolean") {
        isSeqHeaderCollapsed.value = value;
      } else if (typeof value === "number") {
        isSeqHeaderCollapsed.value = isWidthLessThanMediumView(value);
      } else {
        isSeqHeaderCollapsed.value = !isSeqHeaderCollapsed.value;
      }
    };
    const changeAsideCollapsedState = (
      type: CollapsedState,
      value = undefined
    ) => {
      const isAsideCollapsed =
        type === CollapsedState.preflop
          ? isPreflopAsideCollapsed
          : isStudyAsideCollapsed;

      if (typeof value === "boolean") {
        isAsideCollapsed.value = value;
      } else {
        isAsideCollapsed.value = !isAsideCollapsed.value;
      }
    };
    const setIsQueryAreaEnabled = (value: boolean) => {
      isQueryAreaEnabled.value = value;
    };

    return {
      changeAppHeaderInAbsolutePosState,
      changeFocusModeState,
      changeFocusModeToastVisibility,
      changePreflopAsideCollapsedState,
      changePreflopSeqHeaderCollapsedState,
      changeStudyAsideCollapsedState,
      changeStudySeqHeaderCollapsedState,
      isActiveFocusMode,
      isAppHeaderInAbsolutePos,
      isPreflopAsideCollapsed,
      isPreflopSeqHeaderCollapsed,
      isQueryAreaEnabled,
      isStudyAsideCollapsed,
      isStudySeqHeaderCollapsed,
      isVisibleFocusModeToast,
      isWidthLessThanMediumView,
      setIsQueryAreaEnabled,
    };
  },
  {
    persist: {
      paths: [
        "isActiveFocusMode",
        "isPreflopSeqHeaderCollapsed",
        "isPreflopAsideCollapsed",
        "isStudySeqHeaderCollapsed",
        "isStudyAsideCollapsed",
      ],
    },
    share: {
      enable: false,
    },
  }
);
