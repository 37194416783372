import type { GameConfig, GameScenario } from "@/types/gameSettings";

export const settingsFieldsPreflop: Array<keyof GameConfig> = [
  "ante",
  "numPlayers",
  "stack",
  "stake",
];

export const settingsFieldsStudy: Array<keyof GameConfig> = [
  "numPlayers",
  "stack",
];

export const omahaScenarios: GameScenario[] = [
  {
    name: "SRP",
    availableSequences: [
      {
        players: ["UTG", "HJ"],
        betSequence: "rcffff",
      },
      {
        players: ["UTG", "CO"],
        betSequence: "rfcfff",
      },
      {
        players: ["UTG", "BTN"],
        betSequence: "rffcff",
      },
      {
        players: ["UTG", "SB"],
        betSequence: "rfffcf",
      },
      {
        players: ["UTG", "BB"],
        betSequence: "rffffc",
      },
      {
        players: ["HJ", "CO"],
        betSequence: "frcfff",
      },
      {
        players: ["HJ", "BTN"],
        betSequence: "frfcff",
      },
      {
        players: ["HJ", "SB"],
        betSequence: "frffcf",
      },
      {
        players: ["HJ", "BB"],
        betSequence: "frfffc",
      },
      {
        players: ["CO", "BTN"],
        betSequence: "ffrcff",
      },
      {
        players: ["CO", "SB"],
        betSequence: "ffrfcf",
      },
      {
        players: ["CO", "BB"],
        betSequence: "ffrffc",
      },
      {
        players: ["BTN", "SB"],
        betSequence: "fffrcf",
      },
      {
        players: ["BTN", "BB"],
        betSequence: "fffrfc",
      },
      {
        players: ["SB", "BB"],
        betSequence: "ffffrc",
      },
    ],
  },
  {
    name: "3bet",
    availableSequences: [
      {
        players: ["UTG", "HJ"],
        betSequence: "rrffffc",
      },
      {
        players: ["UTG", "CO"],
        betSequence: "rfrfffc",
      },
      {
        players: ["UTG", "BTN"],
        betSequence: "rffrffc",
      },
      {
        players: ["UTG", "SB"],
        betSequence: "rfffrfc",
      },
      {
        players: ["UTG", "BB"],
        betSequence: "rffffrc",
      },
      {
        players: ["HJ", "CO"],
        betSequence: "frrfffc",
      },
      {
        players: ["HJ", "BTN"],
        betSequence: "frfrffc",
      },
      {
        players: ["HJ", "SB"],
        betSequence: "frffrfc",
      },
      {
        players: ["HJ", "BB"],
        betSequence: "frfffrc",
      },
      {
        players: ["CO", "BTN"],
        betSequence: "ffrrffc",
      },
      {
        players: ["CO", "SB"],
        betSequence: "ffrfrfc",
      },
      {
        players: ["CO", "BB"],
        betSequence: "ffrffrc",
      },
      {
        players: ["BTN", "SB"],
        betSequence: "fffrrfc",
      },
      {
        players: ["BTN", "BB"],
        betSequence: "fffrfrc",
      },
      {
        players: ["SB", "BB"],
        betSequence: "ffffrrc",
      },
    ],
  },
  {
    name: "4bet",
    availableSequences: [
      {
        players: ["UTG", "HJ"],
        betSequence: "rrffffrc",
      },
      {
        players: ["UTG", "CO"],
        betSequence: "rfrfffrc",
      },
      {
        players: ["UTG", "BTN"],
        betSequence: "rffrffrc",
      },
      {
        players: ["UTG", "SB"],
        betSequence: "rfffrfrc",
      },
      {
        players: ["UTG", "BB"],
        betSequence: "rffffrrc",
      },
      {
        players: ["HJ", "CO"],
        betSequence: "frrfffrc",
      },
      {
        players: ["HJ", "BTN"],
        betSequence: "frfrffrc",
      },
      {
        players: ["HJ", "SB"],
        betSequence: "frffrfrc",
      },
      {
        players: ["HJ", "BB"],
        betSequence: "frfffrrc",
      },
      {
        players: ["CO", "BTN"],
        betSequence: "ffrrffrc",
      },
      {
        players: ["CO", "SB"],
        betSequence: "ffrfrfrc",
      },
      {
        players: ["CO", "BB"],
        betSequence: "ffrffrrc",
      },
      {
        players: ["BTN", "SB"],
        betSequence: "fffrrfrc",
      },
      {
        players: ["BTN", "BB"],
        betSequence: "fffrfrrc",
      },
      {
        players: ["SB", "BB"],
        betSequence: "ffffrrrc",
      },
    ],
  },
  {
    name: "Limp",
    availableSequences: [
      {
        players: ["SB", "BB"],
        betSequence: "ffffck",
      },
    ],
  },
  {
    name: "Iso",
    availableSequences: [
      {
        players: ["SB", "BB"],
        betSequence: "ffffcrc",
      },
    ],
  },
];
