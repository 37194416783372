import { defineComponent as _defineComponent } from 'vue'
import { navigation } from "@/constants/navigation";
import { useRoute, useRouter } from "vue-router";
import { computed } from "vue";
import { useGameStore } from "@/stores/game";
import { findGameConnection } from "@/features/gameSettings";
import { useSequenceStore } from "@/stores/sequence";
import { useStudySequenceStore } from "@/stores/studySequence";
import routesName from "@/constants/routesName";


export default /*@__PURE__*/_defineComponent({
  __name: 'NavHeader',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();
const gameStore = useGameStore();
const seqStore = useSequenceStore();
const studySeqStore = useStudySequenceStore();

const isSetupScreen = (link: string) => {
  return route.query.redirect === link;
};
const getLinkForRedirect = (link: string) => {
  if (link === "/preflop") {
    return router.resolve({
      name: routesName.preflop,
      query: preflopSequenceQuery.value,
    });
  } else if (link === "/study") {
    return router.resolve({
      name: routesName.study,
      query: studySequenceQuery.value,
    });
  } else {
    return link;
  }
};
const preflopSequenceQuery = computed(() => {
  if (gameStore.lastActivePreflopStrategy) {
    return {
      connectionName: findGameConnection(
        gameStore.lastActivePreflopStrategy.selectedGameCategory,
        gameStore.lastActivePreflopStrategy.selectedGameSettings
      ),
      seq: seqStore.seqForRequest,
      upCards: seqStore.upCardsModule.cards.length
        ? seqStore.upCardsModule.cards.join(",")
        : undefined,
    };
  } else {
    return undefined;
  }
});
const studySequenceQuery = computed(() => {
  if (gameStore.lastActiveStudyStrategy) {
    return {
      connectionName: findGameConnection(
        gameStore.lastActiveStudyStrategy.selectedGameCategory,
        gameStore.lastActiveStudyStrategy.selectedGameSettings
      ),
      seq: studySeqStore.seqForRequest,
      boardCards: studySeqStore.boardCardsModule.cards.length
        ? studySeqStore.boardCardsModule.cards.join("")
        : undefined,
      upCards: studySeqStore.upCardsModule.cards.length
        ? studySeqStore.upCardsModule.cards.join(",")
        : undefined,
    };
  } else {
    return undefined;
  }
});

const __returned__ = { route, router, gameStore, seqStore, studySeqStore, isSetupScreen, getLinkForRedirect, preflopSequenceQuery, studySequenceQuery, get navigation() { return navigation }, get useRoute() { return useRoute }, get useRouter() { return useRouter }, computed, get useGameStore() { return useGameStore }, get findGameConnection() { return findGameConnection }, get useSequenceStore() { return useSequenceStore }, get useStudySequenceStore() { return useStudySequenceStore }, get routesName() { return routesName } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})