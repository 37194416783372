import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "lang" }
const _hoisted_2 = { class: "lang__wr" }
const _hoisted_3 = { class: "lang__list" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "settings-page__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.langList, (lang) => {
          return (_openBlock(), _createElementBlock("li", {
            class: _normalizeClass(["lang__list-item", { selected: lang.value === $setup.currentLang.value }]),
            key: lang.value,
            onClick: ($event: any) => ($setup.selectLang(lang))
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["lang__flag", lang.value])
            }, null, 2),
            _createTextVNode(_toDisplayString(lang.label), 1)
          ], 10, _hoisted_4))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode($setup["AppButton"], {
        class: "settings-page__save-btn",
        onOnClick: $setup.save,
        "is-disabled": !$setup.isChangedLanguage
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("saveChanges")), 1)
        ]),
        _: 1
      }, 8, ["is-disabled"])
    ])
  ]))
}