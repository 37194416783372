// Maps a combo (as a string) to a matrix cell (assuming 13x13)
export function comboCell(combo: string): number | undefined {
  const i1 = CARDS_TXT.indexOf(combo.substring(0, 2));
  const i2 = CARDS_TXT.indexOf(combo.substring(2, 4));

  if (i1 !== undefined && i2 !== undefined) {
    const r1 = 12 - Math.floor(i1 / 4); //Convert ranks high to low!
    const r2 = 12 - Math.floor(i2 / 4);
    const s1 = 3 - (i1 % 4);
    const s2 = 3 - (i2 % 4);
    const sameSuit = s1 === s2;
    const minR = Math.min(r1, r2);
    const maxR = Math.max(r1, r2);
    //NOTE these might be backwards or upside down or something weird.
    // but, close enough for now?
    const x = sameSuit ? minR : maxR;
    const y = sameSuit ? maxR : minR;
    return 13 * x + y;
  }
  return undefined;
}

// List of cards
export const ACPC_CARDS = [
  "2s",
  "2h",
  "2d",
  "2c",
  "3s",
  "3h",
  "3d",
  "3c",
  "4s",
  "4h",
  "4d",
  "4c",
  "5s",
  "5h",
  "5d",
  "5c",
  "6s",
  "6h",
  "6d",
  "6c",
  "7s",
  "7h",
  "7d",
  "7c",
  "8s",
  "8h",
  "8d",
  "8c",
  "9s",
  "9h",
  "9d",
  "9c",
  "Ts",
  "Th",
  "Td",
  "Tc",
  "Js",
  "Jh",
  "Jd",
  "Jc",
  "Qs",
  "Qh",
  "Qd",
  "Qc",
  "Ks",
  "Kh",
  "Kd",
  "Kc",
  "As",
  "Ah",
  "Ad",
  "Ac",
];

export const CARDS_TXT = [
  "2c",
  "2d",
  "2h",
  "2s",
  "3c",
  "3d",
  "3h",
  "3s",
  "4c",
  "4d",
  "4h",
  "4s",
  "5c",
  "5d",
  "5h",
  "5s",
  "6c",
  "6d",
  "6h",
  "6s",
  "7c",
  "7d",
  "7h",
  "7s",
  "8c",
  "8d",
  "8h",
  "8s",
  "9c",
  "9d",
  "9h",
  "9s",
  "Tc",
  "Td",
  "Th",
  "Ts",
  "Jc",
  "Jd",
  "Jh",
  "Js",
  "Qc",
  "Qd",
  "Qh",
  "Qs",
  "Kc",
  "Kd",
  "Kh",
  "Ks",
  "Ac",
  "Ad",
  "Ah",
  "As",
];

export const RANK_TXT = [
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "T",
  "J",
  "Q",
  "K",
  "A",
];
export const SUIT_TXT = ["c", "d", "h", "s"];

export enum Rank {
  Two,
  Three,
  Four,
  Five,
  Six,
  Seven,
  Eight,
  Nine,
  Ten,
  Jack,
  Queen,
  King,
  Ace,
}

export enum Suit {
  Clubs,
  Diamonds,
  Hearts,
  Spades,
}

export const Ranks = [
  Rank.Two,
  Rank.Three,
  Rank.Four,
  Rank.Five,
  Rank.Six,
  Rank.Seven,
  Rank.Eight,
  Rank.Nine,
  Rank.Ten,
  Rank.Jack,
  Rank.Queen,
  Rank.King,
  Rank.Ace,
];

export const RanksAceHigh = [
  Rank.Ace,
  Rank.King,
  Rank.Queen,
  Rank.Jack,
  Rank.Ten,
  Rank.Nine,
  Rank.Eight,
  Rank.Seven,
  Rank.Six,
  Rank.Five,
  Rank.Four,
  Rank.Three,
  Rank.Two,
];

export const RankChar = [
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "T",
  "J",
  "Q",
  "K",
  "A",
];

export const Suits = [Suit.Clubs, Suit.Diamonds, Suit.Hearts, Suit.Spades];
export const SuitsSpadesToClubs = [
  Suit.Spades,
  Suit.Hearts,
  Suit.Diamonds,
  Suit.Clubs,
];

export const SuitChar = ["c", "d", "h", "s"];
export const SuitIcons: string[] = ["♣", "♦", "♥", "♠"];

// ACPC order
// export const SuitChar = ["s", "h", "d", "c"]
// export const SuitIcons: string[] = ["♠", "♥", "♦", "♣"];

export class Card {
  static count = 52;

  ordinal: number;
  rank: Rank;
  suit: Suit;

  private constructor(ordinal: number) {
    this.ordinal = ordinal;
    this.rank = Ranks[Math.floor(this.ordinal / 4)];
    this.suit = Suits[this.ordinal % 4];
  }

  toString(): string {
    return CARDS_TXT[this.ordinal];
  }

  static generateCards(): Card[] {
    return Array.from({ length: 52 }, (_, i) => new Card(i));
  }

  static parse(txt: string) {
    const rank = RANK_TXT.indexOf(txt[0]);
    const suit = SUIT_TXT.indexOf(txt[1]);
    return Cards[4 * rank + suit];
  }

  static get(rank: Rank, suit: Suit) {
    return Cards[4 * rank + suit];
  }

  static sort(cards: Card[]) {
    cards.sort((a, b) => a.ordinal - b.ordinal);
  }

  static parseArray(cardsTxt: string): Card[] {
    const result = [];
    for (let i = 0; i + 1 < cardsTxt.length; i += 2) {
      result.push(Card.parse(cardsTxt.substring(i, i + 2)));
    }
    return result;
  }
}

function generateShortdeckList(): Card[] {
  const result: Card[] = [];
  for (let rank = Rank.Six; rank <= Rank.Ace; rank++) {
    // Suits are in ACPC order!
    for (const suit of SuitsSpadesToClubs) {
      result.push(Card.get(rank, suit));
    }
  }
  return result;
}

export const Cards = Card.generateCards();
export const AcpcCards = ACPC_CARDS.map(Card.parse);
export const AcpcCardsShortdeck = generateShortdeckList();

export function toIsomorphicString(cards: Card[]): string {
  const suitMasks = [0, 0, 0, 0];
  for (const c of cards) {
    suitMasks[c.suit] |= 1 << c.rank;
  }
  suitMasks.sort((a, b) => b - a);

  let result = "";
  for (const mask of suitMasks) {
    let count = 0;
    let suitTxt = "";
    for (const r of RanksAceHigh) {
      if (mask & (1 << r)) {
        count++;
        suitTxt += RankChar[r];
      }
    }

    if (count >= 2) {
      result += "(";
    }
    result += suitTxt;
    if (count >= 2) {
      result += ")";
    }
  }

  return result;
}

// Two card holdem hand
export class Pair {
  static count = 1326;

  ordinal: number;
  cards: Card[];

  private constructor(cards: Card[], ordinal: number) {
    this.cards = cards;
    this.ordinal = ordinal;
  }

  intersects(cards: Card[]): boolean {
    return combosIntersect(this.cards, cards);
  }

  toString(): string {
    return this.cards.map((x) => x.toString()).join("");
  }

  static parse(txt: string): Pair {
    const cards = Card.parseArray(txt);
    return this.get(cards[0], cards[1]);
  }

  static get(card1: Card, card2: Card): Pair {
    return PairsByCard[card1.ordinal][card2.ordinal];
  }

  static generate(): [Pair[], Pair[][]] {
    const values: Pair[] = Array(1326);
    const valuesByCard: Pair[][] = Array(52);
    for (let i = 0; i < 52; i++) {
      valuesByCard[i] = Array(52);
    }

    let ordinal = 0;
    for (let i = 0; i < Card.count - 1; i++) {
      for (let j = i + 1; j < Card.count; j++) {
        const pair = new Pair([Cards[j], Cards[i]], ordinal);
        values[ordinal] = pair;
        valuesByCard[i][j] = pair;
        valuesByCard[j][i] = pair;
        ordinal++;
      }
    }
    return [values, valuesByCard];
  }
}

export const [Pairs, PairsByCard] = Pair.generate();

// this is only used below, just calculate matrix cell directly then!
// Assumes rank(a) >= rank(b)
export function getCanonPairOrdinal(a: Card, b: Card): number {
  const r0 = a.rank;
  const r1 = b.rank;
  const suited = a.suit === b.suit;

  //r0 is the higher rank!
  if (suited) {
    return r0 * 13 + r1;
  }

  return r1 * 13 + r0;
}

// Assumes rank(a) >= rank(b)
export function getMatrixCell(a: Card, b: Card): number {
  return 168 - getCanonPairOrdinal(a, b);
}

//----------------------------------------------------
// Canonical pair! (PreflopPair in Java)
export class CanonPair {
  static count = 169;

  suited: boolean;
  ranks: Rank[];
  ordinal: number;
  pairs: Pair[] = [];

  constructor(ranks: Rank[], suited: boolean, ordinal: number) {
    this.ranks = ranks;
    this.suited = suited;
    this.ordinal = ordinal;

    if (ranks[0] === ranks[1]) {
      for (let a = 0; a < 4; a++) {
        for (let b = a + 1; b < 4; b++) {
          this.pairs.push(
            Pair.get(Card.get(ranks[0], a), Card.get(ranks[1], b))
          );
        }
      }
    } else if (suited) {
      for (const s of Suits) {
        this.pairs.push(Pair.get(Card.get(ranks[0], s), Card.get(ranks[1], s)));
      }
    } else {
      for (let a = 0; a < 4; a++) {
        for (let b = 0; b < 4; b++) {
          this.addPairIfDifferent(a, b, ranks);
        }
      }
    }
  }

  private addPairIfDifferent(a: number, b: number, ranks: number[]): void {
    if (a !== b) {
      this.pairs.push(Pair.get(Card.get(ranks[0], a), Card.get(ranks[1], b)));
    }
  }

  static get(pair: Pair) {
    const r0 = pair.cards[0].rank;
    const r1 = pair.cards[1].rank;
    const suited = pair.cards[0].suit === pair.cards[1].suit;

    //r0 is the higher rank!
    if (suited) {
      return CanonPairs[r0 * 13 + r1];
    }

    return CanonPairs[r1 * 13 + r0];
  }

  toString(): string {
    let result = RankChar[this.ranks[0]] + RankChar[this.ranks[1]];
    if (this.ranks[0] !== this.ranks[1]) {
      result += this.suited ? "s" : "o";
    }
    return result;
  }

  static generate(): CanonPair[] {
    const result: CanonPair[] = Array(CanonPair.count);
    let ordinal = 0;
    for (let i = 0; i < Ranks.length; i++) {
      for (let j = 0; j < Ranks.length; j++) {
        if (i === j) {
          result[ordinal] = new CanonPair([Ranks[i], Ranks[j]], false, ordinal);
        } else if (i < j) {
          result[ordinal] = new CanonPair([Ranks[j], Ranks[i]], false, ordinal);
        } else {
          //i > j
          result[ordinal] = new CanonPair([Ranks[i], Ranks[j]], true, ordinal);
        }
        ordinal++;
      }
    }
    return result;
  }
}

export const CanonPairs = CanonPair.generate();

//Map CanonPair to cell number:
export function getMatrixCellForCanonPair(cp: CanonPair): number {
  if (cp.suited) {
    return 13 * (12 - cp.ranks[0]) + (12 - cp.ranks[1]);
  } else {
    return 13 * (12 - cp.ranks[1]) + (12 - cp.ranks[0]);
  }
}

export function getMatrixCellForCanonPairShortdeck(cp: CanonPair): number {
  if (cp.suited) {
    return 9 * (12 - cp.ranks[0]) + (12 - cp.ranks[1]);
  } else {
    return 9 * (12 - cp.ranks[1]) + (12 - cp.ranks[0]);
  }
}

// Assumes rank(a) >= rank(b)
export function getMatrixCellFromSortedCards(a: Card, b: Card): number {
  if (a.suit === b.suit) {
    return 13 * (12 - a.rank) + (12 - b.rank);
  } else {
    return 13 * (12 - b.rank) + (12 - a.rank);
  }
}

// Doesn't work for shortdeck, but only used for OMAHA I think.
export function getCanonPairForMatrixCell(i: number): CanonPair {
  return CanonPairs[168 - i];
}

//
//  Shortdeck Pairs
//
function generateShortdeckPairs(): Pair[] {
  const result = [];
  for (let i = 0; i < AcpcCards.length; i++) {
    for (let j = i + 1; j < AcpcCards.length; j++) {
      const pair = Pair.get(AcpcCards[i], AcpcCards[j]);
      result.push(pair);
    }
  }
  return result;
}

export const ShortdeckPairs = generateShortdeckPairs();

//
//
// Functions for determining and working with combo ordinals
//
//

function binomialCoefficient(n: number, k: number): number {
  if (k > n - k) {
    k = n - k;
  }

  let result = 1;
  for (let i = 0; i < k; i++) {
    result *= n - i;
    result /= i + 1;
  }

  return result;
}

export function getColexIndex(cards: Card[]): number {
  Card.sort(cards);
  let index = 0;
  for (let i = 0; i < cards.length; i++) {
    const n = cards[i].ordinal;
    const k = i + 1;
    if (n >= k) {
      index += binomialCoefficient(n, k);
    }
  }
  return index;
}

// Generates a table of binomial coefficients so we don't have to compute
// them everytime.   ~2.5kb.
// NOTE that we set C(n, k) = 0 for k > n, rather than 1.
// NOTE could maybe make this smaller by only storing up k's up to n/2,
//   since C(n, k) = C(n, n-k), and also not storing C(n, 1) = n.
//   This introduces some additional branching though, so benchmark things first.
//    And ~2.5kb is way better than 800mb!
function genBinomialCoefficients(): number[][] {
  const result = [];
  for (let k = 0; k < 6; k++) {
    const array = [];
    for (let c = 0; c < 52; c++) {
      if (k + 1 > c) {
        array.push(0);
      } else {
        array.push(binomialCoefficient(c, k + 1));
      }
    }
    result.push(array);
  }
  return result;
}

// Fast lookup of binomial coefficients.
const BINOMIAL_COEFFICIENTS = genBinomialCoefficients();
export function binomialCoefficientFAST(n: number, k: number): number {
  return BINOMIAL_COEFFICIENTS[k - 1][n];
}

/*
 *  Faster version of getColexIndex where we assume that cards are already
 *  sorted, and we use the lookup table version for the binomial coefficients.
 *  MAKE SURE WE CALL THIS WITH CARDS ALREADY SORTED THE RIGHT WAY!!!
 *
 *  getColexIndex assigns ordinals to groups of cards. We use colex order, so
 *  if we had triples of cards, we'd assign ordinals like:
 *
 *    card ordinals -> colex index (combo ordinal)
 *     0, 1, 2 ->  0
 *     0, 1, 3 ->  1
 *     0, 2, 3 ->  2
 *     1, 2, 3 ->  3
 *     0, 1, 4 ->  4
 *     0, 2, 4 ->  5
 *     1, 2, 4 ->  6
 *     0, 3, 4 ->  7
 *       ...
 *     2, 3, 6 ->  25
 *
 *  How do we compute this index quickly given the card ordinals?
 *
 *  Taking (2, 3, 6) for example, how many entries come before it in the list?
 *  For an entry to occur earlier, we can break it down into mutually exclusive cases
 *  based on how many of the card ordinals match. i.e.
 *
 *  (x, y, z)  where x < y < z < 6,
 *  (x, y, 6)  where x < y < 3,
 *  (x, 3, 6)  where x < 2,
 *
 *  For the first case, this is just C(6, 3), since (x<y<z) can be any triple ordinals from 0 to 5.
 *  For the next, it's C(3, 2). then C(2, 1).
 *
 *  In general for a triple, (a_0, a_1, a_2) => we sum
 *  C(a_2, 3) + C(a_1, 2) + C(a_0, 1) = Sum C(a_i, i).
 *
 *  Let's consider an edge cases, like 0,1,3 -> 1
 *  We would sum C(3,3) + C(1,2) + C(0,1)
 *
 *  There are NO cases where we make the 1 or 0 smaller to find earlier entries, so we want
 *  count 0 entries that come earlier. This is why we take C(n, k) = 0 for k > n.
 */
export function getColexIndexFromU8Indices(cardOrdinals: Uint8Array): number {
  const n = cardOrdinals.length;
  let index = 0;
  for (let i = 0; i < n; i++) {
    // colex index calculations are based on LOW to HIGH order.
    // Here we assume that cards are stored from HIGH to LOW,
    // so access indices in reverse order.
    // NOTE that the older getColexIndex() calls sort and puts
    // things in LOW to HIGH order,
    //
    // If we add FANCIER orderings, then the "base" ordering can be low to hi again,
    // and then we won't need to index these backwards.
    index += binomialCoefficientFAST(cardOrdinals[n - 1 - i], i + 1);
  }
  return index;
}

/**
 * Given an ordinal, figures out what cards are in the combo. This is the inverse
 *  of getColexIndex.
 * NOTE! Assumes that BINOMIAL_COEFFICIENTS will have C(n, k) = 0 for k > n.
 * NOTE! BINOMIAL_COEFFICIENTS stores Choose(n, k) at index [k-1][n].
 */
export function getU8ComboFromOrdinal(
  ordinal: number,
  numCards: number
): Uint8Array {
  const result = new Uint8Array(numCards);
  let offset = ordinal;
  for (let i = numCards - 1; i >= 0; i--) {
    for (let n = 51; n >= i; n--) {
      const step = BINOMIAL_COEFFICIENTS[i][n]; //C(n, i+1)
      if (step <= offset) {
        // Reverse order so we get cards high to lo
        result[numCards - 1 - i] = n;
        offset -= step;
        break;
      }
    }
  }
  return result;
}

export function getCardsFromOrdinal(ordinal: number, numCards: number): Card[] {
  return getCardArray(getU8ComboFromOrdinal(ordinal, numCards));
}

export type Combo = Pair | Uint8Array;

export function getOrdinal(combo: Combo): number {
  if (combo instanceof Pair) {
    return combo.ordinal;
  }
  // U8 array
  return getColexIndexFromU8Indices(combo);
}

export function getCardArray(combo: Combo): Card[] {
  if (combo instanceof Pair) {
    return combo.cards;
  }
  if (combo instanceof Uint8Array) {
    // U8 array
    const result: Card[] = [];
    for (const i of combo) {
      result.push(Cards[i]);
    }
    return result;
  }
  return [];
}

export function getCardTxt(combo: Combo): string {
  return getCardArray(combo).join("");
}

export function combosIntersect(A: Card[], B: Card[]): boolean {
  for (const a of A) {
    if (B.indexOf(a) >= 0) {
      return true;
    }
  }
  return false;
}

export class CardMask {
  mask: boolean[];

  constructor(cards: Card[]) {
    this.mask = Array.from({ length: 52 }).fill(false) as boolean[];
    for (const c of cards) {
      this.mask[c.ordinal] = true;
    }
  }

  intersect(cards: Card[]): boolean {
    return cards.some((c) => this.mask[c.ordinal]);
  }
}

///// New smaller Quint lookup tables! ////////
// PERF: Can maybe be faster by just converting these tables into static lookup
//  tables rather than recomputing everytime!! Look into it!
//
// canonOrdinal is a list of quint ordinals, one for each CANONICAL quint!
// canonIsoCount[i] is HOW MANY hands are isomorphic to the hand with ordinal canonOrdinal[i].
//
// These are used to figure out non-reaching hands in canonical queries. We can iterate over
//  each canonical hand in 'canonOrdinal', see if it's been processed, and if it hasn't, we
//  can add 'canonIsoCount' number of nonReaching hands at that combo.
//
// We also need to be able to figure out how many CANONICAL combos match the hand given
// For now, we need this extra array:
//
//  fullIsoCount[i] is HOW MANY hands are isomorphic to the hand with ordinal i.
//
// PERF: We could eliminate the need for this one if the server returned an INDEX into
// canonOrdinal / canonIsoCount rather than the hand itself. This would save ~10MB.
//
type CanonicalLookupTables = {
  canonOrdinal: Uint32Array;
  canonIsoCount: Uint8Array;
  fullIsoCount: Uint8Array; //Can eliminate if server sends different format for CANON QUINTS!
};

function generateCanonLookupTables(
  numCards: number,
  comboCount: number,
  canonCount: number
): CanonicalLookupTables {
  const canonOrdinal = new Uint32Array(canonCount);
  const canonIsoCount = new Uint8Array(canonCount);
  const fullIsoCount = new Uint8Array(comboCount);

  // For each quint, figure out what isomorphic group it belongs to.
  const map = new Map<string, number[]>();
  for (let i = 0; i < NUM_QUINTS; i++) {
    const iso = toIsomorphicString(getCardsFromOrdinal(i, numCards));
    if (map.has(iso)) {
      map.get(iso)?.push(i);
    } else {
      map.set(iso, [i]);
    }
  }

  let canonIndex = 0;
  for (const group of map.values()) {
    canonOrdinal[canonIndex] = group[0];
    canonIsoCount[canonIndex] = group.length;

    for (const ord of group) {
      fullIsoCount[ord] = group.length;
    }
    canonIndex++;
  }

  return {
    canonOrdinal,
    canonIsoCount,
    fullIsoCount,
  };
}

/**
 * Returns lookup quint tables, and caches them for later.
 */
export const NUM_QUINTS = 2598960;
export const NUM_CANON_QUINTS = 134459;
let CANONICAL_QUINT_LOOKUP_TABLES: CanonicalLookupTables | null = null;

export function getCanonQuintLookupTables(): CanonicalLookupTables {
  const lookupTables =
    CANONICAL_QUINT_LOOKUP_TABLES ||
    generateCanonLookupTables(5, NUM_QUINTS, NUM_CANON_QUINTS);
  if (!CANONICAL_QUINT_LOOKUP_TABLES) {
    CANONICAL_QUINT_LOOKUP_TABLES = lookupTables;
  }
  return lookupTables;
}

///// New smaller Quad lookup tables! //////////
export const NUM_QUADS = 270725;
export const NUM_CANON_QUADS = 16432;

let CANONICAL_QUAD_LOOKUP_TABLES: CanonicalLookupTables | null = null;
export function getCanonQuadLookupTables(): CanonicalLookupTables {
  const lookupTables =
    CANONICAL_QUAD_LOOKUP_TABLES ||
    generateCanonLookupTables(4, NUM_QUADS, NUM_CANON_QUADS);
  if (!CANONICAL_QUAD_LOOKUP_TABLES) {
    CANONICAL_QUAD_LOOKUP_TABLES = lookupTables;
  }
  return lookupTables;
}

// Get lookup tables for a game given card count.
export function getCanonicalComboLookupTables(
  n: number
): CanonicalLookupTables {
  if (n === 4) {
    return getCanonQuadLookupTables();
  } else if (n === 5) {
    return getCanonQuintLookupTables();
  } else {
    //Should be unreachable!
    throw new Error("No canonical combo lookup tables for size " + n);
  }
}

export function numCardsForGame(game: string): number {
  if (
    game?.startsWith("five") ||
    game?.startsWith("5omaha") ||
    game?.startsWith("draw")
  ) {
    return 5;
  } else if (game?.startsWith("omaha") || game?.startsWith("badugi")) {
    return 4;
  }
  return 2;
}

export function numCanonFromCardCount(numHolecards: number): number {
  if (numHolecards === 5) {
    return NUM_CANON_QUINTS;
  } else if (numHolecards === 4) {
    return NUM_CANON_QUADS;
  }
  return CanonPairs.length;
}

export function numCombosFromCardCount(numHolecards: number): number {
  if (numHolecards === 5) {
    return NUM_QUINTS;
  } else if (numHolecards === 4) {
    return NUM_QUADS;
  }
  return Pairs.length;
}
