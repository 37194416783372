import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "header__title" }
const _hoisted_4 = { class: "header__switcher-option" }
const _hoisted_5 = { class: "header__switcher-option" }
const _hoisted_6 = {
  key: 0,
  class: "cards-designs"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 1,
  class: "cards-designs"
}
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("cardsDesign")), 1),
      _createElementVNode("div", {
        class: "header__switcher",
        onClick: $setup.toggleCardsDesigns
      }, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("colored")), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("white")), 1),
        _createElementVNode("div", {
          class: _normalizeClass(["header__switcher-control", { left: $setup.whichCardsDesignsSelected === 'colored', right: $setup.whichCardsDesignsSelected === 'white' }])
        }, null, 2)
      ])
    ]),
    ($setup.whichCardsDesignsSelected === 'colored')
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, (num) => {
            return _createElementVNode("div", {
              class: _normalizeClass(["selectable-wrapper", { selected: $props.selectedCardsDesign === `variant${num}` }]),
              key: num,
              onClick: ($event: any) => ($setup.setSelectedCardsDesign(`variant${num}`))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["diamonds preview", [`variant${num}`]])
              }, "A", 2)
            ], 10, _hoisted_7)
          }), 64))
        ]))
      : _createCommentVNode("", true),
    ($setup.whichCardsDesignsSelected === 'white')
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(6, (num) => {
            return _createElementVNode("div", {
              class: _normalizeClass(["selectable-wrapper", { selected: $props.selectedCardsDesign === `variant${num + 6}` }]),
              key: num,
              onClick: ($event: any) => ($setup.setSelectedCardsDesign(`variant${num + 6}`))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["diamonds preview", [`variant${num + 6}`]])
              }, "A", 2)
            ], 10, _hoisted_9)
          }), 64))
        ]))
      : _createCommentVNode("", true)
  ]))
}