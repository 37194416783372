import type { Card } from "../Combos";
import { CanonPair, Pair } from "../Combos";
import { NodeAvgs, NodeGroup } from "../DecisionStrategy";
import StrategyBuckets from "../StrategyBuckets";
import type { Matrix } from "../interfaces/matrix.interface";
import type { PokerBettingAction } from "../types/poker-betting-action.type";
import type { MatrixData } from "./MatrixData";
import {
  CellRenderData,
  NONREACHING_PROBS,
  SingleMatrixData,
} from "./MatrixData";

export class SingleMatrixPlusSuitsData implements MatrixData {
  actionList: PokerBettingAction[];
  leftSelection: number | undefined; //Java does this with a PreflopPair. Let's just use a cell index for now???
  rightSelection: number | undefined; // ditto.

  leftStratFull = new StrategyBuckets(169);
  rightStratFull = new StrategyBuckets(16);

  leftStrat: StrategyBuckets | undefined;
  rightStrat: StrategyBuckets | undefined;

  leftRenderData: SingleMatrixData;
  rightRenderData: SingleMatrixData;

  hoverData: NodeGroup | undefined;
  totalSummary: NodeAvgs = new NodeAvgs();

  boardCards: Card[] = [];
  strat: NodeGroup | undefined;
  selectedStrat: NodeGroup | undefined;

  numRows: number;
  cellForPair: (pair: CanonPair) => number;

  constructor(
    actionList: PokerBettingAction[],
    rows: number,
    cellForPairFn: (pair: CanonPair) => number
  ) {
    this.numRows = rows;
    this.cellForPair = cellForPairFn;
    this.actionList = actionList;
    this.leftRenderData = new SingleMatrixData(rows, rows);
    this.rightRenderData = new SingleMatrixData(4, 4);
  }

  getActionListing(): string[] | undefined {
    return this.actionList;
  }

  // board: Card[] is to match MatrixData interface.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setStrategy(strategy: NodeGroup, board: Card[]) {
    this.totalSummary.clear();
    this.strat = strategy;
    this.leftRenderData = new SingleMatrixData(this.numRows, this.numRows);
    this.rightRenderData = new SingleMatrixData(4, 4);

    this.leftStratFull = new StrategyBuckets(this.numRows * this.numRows);
    this.rightStratFull = new StrategyBuckets(16);

    for (const node of strategy.nodeList) {
      this.totalSummary.addNode(node);
      const pair = node.holecards;
      if (pair instanceof Pair) {
        const canPair = CanonPair.get(pair);
        this.leftStratFull.add(this.cellForPair(canPair), node);
        this.rightStratFull.add(this.getSuitMatrixCell(pair), node);
      }
    }
    for (const pair of strategy.nonReaching) {
      this.totalSummary.addNonReaching();
      if (pair instanceof Pair) {
        const canPair = CanonPair.get(pair);
        const cell = this.cellForPair(canPair);
        this.leftStratFull.addNonReaching(cell, pair);
        this.rightStratFull.addNonReaching(cell, pair);
      }
    }
    this.leftStrat = this.leftStratFull;
    this.rightStrat = this.rightStratFull;

    //update renderdata!
    this.updateRenderData();
  }

  getSelectedStrat(): NodeGroup {
    return this.selectedStrat || new NodeGroup();
  }

  getSuitMatrixCell(pair: Pair): number {
    const s1 = 3 - pair.cards[0].suit;
    const s2 = 3 - pair.cards[1].suit;
    return 4 * s1 + s2;
  }

  updateStrategy() {
    // If we have a selection, fill out the right hand matrix
    if (this.leftSelection !== undefined) {
      const right = new StrategyBuckets(16);
      const bucket = this.leftStratFull.data[this.leftSelection];
      if (bucket) {
        bucket.nodeList.forEach((node) => {
          const pair = node.holecards;
          if (pair instanceof Pair) {
            const cell = this.getSuitMatrixCell(pair);
            right.add(cell, node);
          }
        });
      }
      this.rightStrat = right;
    } else {
      this.rightStrat = this.rightStratFull;
    }

    // If we have a suit selection, fill out the left hand matrix
    if (this.rightSelection !== undefined) {
      const left = new StrategyBuckets(169);
      const bucket = this.rightStratFull.data[this.rightSelection];
      if (bucket) {
        bucket.nodeList.forEach((node) => {
          const pair = node.holecards;
          if (pair instanceof Pair) {
            const cell = this.cellForPair(CanonPair.get(pair));
            left.add(cell, node);
          }
        });
      }
      this.leftStrat = left;
    } else {
      this.leftStrat = this.leftStratFull;
    }

    this.updateRenderData();
  }

  updateRenderData() {
    this.updateSummaryInfo();
    this.updateLeftRenderData();
    this.updateRightRenderData();
  }

  updateLeftRenderData() {
    if (this.leftStrat) {
      for (let i = 0; i < 169; i++) {
        this.updateLeftRenderDataForBucket(i);
      }
      this.leftRenderData.selected = this.leftSelection;
    }
  }

  updateLeftRenderDataForBucket(index: number) {
    const bucket = this.leftStrat?.data[index];
    this.leftRenderData.cells[index] = NONREACHING_PROBS;
    if (bucket) {
      const { probs, ev } = bucket.getAvg();
      if (probs) {
        const cellData = new CellRenderData(
          this.actionList,
          probs,
          bucket.getAvg().reach,
          ev ?? 0,
          index === this.leftSelection
        );
        this.leftRenderData.cells[index] = cellData;
      }
    }
  }

  updateRightRenderData() {
    if (this.rightStrat) {
      for (let i = 0; i < 16; i++) {
        this.updateRightRenderDataForBucket(i);
      }
      this.rightRenderData.selected = this.rightSelection;
    }
  }

  updateRightRenderDataForBucket(index: number) {
    this.rightRenderData.cells[index] = NONREACHING_PROBS;
    const bucket = this.rightStrat?.data[index];
    if (bucket) {
      const avg = bucket.getAvg();
      const probs = avg.probs;
      const ev = avg.ev ?? 0;
      if (probs) {
        const cellData = new CellRenderData(
          this.actionList,
          probs,
          avg.reach,
          ev,
          index === this.rightSelection
        );
        this.rightRenderData.cells[index] = cellData;
      }
    }
  }

  updateSummaryInfo() {
    if (this.leftSelection !== undefined) {
      this.selectedStrat = this.leftStrat?.data[this.leftSelection];
    } else if (this.rightSelection !== undefined) {
      this.selectedStrat = this.rightStrat?.data[this.rightSelection];
    } else {
      this.selectedStrat = this.strat;
    }
  }

  getRenderData(): [Matrix, Matrix | null] {
    return [this.leftRenderData, this.rightRenderData];
  }

  handleClicks(id: number, cell: number): void {
    // point: [number, number]
    // const cell = point[1] * 13 + point[0]
    if (id === 0) {
      //Clicking same cell will clear selection
      if (this.leftSelection === cell) {
        this.leftSelection = undefined as undefined | number;
        this.rightSelection = undefined as undefined | number;
      } else {
        this.leftSelection = cell;
      }
    }
    if (id === 1) {
      if (this.rightSelection === cell) {
        this.leftSelection = undefined as undefined | number;
        this.rightSelection = undefined as undefined | number;
      } else {
        this.rightSelection = cell;
      }
    }
    this.updateStrategy();
  }

  handleHover(id: number, point: [number, number] | undefined): void {
    if (point) {
      const n = id === 0 ? 13 : 4; //dimensions of hovered matrix
      const [x, y] = point;
      const i = n * y + x;
      if (id === 0 && this.leftSelection === undefined) {
        this.hoverData = this.leftStratFull.data[i];
      } else if (id === 1 && this.rightStrat !== undefined) {
        this.hoverData = this.rightStrat.data[i];
      }
    } else {
      this.hoverData = undefined as NodeGroup | undefined;
    }
  }

  getHoverData(): NodeGroup | undefined {
    return this.hoverData;
  }
}
