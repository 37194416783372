import { defineComponent as _defineComponent } from 'vue'
import { computed, ref } from "vue";
import AppButton from "@/components/common/app/AppButton.vue";
import { loadLocaleMessages } from "@/i18n";
import { useUserStore } from "@/stores/user";
import type { Lang } from "@/constants/languages";
import { langList } from "@/constants/languages";


export default /*@__PURE__*/_defineComponent({
  __name: 'LanguageSwitcher',
  emits: ["close"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const userStore = useUserStore();
const emits = __emit;

const currentLang = ref(
  langList.find((item) => item.value === userStore.currentUser?.language)
);
const selectLang = (lang: Lang) => {
  currentLang.value = lang;
};
const save = () => {
  loadLocaleMessages(currentLang.value?.value as string);
  userStore.updateUser({ language: currentLang.value?.value }).then(() => {
    emits("close");
  });
};
const isChangedLanguage = computed(() => {
  return currentLang.value?.value !== userStore?.currentUser?.language;
});

const __returned__ = { userStore, emits, currentLang, selectLang, save, isChangedLanguage, computed, ref, AppButton, get loadLocaleMessages() { return loadLocaleMessages }, get useUserStore() { return useUserStore }, get langList() { return langList } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})