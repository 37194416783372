import type { Card, Combo, Pair } from "../Combos";
import { getCardArray, getMatrixCell } from "../Combos";
import type { NodeGroup } from "../DecisionStrategy";
import { NodeAvgs } from "../DecisionStrategy";
import type { Matrix } from "../interfaces/matrix.interface";
import StrategyBuckets from "../StrategyBuckets";
import type { PokerBettingAction } from "../types/poker-betting-action.type";
import type { MatrixData } from "../matrix/MatrixData";
import {
  CellRenderData,
  EMPTY_NODE_GROUP,
  NONREACHING_PROBS,
  SingleMatrixData,
} from "../matrix/MatrixData";

/**
 * This function does the filtering for the middle 13x13 matrix. Given a quint and a selected left cell,
 * we want to figure out which cells this quint belongs in in the middle matrix.
 *
 * To do this, we iterate over all pairs in the quint. We check each if it matches the left selection.
 * If it does, then the remaining three cards are how we determine which middle matrix cell to put it in.
 * Three cards remain, and we return all three (possbly overlapping) indices.
 */
function middleMatrixCellsAfterRemovingSelection(
  quint: Combo,
  selection: number
): number[] {
  const cards = getCardArray(quint);
  for (const [[i, j], remaining] of FIVE_CARD_TWO_THREE_SPLIT) {
    if (getMatrixCell(cards[i], cards[j]) === selection) {
      return [
        getMatrixCell(cards[remaining[0]], cards[remaining[1]]),
        getMatrixCell(cards[remaining[0]], cards[remaining[2]]),
        getMatrixCell(cards[remaining[1]], cards[remaining[2]]),
      ];
    }
  }
  return [];
}

const FIVE_CARD_TWO_THREE_SPLIT = [
  [
    [0, 1],
    [2, 3, 4],
  ],
  [
    [0, 2],
    [1, 3, 4],
  ],
  [
    [0, 3],
    [1, 2, 4],
  ],
  [
    [0, 4],
    [1, 2, 3],
  ],
  [
    [1, 2],
    [0, 3, 4],
  ],
  [
    [1, 3],
    [0, 2, 4],
  ],
  [
    [1, 4],
    [0, 2, 3],
  ],
  [
    [2, 3],
    [0, 1, 4],
  ],
  [
    [2, 4],
    [0, 1, 3],
  ],
  [
    [3, 4],
    [0, 1, 2],
  ],
];

const FIVE_CARD_TWO_TWO_ONE: [number[], number[], number][] = [
  [[0, 1], [2, 3], 4],
  [[0, 1], [2, 4], 3],
  [[0, 1], [3, 4], 2],
  [[0, 2], [1, 3], 4],
  [[0, 2], [1, 4], 3],
  [[0, 2], [3, 4], 1],
  [[0, 3], [1, 2], 4],
  [[0, 3], [1, 4], 2],
  [[0, 3], [2, 4], 1],
  [[0, 4], [1, 2], 3],
  [[0, 4], [1, 3], 2],
  [[0, 4], [2, 3], 1],
  [[1, 2], [3, 4], 0],
  [[1, 3], [2, 4], 0],
  [[1, 4], [2, 3], 0],
];

const FIVE_CARD_PAIRS: [number, number][] = [
  [0, 1],
  [0, 2],
  [0, 3],
  [0, 4],
  [1, 2],
  [1, 3],
  [1, 4],
  [2, 3],
  [2, 4],
  [3, 4],
];
/**
 * This function does the filtering for the right 13x1 matrix. Given a quint and left and middle selected
 * indices.
 *
 * Iterate over all groupings of two pairs. If both sets of pairs
 */
function tailMatrixCellAfterRemovingSelections(
  quint: Combo,
  filter1: number,
  filter2: number
): number | undefined {
  const cards = getCardArray(quint);
  for (const [[a, b], [c, d], e] of FIVE_CARD_TWO_TWO_ONE) {
    if (
      getMatrixCell(cards[a], cards[b]) === filter1 &&
      getMatrixCell(cards[c], cards[d]) === filter2
    ) {
      return 12 - cards[e].rank;
    }
    if (
      getMatrixCell(cards[a], cards[b]) === filter2 &&
      getMatrixCell(cards[c], cards[d]) === filter1
    ) {
      return 12 - cards[e].rank;
    }
  }
}

export class FiveCardMatrixU8 implements MatrixData {
  actionList: PokerBettingAction[];
  leftSelection: number | undefined; //Java does this with a PreflopPair. Let's just use a cell index for now???
  middleSelection: number | undefined; // ditto.
  tailSelection: number | undefined; // ditto.

  leftSelectedPair: Pair | undefined; // Exact selection, not just Q7 suited, but Qd7d.

  // new StrategyBuckets(169) will give us 13x13 cells worth of data. This stores the *full*
  // DecisionStrategy in the buckets. When selections are applied, left and right may be
  // set to some filtered version of these, below.
  leftStratFull = new StrategyBuckets(169);

  // These represent the strats that we're going to display in the left and right matrices.
  leftStrat: StrategyBuckets | undefined; // Kinda not used! Will always equal leftStratFull in "normal" rankFilterMode
  middleStrat: StrategyBuckets | undefined;
  tailStrat: StrategyBuckets | undefined;

  // Current data to be displayed in the left/right matrices.
  // These will be averaged action probabilities or evs, from leftStrat / rightStrat
  // depending on whether we currently want to show evs.
  leftRenderData: SingleMatrixData;
  middleRenderData: SingleMatrixData;
  tailRenderData: SingleMatrixData;

  totalSummary: NodeAvgs = new NodeAvgs();

  // Cell data for the cell being hovered over - this would let us do popups that
  // show all of the hands in the current cell
  hoverData: NodeGroup | undefined;

  // Board cards can be specified here. Whenever we update cell data, we'll filter
  // out any combos that overlap with the cards listed here
  boardCards: Card[] = [];

  decisionStrategy: NodeGroup | undefined;
  selectedStrategy: NodeGroup | undefined;
  percentReach = 0;
  displayFreqs: number[] = [];

  constructor(actionList: PokerBettingAction[], board: Card[]) {
    this.actionList = actionList;
    this.leftRenderData = new SingleMatrixData(13, 13);
    this.middleRenderData = new SingleMatrixData(13, 13);
    this.tailRenderData = new SingleMatrixData(13, 1);
    this.boardCards = board;
  }
  getRenderData(): (Matrix | null)[] {
    if (this.leftSelection !== undefined) {
      if (this.middleSelection !== undefined) {
        return [
          this.leftRenderData,
          this.middleRenderData,
          this.tailRenderData,
        ];
      }
      return [this.leftRenderData, this.middleRenderData, null];
    }
    return [this.leftRenderData, null, null];
  }

  handleClicks(matrixIndex: number, cellIndex: number): void {
    if (matrixIndex === 0) {
      this.leftSelection =
        this.leftSelection !== cellIndex ? cellIndex : undefined;
      this.middleSelection = undefined as number | undefined;
      this.tailSelection = undefined as number | undefined;
    }
    if (matrixIndex === 1 && this.middleStrat) {
      this.middleSelection =
        this.middleSelection !== cellIndex ? cellIndex : undefined;
      this.tailSelection = undefined as number | undefined;
    }
    if (matrixIndex === 2 && this.tailStrat) {
      this.tailSelection =
        this.tailSelection !== cellIndex ? cellIndex : undefined;
    }
    this.updateStrategyForSelection();
  }

  setStrategy(strategy: NodeGroup, board: Card[]): void {
    this.decisionStrategy = strategy;
    this.boardCards = board;
    this.leftRenderData = new SingleMatrixData(13, 13);
    this.middleRenderData = new SingleMatrixData(13, 13);
    this.tailRenderData = new SingleMatrixData(13, 1);

    this.leftStratFull = new StrategyBuckets(169);
    this.totalSummary.clear();

    // Iterate over all data in the Decision strategy. Each entry has a QUAD and NodeData.
    // For each pair in the quad, we want to add the NodeData to matrix cell corresponding
    // to that pair.
    for (const node of strategy.nodeList) {
      this.totalSummary.addNode(node);
      const combo = node.holecards;
      if (combo instanceof Uint8Array) {
        const cards = getCardArray(combo);
        for (const [i, j] of FIVE_CARD_PAIRS) {
          const cell = getMatrixCell(cards[i], cards[j]);
          this.leftStratFull.addLocked(cell, node);
        }
        this.leftStratFull.unlock();
      } else {
        throw new Error("Oh no! Wrong combo type for this matrix class");
      }
    }

    //
    for (const combo of strategy.nonReaching) {
      this.totalSummary.addNonReaching();
      if (combo instanceof Uint8Array) {
        const cards = getCardArray(combo);
        for (const [i, j] of FIVE_CARD_PAIRS) {
          const cell = getMatrixCell(cards[i], cards[j]);
          this.leftStratFull.addNonReachingLocked(cell, combo);
        }
        this.leftStratFull.unlock();
      }
    }

    this.leftStrat = this.leftStratFull;

    // Maintain existing selections if we can.
    this.updateStrategyForSelection();
  }

  getSelectedStrat(): NodeGroup {
    return this.selectedStrategy || EMPTY_NODE_GROUP;
  }

  /**
   * This updates the values leftStrat and rightStrat. This will happen whenever we
   * make a new selection in the matrix.
   */
  updateStrategyForSelection() {
    // If there's a left and a middle selection, then fill out the tail strat
    if (
      this.leftSelection !== undefined &&
      this.middleStrat !== undefined &&
      this.middleSelection !== undefined
    ) {
      const tail = new StrategyBuckets(13);
      const bucket = this.middleStrat.data[this.middleSelection];
      if (bucket) {
        this.addDataToTailStrat(
          bucket,
          tail,
          this.leftSelection,
          this.middleSelection
        );
      }
      this.tailStrat = tail;
    }
    // If we have a selection, fill out the middle matrix
    else if (this.leftSelection !== undefined) {
      const middle = new StrategyBuckets(169);

      // Grab the matrix cell currently selected in the left matrix, and iterate over
      // all the data in it.
      const bucket = this.leftStratFull.data[this.leftSelection];
      if (bucket) {
        this.addDataToMiddleStrat(bucket, middle, this.leftSelection);
      }
      this.middleStrat = middle;
    } else {
      this.middleStrat = undefined as StrategyBuckets | undefined;
      this.tailStrat = undefined as StrategyBuckets | undefined;
    }

    this.updateRenderData();
  }

  /**
   * Iterate over all selected data, to compute middle matrix data
   *
   * @param bucket -- This is the partial strat data from the selected left cell
   * @param strat -- StrategyBuckets to process data into
   * @param selection  -- left selection index
   */
  private addDataToMiddleStrat(
    bucket: NodeGroup,
    strat: StrategyBuckets,
    selection: number
  ) {
    bucket.nodeList.forEach((node) => {
      for (const ordinal of middleMatrixCellsAfterRemovingSelection(
        node.holecards,
        selection
      )) {
        strat.addLocked(ordinal, node);
      }
      strat.unlock();
    });
    bucket.nonReaching.forEach((combo) => {
      for (const ordinal of middleMatrixCellsAfterRemovingSelection(
        combo,
        selection
      )) {
        strat.addNonReachingLocked(ordinal, combo);
      }
      strat.unlock();
    });
  }

  /**
   * Iterate over all selected data, to compute right strategy cells
   */
  private addDataToTailStrat(
    bucket: NodeGroup,
    strat: StrategyBuckets,
    leftSelection: number,
    midSelection: number
  ) {
    bucket.nodeList.forEach((node) => {
      const remaining = tailMatrixCellAfterRemovingSelections(
        node.holecards,
        leftSelection,
        midSelection
      );
      if (remaining !== undefined) {
        strat.add(remaining, node);
      }
    });
    bucket.nonReaching.forEach((combo) => {
      const remaining = tailMatrixCellAfterRemovingSelections(
        combo,
        leftSelection,
        midSelection
      );
      if (remaining !== undefined) {
        strat.addNonReaching(remaining, combo);
      }
    });
  }

  /**
   * Called after updateStrategy or setStrategy. This builds the RenderData that we
   * pass on to the rendering code
   */
  updateRenderData() {
    this.updateSummaryInfo();
    if (this.leftStrat) {
      this.updateLeftRenderData();
    }

    if (this.middleStrat) {
      this.updateRightRenderData();
    }

    if (this.tailStrat) {
      this.updateTailRenderData();
    }
  }

  private updateLeftRenderData() {
    for (let i = 0; i < 169; i++) {
      this.leftRenderData.cells[i] = NONREACHING_PROBS;
      const bucket = this.leftStrat?.data[i];
      if (bucket) {
        const cellData = this.getCellRenderData(
          bucket,
          i === this.leftSelection
        );
        this.leftRenderData.cells[i] = cellData;
      }
    }
    this.leftRenderData.selected = this.leftSelection;
  }

  private updateRightRenderData() {
    for (let i = 0; i < 169; i++) {
      this.middleRenderData.cells[i] = NONREACHING_PROBS;
      const bucket = this.middleStrat?.data[i];
      if (bucket) {
        const cellData = this.getCellRenderData(
          bucket,
          i === this.middleSelection
        );
        this.middleRenderData.cells[i] = cellData;
      }
    }
    this.middleRenderData.selected = this.middleSelection;
  }

  private updateTailRenderData() {
    for (let i = 0; i < 13; i++) {
      this.tailRenderData.cells[i] = NONREACHING_PROBS;
      const bucket = this.tailStrat?.data[i];
      if (bucket) {
        const cellData = this.getCellRenderData(
          bucket,
          i === this.tailSelection
        );
        this.tailRenderData.cells[i] = cellData;
      }
    }
    this.tailRenderData.selected = this.tailSelection;
  }

  private getCellRenderData(
    bucket: NodeGroup,
    selected: boolean
  ): CellRenderData | null {
    const avg = bucket.getAvg();
    const probs = avg.probs;
    const ev = avg.ev || 0;
    if (probs) {
      return new CellRenderData(
        this.actionList,
        probs,
        avg.reach,
        ev,
        selected
      );
    }
    return NONREACHING_PROBS;
  }

  /**
   * This gets the tuple to show as an average summary for the whole strat.
   *
   * If there's a left sellection, rightStrat will be set to the contents of the selected
   * left cell, so show that. We're showing a summary of the clicked cell, in that case.
   *
   * Otherwise, leftStrat will be leftStratFull, and summarize
   * the overall DecisionStrategy.
   */
  updateSummaryInfo() {
    if (this.tailSelection !== undefined) {
      this.selectedStrategy = this.tailStrat?.data[this.tailSelection];
    } else if (this.middleSelection !== undefined) {
      this.selectedStrategy = this.middleStrat?.data[this.middleSelection];
    } else if (this.leftSelection !== undefined) {
      this.selectedStrategy = this.leftStrat?.data[this.leftSelection];
    } else {
      this.selectedStrategy = this.decisionStrategy;
    }
  }
}
