import { defineComponent as _defineComponent } from 'vue'
import SpinnerComponent from "@/components/ui/SpinnerComponent.vue";
import { useGameStore } from "@/stores/game";


export default /*@__PURE__*/_defineComponent({
  __name: 'UnavailableOverlay',
  setup(__props, { expose: __expose }) {
  __expose();

const gameStore = useGameStore();

const __returned__ = { gameStore, SpinnerComponent, get useGameStore() { return useGameStore } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})