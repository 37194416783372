import { PokerTablePositionEnum } from "@/enums/pokerTableEnum";
import { PlayerStateEnum } from "@/enums/playerEnum";
import COLORS from "@/constants/colors";
import { deepCopy } from "@/utils/deepCopy";
import type { TablePosition } from "@/types/pokerTable";
import type { PlayerWithAdditionalInfo } from "@/types/player";

const {
  bottomCenter,
  bottomLeft,
  bottomRight,
  middleLeft,
  middleRight,
  topCenter,
  topLeft,
  topRight,
} = PokerTablePositionEnum;

export const positionMap: Record<string, PokerTablePositionEnum[]> = {
  2: [topCenter, bottomCenter],
  4: [middleRight, bottomCenter, middleLeft, topCenter],
  6: [topRight, bottomRight, bottomCenter, bottomLeft, topLeft, topCenter],
  shortDeck2Players: [topCenter, bottomCenter],
};

const leftRightYOffset = "20%";

export const getPlayerPosition = (playerPosition: PokerTablePositionEnum) => {
  const posMap: Record<PokerTablePositionEnum, { [key: string]: string }> = {
    [bottomCenter]: {
      bottom: "0",
      left: "50%",
      transform: `translateX(-50%)`,
    },
    [topCenter]: {
      top: "0",
      left: "50%",
      transform: `translateX(-50%)`,
    },
    [topLeft]: {
      top: leftRightYOffset,
      left: "0",
    },
    [bottomLeft]: {
      bottom: leftRightYOffset,
      left: "0",
    },
    [topRight]: {
      top: leftRightYOffset,
      right: "0",
    },
    [bottomRight]: {
      bottom: leftRightYOffset,
      right: "0",
    },
    [middleLeft]: {
      top: "50%",
      transform: `translateY(-50%)`,
      left: "0",
    },
    [middleRight]: {
      top: "50%",
      transform: `translateY(-50%)`,
      right: "0",
    },
  };
  return posMap[playerPosition];
};

export const setActivePositions = (
  posList: TablePosition[],
  activePosList: PokerTablePositionEnum[],
  color = COLORS.tablePlayerShadow.active
) => {
  return deepCopy(posList).map((pos: TablePosition) => {
    const position = typeof pos === "string" ? pos : pos.position;
    if (activePosList.includes(position)) {
      return { position, color };
    }
    return pos;
  });
};

export const getPlayerShadows = (
  playerList: PlayerWithAdditionalInfo[] | undefined
) => {
  if (!playerList) {
    return [];
  }
  const colorsByState = {
    [PlayerStateEnum.active]: COLORS.tablePlayerShadow.active,
    [PlayerStateEnum.current]: COLORS.tablePlayerShadow.active,
    [PlayerStateEnum.folded]: COLORS.tablePlayerShadow.folded,
    [PlayerStateEnum.regular]: COLORS.tablePlayerShadow.default,
  };

  return playerList.map((player) => {
    return {
      color: colorsByState[player.state],
      position: player.position,
    };
  });
};
