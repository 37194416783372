import { defineComponent as _defineComponent } from 'vue'
import { computed, ref, watch } from "vue";

interface Props {
  selectedCardsDesign: string;
}
interface Emits {
  (e: "set-selected-cards-design", cardsDesign: string): void;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CardsDesignSelect',
  props: {
    selectedCardsDesign: {}
  },
  emits: ["set-selected-cards-design"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const emits = __emit;

const whichCardsDesignsSelected = ref(
  Number(props.selectedCardsDesign.slice(-1)) > 6 ? "white" : "colored"
);
const numberOfCurrentVariant = computed(() => {
  const matchResult = props.selectedCardsDesign?.match(/\d+/)?.[0] ?? "0";
  return Number(matchResult);
});
const setSelectedCardsDesign = (cardsDesign: string) => {
  emits("set-selected-cards-design", cardsDesign);
};
const toggleCardsDesigns = () => {
  if (whichCardsDesignsSelected.value === "white") {
    setSelectedCardsDesign(`variant${numberOfCurrentVariant.value - 6}`);
  } else {
    setSelectedCardsDesign(`variant${numberOfCurrentVariant.value + 6}`);
  }
  whichCardsDesignsSelected.value =
    whichCardsDesignsSelected.value === "white" ? "colored" : "white";
};

watch(
  () => props.selectedCardsDesign,
  () => {
    if (numberOfCurrentVariant.value > 6) {
      whichCardsDesignsSelected.value = "white";
    } else {
      whichCardsDesignsSelected.value = "colored";
    }
  },
  {
    immediate: true,
  }
);

const __returned__ = { props, emits, whichCardsDesignsSelected, numberOfCurrentVariant, setSelectedCardsDesign, toggleCardsDesigns, computed, ref, watch }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})