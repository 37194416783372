import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cell__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["cell cell--default", $setup.matrixCardClasses()]),
    style: _normalizeStyle($props.actionOfCard)
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString($props.withoutItem ? "" : $props.item), 1)
  ], 6))
}