export enum GameTypeEnum {
  fiveCardOmaha = "FiveCardOmahaHi",
  omaha = "OmahaHi",
  holdem = "Holdem",
  shortdeck = "Shortdeck",
  singleDraw27 = "Draw27SD",
  tripleDraw27 = "Draw27TD",
  badugi = "Badugi",
}

export enum GameCategoryEnum {
  omaha = "Omaha",
  holdem = "Hold'em",
  draw = "Draw",
  stud = "Stud",
}

export enum StudGameEnum {
  stud = "Stud",
  razz = "Razz",
  studHiLo = "Stud HiLo",
}
