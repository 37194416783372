import { defineComponent as _defineComponent } from 'vue'
import { computed, shallowRef } from "vue";
import AppModal from "@/components/AppModal.vue";
import LanguageSwitcher from "@/components/settings/LanguageSwitcher.vue";
import AppearanceSettings from "@/components/settings/AppearanceSettings.vue";
import { useI18n } from "vue-i18n";

interface Emits {
  (e: "close"): void;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AppSettingsModal',
  emits: ["close"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const emits = __emit;
const { t } = useI18n();

const tabsList = computed(() => [
  {
    id: "appearance",
    component: AppearanceSettings,
    label: t("appearance"),
    class: "appearance-icon",
  },
  {
    id: "language",
    component: LanguageSwitcher,
    label: t("language"),
    class: "language-icon",
  },
]);
const currentTab = shallowRef(tabsList.value[0]);
const close = () => {
  emits("close");
};

const __returned__ = { emits, t, tabsList, currentTab, close, computed, shallowRef, AppModal, LanguageSwitcher, AppearanceSettings, get useI18n() { return useI18n } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})