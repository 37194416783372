import { useGameStore } from "@/stores/game";
import { allInLabel } from "@/features/action/actionLabel";
import type { StateInfo } from "@/types/general";

export const getRaisePercentOfPot = (
  raiseAction: string,
  stateInfo: StateInfo
) => {
  const raiseValue = raiseAction.substring(1);
  if (stateInfo) {
    if (
      +raiseValue ===
      stateInfo.roundContrib[stateInfo.playerIndex] +
        stateInfo.remainingStack[stateInfo.playerIndex]
    ) {
      return `${allInLabel} ${Math.round(
        ((+raiseValue - Math.max(...stateInfo.roundContrib)) /
          (stateInfo.pot +
            Math.max(...stateInfo.roundContrib) -
            stateInfo.roundContrib[stateInfo.playerIndex])) *
          100
      )}%`;
    }
    return Math.round(
      ((+raiseValue - Math.max(...stateInfo.roundContrib)) /
        (stateInfo.pot +
          Math.max(...stateInfo.roundContrib) -
          stateInfo.roundContrib[stateInfo.playerIndex])) *
        100
    );
  }
  return null;
};

export const isRaiseValueAllIn = (
  action: string,
  isPreflop: boolean,
  stateInfo: StateInfo
) => {
  if (isPreflop) {
    return getRaiseBbAmount(action, stateInfo).startsWith(allInLabel);
  } else {
    const result = getRaisePercentOfPot(action, stateInfo);
    if (typeof result === "string") {
      return result.startsWith(allInLabel);
    } else {
      return false;
    }
  }
};

export const getRaisePercentOrBb = (
  action: string,
  isPreflop: boolean,
  stateInfo: StateInfo
) => {
  if (isPreflop) {
    return getRaiseBbAmount(action, stateInfo);
  } else {
    return getRaisePercentOfPot(action, stateInfo);
  }
};

export const getRaiseBbAmount = (raiseAction: string, stateInfo: StateInfo) => {
  const gameStore = useGameStore();

  const raiseValue = raiseAction.substring(1);
  if (
    +raiseValue ===
      stateInfo.roundContrib[stateInfo.playerIndex] +
        stateInfo.remainingStack[stateInfo.playerIndex] &&
    stateInfo
  ) {
    return `${allInLabel} ${
      +raiseValue /
      (gameStore.configByConnectionName?.normalizingUnit
        ? gameStore.configByConnectionName?.normalizingUnit
        : 100)
    }bb`;
  }
  return `${
    +raiseValue /
    (gameStore.configByConnectionName?.normalizingUnit
      ? gameStore.configByConnectionName?.normalizingUnit
      : 100)
  }bb`;
};
