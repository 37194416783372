import type { UorgStreamReader, IUorgByteReader } from "./UorgByteReader";
import {
  UorgArrayByteReader,
  UorgChunkReaderZstdDecompress,
} from "./UorgByteReader";
import type { Combo } from "./Combos";
import {
  AcpcCards,
  AcpcCardsShortdeck,
  Card,
  CardMask,
  getColexIndexFromU8Indices,
  getCardArray,
  getOrdinal,
  getU8ComboFromOrdinal,
  NUM_QUINTS,
  Pair,
  Pairs,
  getCanonicalComboLookupTables,
  NUM_QUADS,
  numCardsForGame,
  numCombosFromCardCount,
  numCanonFromCardCount,
  ShortdeckPairs,
} from "./Combos";
import { DecisionStrategy, NodeData } from "./DecisionStrategy";
import * as Zstd from "fzstd";
import type { FloatReader } from "./UorgBuff";
import {
  UorgBuff,
  F16Reader,
  F32Reader,
  F8Reader,
  U16Reader,
  U8Reader,
} from "./UorgBuff";
import type { PokerBettingAction } from "./types/poker-betting-action.type";

function count1s32(i: number): number {
  let count = 0;
  i = i - ((i >> 1) & 0x55555555);
  i = (i & 0x33333333) + ((i >> 2) & 0x33333333);
  i = (i + (i >> 4)) & 0x0f0f0f0f;
  i = i + (i >> 8);
  i = i + (i >> 16);
  count += i & 0x3f;
  return count;
}

// Chunk types. These are the 4-byte codes packed into 32-bit ints.
export enum ChunkType {
  Done = 1162760004, //DONE
  Prop = 1347375696, //PROP
  CompressNextChunkZstd = 1146377050, //ZSTD
  Node = 1162104654, //NODE

  //
  Info = 1330007625, //INFO  // DEPRECATED v2.1 uses PROPS
  Opts = 1398034511, //OPTS  // DEPRECATED v2.1 uses PROPS
  Actions = 1398031169, //ACTS  // DEPRECATED v2.1 uses PROPS

  BucketNodes = 1262703938, //BNOD // Buckets, won't need for a while
  BucketHands = 1312901186, //BHAN // Buckets, won't need for a while

  ZstbBeginStream = 1195721306, //ZBEG  // ZSTD_STREAM, don't need!
  ZstdStreamData = 1413563482, //ZDAT  // ZSTD_STREAM, don't need!
  ZstdEndStream = 1145980250, //ZEND  // ZSTD_STREAM, don't need!
  EndOfStream = 559107909, //EOS!  // ZSTD_STREAM, don't need!
}

// Key values for the OPTIONS ("OPTS") chunk.
enum OptionKey {
  Null = 0,
  Actions = 3,
  Reach = 4,
  Evs = 5,
  EqRand = 6,
  EqSelf = 7,
  Regrets = 8,
  AccumAvg = 9,
  NumRounds = 50,
  ComboFormat = 51,
  ImplicitBuckets = 52,
  CanonicalHolecards = 54,
  OmitLastAction = 100,
  SkipFoldEvs = 101,
  FoldEV = 102,
  MaxEv = 103,
  TotalHands = 105,
}

enum FormatOptions {
  NONE = 0,
  F32 = 1,
  U16 = 2,
  U8 = 3,
  F16 = 4,
  F8 = 5,
}

const serializerMap: { [key: string]: FloatReader } = {
  f8: F8Reader,
  f16: F16Reader,
  f32: F32Reader,
  u16: U16Reader,
  u8: U8Reader,
};

// NEEDS CLEANUP!
// NOTE! This isn't going to work if the server stops returning the FULL set of
// masks, and only returns the allowed ones!
// Also, maybe not all sizes are available!
const MASK_TO_SIZE = [
  0, 1, 1, 2, 1, 2, 2, 3, 1, 2, 2, 3, 2, 3, 3, 4, 1, 2, 2, 3, 2, 3, 3, 4, 2, 3,
  3, 4, 3, 4, 4, 5,
];
function collapseDiscardMasks(node: NodeData) {
  function collapseArray(array: number[]): number[] {
    const resultLength = array.length === 32 ? 6 : 5;
    const result: number[] = Array.from({ length: resultLength });
    result.fill(0);
    for (let i = 0; i < array.length; i++) {
      result[MASK_TO_SIZE[i]] += array[i];
    }
    return result;
  }

  if (node.actions) {
    node.actions = collapseArray(node.actions);
  }
  if (node.expectedValues) {
    node.expectedValues = collapseArray(node.expectedValues);
  }
}

/**
 * Server gives us a "discards considered" mask. Each bit set
 * corresponds to a different discard mask that was considered.
 * e.g. If bit 12 is set, then mask 12 (12=1100=cards 3 and 4) were considered.
 *
 * This function goes from the considered mask, to a list of discard masks
 * that were considered. ie. an array of which bit positions were set in the
 * considered mask.
 *
 * 111001 --> [0, 3, 4, 5]
 */
function extractDrawMasksFromConsideredMask(mask: number): number[] {
  const result: number[] = [];
  for (let i = 0; i < 32; i++) {
    if ((mask & (1 << i)) > 0) {
      result.push(i);
    }
  }
  return result;
}

function collapseConsideredDrawMasks(
  node: NodeData,
  handType: string,
  considered: number
) {
  function collapseArray(consideredMasks: number[], array: number[]): number[] {
    const resultLength = handType === "DRAW,4" ? 5 : 6;
    const result: number[] = Array.from({ length: resultLength });
    result.fill(0);
    for (let i = 0; i < array.length; i++) {
      const val = array[i];
      const size = MASK_TO_SIZE[consideredMasks[i]];
      result[size] += val;
    }
    return result;
  }

  const masks = extractDrawMasksFromConsideredMask(considered);

  node.fullMaskActionList = masks.map((n) => n.toString());
  if (node.actions) {
    node.actions = collapseArray(masks, node.actions);
  }

  if (node.expectedValues) {
    node.expectedValues = collapseArray(masks, node.expectedValues);
  }
}

class NodeSerializer {
  options: Map<string, string>;
  actionReader?: FloatReader;
  evReader?: FloatReader;
  reachReader?: FloatReader;
  contrib = 0;
  omitFoldEv: boolean;
  omitLastAction: boolean;
  consideredDiscardsOnly: boolean;
  handType: string;

  constructor(options: Map<string, string>) {
    this.options = options;
    this.actionReader = this.getSerializer(options.get("ACTION"));
    this.evReader = this.getSerializer(options.get("EV"));
    this.reachReader = this.getSerializer(options.get("REACH"));
    this.consideredDiscardsOnly =
      options.get("ACTION_LIST") === "CONSIDERED_DISCARDS";
    this.handType = options.get("HAND") ?? "";

    const contribTxt = options.get("CONTRIBUTED");
    if (contribTxt) {
      this.contrib = Number(contribTxt);
    }
    this.omitFoldEv = options.has("OMIT_FOLD_EV");
    this.omitLastAction = options.has("OMIT_LAST_ACTION");
  }

  private getSerializer(fmt: string | undefined): FloatReader | undefined {
    if (fmt && fmt in serializerMap) {
      return serializerMap[fmt];
    }
  }

  readEvArray(
    buff: UorgBuff,
    numActions: number,
    evReader: FloatReader
  ): number[] {
    let numStored = numActions;
    if (this.omitFoldEv) {
      numStored--;
    }
    let evs = this.readArray(buff, evReader, numStored);

    // Convert to relative EVs
    for (let i = 0; i < evs.length; i++) {
      evs[i] += this.contrib;
    }

    if (this.omitFoldEv) {
      evs = [0, ...evs];
    }
    return evs;
  }

  readNode(buff: UorgBuff, actions: string[], skipReach = false): NodeData {
    let actionCount = actions.length;
    let consideredDrawMasks = 0;
    if (this.consideredDiscardsOnly) {
      consideredDrawMasks = buff.readU32();
      actionCount = count1s32(consideredDrawMasks);
    }

    const drawMasks = actions[0] === "0" || this.consideredDiscardsOnly;
    const result = new NodeData();
    //actions
    if (this.actionReader) {
      let numStored = actionCount;
      if (this.omitLastAction) {
        numStored--;
      }
      const probs = this.readArray(buff, this.actionReader, numStored);
      if (this.omitLastAction) {
        probs.push(1 - probs.reduce((sum, x) => sum + x, 0));
      }
      result.actions = probs;
    }

    //ev
    const evReader = this.evReader;
    if (evReader) {
      result.expectedValues = this.readEvArray(buff, actionCount, evReader);
    }

    //reach
    if (this.reachReader && !skipReach) {
      result.reach = this.reachReader.get(buff);
    }

    //Collapse draw masks into discard sizes, but remember the full mask
    // values too!
    if (drawMasks) {
      this.readNodeFixDiscardSizes(result, consideredDrawMasks);
    }
    return result;
  }

  readNodeFixDiscardSizes(nodeData: NodeData, consideredDrawMasks: number) {
    nodeData.fullMaskActions = nodeData.actions;
    nodeData.fullMaskEvs = nodeData.expectedValues;
    if (consideredDrawMasks) {
      collapseConsideredDrawMasks(nodeData, this.handType, consideredDrawMasks);
    } else {
      collapseDiscardMasks(nodeData);
    }
  }

  readNodeSkippingReach(buff: UorgBuff, actions: string[]): NodeData {
    return this.readNode(buff, actions, true);
  }

  readArray(buff: UorgBuff, reader: FloatReader, count: number): number[] {
    const result = [];
    for (let i = 0; i < count; i++) {
      result.push(reader.get(buff));
    }
    return result;
  }
}

class ComboSerializer {
  handType?: string;
  numRounds: number;

  constructor(options: Map<string, string>) {
    //NOTE Only supported combo formats at the moment are just numcards
    this.handType = options.get("HAND");
    this.numRounds = Number(options.get("NUM_ROUNDS"));
  }

  read(buff: UorgBuff): Combo {
    if (this.handType === "2") {
      const c1 = AcpcCards[buff.readU8()];
      const c2 = AcpcCards[buff.readU8()];
      return Pair.get(c1, c2);
    } else if (this.handType === "4") {
      return new Uint8Array([
        AcpcCards[buff.readU8()].ordinal,
        AcpcCards[buff.readU8()].ordinal,
        AcpcCards[buff.readU8()].ordinal,
        AcpcCards[buff.readU8()].ordinal,
      ]).sort((x, y) => y - x); //Descending
    } else if (this.handType === "DRAW,4") {
      //Ignore earlier hands --- why does the server even return these?
      for (let i = 0; i < 5 * (this.numRounds - 1); i++) {
        buff.readU8();
      }
      return new Uint8Array([
        AcpcCards[buff.readU8()].ordinal,
        AcpcCards[buff.readU8()].ordinal,
        AcpcCards[buff.readU8()].ordinal,
        AcpcCards[buff.readU8()].ordinal,
      ]).sort((x, y) => y - x); //Descending
    } else if (this.handType === "5") {
      return new Uint8Array([
        AcpcCards[buff.readU8()].ordinal,
        AcpcCards[buff.readU8()].ordinal,
        AcpcCards[buff.readU8()].ordinal,
        AcpcCards[buff.readU8()].ordinal,
        AcpcCards[buff.readU8()].ordinal,
      ]).sort((x, y) => y - x); //Descending
    } else if (this.handType === "DRAW,5") {
      //Ignore earlier hands --- why does the server even return these?
      for (let i = 0; i < 5 * (this.numRounds - 1); i++) {
        buff.readU8();
      }
      return new Uint8Array([
        AcpcCards[buff.readU8()].ordinal,
        AcpcCards[buff.readU8()].ordinal,
        AcpcCards[buff.readU8()].ordinal,
        AcpcCards[buff.readU8()].ordinal,
        AcpcCards[buff.readU8()].ordinal,
      ]).sort((x, y) => y - x); //Descending
    } else if (this.handType === "SHORT") {
      const c1 = AcpcCardsShortdeck[buff.readU8()];
      const c2 = AcpcCardsShortdeck[buff.readU8()];
      return Pair.get(c1, c2);
    } else {
      throw new Error("Unexpected number of cards for combo:" + this.handType);
    }
  }
}

function readOptions(info: Map<string, string>, buff: UorgBuff) {
  function convertOptionChunkFormatSpecifier(fmt: number): string {
    switch (fmt) {
      case FormatOptions.F32:
        return "f32";
      case FormatOptions.U16:
        return "u16";
      case FormatOptions.F16:
        return "f16";
      case FormatOptions.U8:
        return "u8";
      case FormatOptions.F8:
        return "f8";
      case FormatOptions.NONE:
        return "0";
      default:
        throw new Error("Invalid format: " + fmt);
    }
  }

  function convertOptionChunkComboFormat(fmt: number): string {
    switch (fmt) {
      case 2:
        return "2";
      case 4:
        return "4";
      case 130:
        return "SHORT";
      default:
        throw new Error("Invalid combo format: " + fmt);
    }
  }

  const optionKeyArray = [
    OptionKey.Actions,
    OptionKey.Evs,
    OptionKey.Reach,
    OptionKey.CanonicalHolecards,
    OptionKey.ComboFormat,
    OptionKey.TotalHands,
    OptionKey.FoldEV,
    OptionKey.SkipFoldEvs,
    OptionKey.OmitLastAction,
    OptionKey.ImplicitBuckets,
    OptionKey.Null,
  ];
  while (!buff.isDone()) {
    const option = buff.readU8();

    const handleNodeFormatOptions = () => {
      if (option === OptionKey.Actions) {
        info.set("ACTION", convertOptionChunkFormatSpecifier(buff.readU8()));
      } else if (option === OptionKey.Evs) {
        info.set("EV", convertOptionChunkFormatSpecifier(buff.readU8()));
      } else if (option === OptionKey.Reach) {
        info.set("REACH", convertOptionChunkFormatSpecifier(buff.readU8()));
      }
    };

    const handleOtherOptions = () => {
      if (option === OptionKey.Null) {
        //no-op
      } else if (option === OptionKey.CanonicalHolecards) {
        info.set("CANON", "");
      } else if (option === OptionKey.ComboFormat) {
        info.set("HAND", convertOptionChunkComboFormat(buff.readU8()));
      } else if (option === OptionKey.FoldEV) {
        info.set("FOLD_EV", buff.readF32().toFixed(4));
      } else if (option === OptionKey.SkipFoldEvs) {
        info.set("OMIT_FOLD_EV", "");
      } else if (option === OptionKey.OmitLastAction) {
        info.set("OMIT_LAST_ACTION", "");
      } else if (option === OptionKey.ImplicitBuckets) {
        if (buff.readU8() !== 0) {
          info.set("IMPLICIT_BUCKETS", "");
        }
      } else if (option === OptionKey.TotalHands) {
        info.set("MAX_HANDS", buff.readU32().toFixed(0));
      }
    };

    handleNodeFormatOptions();
    handleOtherOptions();

    if (!optionKeyArray.includes(option)) {
      throw new Error("Unexpected option in OPTION chunk: " + option);
    }
  }
}

function readActions(bytes: Uint8Array): PokerBettingAction[] {
  const textDecoder = new TextDecoder();
  const fullTxt = textDecoder.decode(bytes);
  return fullTxt.split(",") as PokerBettingAction[];
}

function readNodeData(
  buff: UorgBuff,
  strat: DecisionStrategy,
  seenCombos: Set<number>,
  canonical: boolean,
  nodeSerializer: NodeSerializer,
  comboSeriailizer: ComboSerializer,
  handReadCallback: (n: number) => void
) {
  function addCanonicalCombos(combo: Uint8Array, node: NodeData) {
    const lookup = getCanonicalComboLookupTables(combo.length);
    const ordinal = getColexIndexFromU8Indices(combo);
    const numCombos = lookup.fullIsoCount[ordinal];

    seenCombos.add(ordinal);
    node.count = numCombos;
    node.holecards = combo;
    node.id = ordinal;
    strat.decisionNodes.add(node);
    handReadCallback(numCombos);
  }

  // We might need some extra info mapping QUADS to NUMBER of canonical maybe?
  // Who calls this? Do we need this?
  function addCanonical(combo: Combo, node: NodeData) {
    if (combo instanceof Uint8Array) {
      addCanonicalCombos(combo, node);
    } else {
      throw new Error(
        "Don't understand canonical of size " + getCardArray(combo).length
      );
    }
  }

  function addExactHand(combo: Combo, node: NodeData) {
    if (!seenCombos.has(getOrdinal(combo))) {
      node.holecards = combo;
      strat.decisionNodes.add(node.withFixedId());
      seenCombos.add(getOrdinal(combo));
      handReadCallback(1);
    } else {
      // We should only have seen the combo before if we're sampling, in which
      // case, count that we've tried to do that sample!
      handReadCallback(1);
    }
  }

  while (!buff.isDone()) {
    const combo = comboSeriailizer.read(buff);
    const node = nodeSerializer.readNode(buff, strat.actionListing.betting);
    if (canonical) {
      addCanonical(combo, node);
    } else {
      addExactHand(combo, node);
    }
  }
}

class BucketMapper {
  map: Map<number, NodeData> = new Map();
  actions: string[];
  counter = 0;
  implicitBuckets: boolean;

  constructor(actions: string[], implicitBuckets: boolean) {
    this.actions = actions;
    this.implicitBuckets = implicitBuckets;
  }

  getBucketSize(): number {
    if (this.counter <= 256) {
      return 1;
    }
    if (this.counter <= 65536) {
      return 2;
    }
    return 4;
  }

  readNodes(size: number, bytes: Uint8Array, nodeSerializer: NodeSerializer) {
    const buff = new UorgBuff(bytes, size);
    while (!buff.isDone()) {
      const bucketKey: number = this.implicitBuckets
        ? this.counter
        : buff.readU32();
      const node = nodeSerializer.readNodeSkippingReach(buff, this.actions);
      this.map.set(bucketKey, node);
      this.counter++;
    }
  }

  readHands(
    strat: DecisionStrategy,
    seenCombos: Set<number>,
    size: number,
    bytes: Uint8Array,
    comboSerializer: ComboSerializer,
    nodeSerializer: NodeSerializer
  ) {
    const buff = new UorgBuff(bytes, size);
    const storageSize = this.implicitBuckets ? this.getBucketSize() : 4;

    while (!buff.isDone()) {
      const combo = comboSerializer.read(buff);
      seenCombos.add(getOrdinal(combo));
      const bucketKey: number = buff.readUInt(storageSize);
      const reach = nodeSerializer.reachReader?.get(buff) || 1.0;
      const node = this.map.get(bucketKey);
      if (node) {
        const copy = new NodeData();
        copy.actions = node.actions;
        copy.reach = node.reach;
        copy.reach = reach;
        copy.holecards = node.holecards;
        strat.decisionNodes.add(copy.withFixedId());
      } else {
        throw new Error(`Can't find bucket ${bucketKey} in map`);
      }
    }
  }
}

class ParseContext {
  baseReader: UorgStreamReader;
  reader: IUorgByteReader;
  compressionStream?: UorgChunkReaderZstdDecompress; //Used for ZSTD streaming mode. Safe to ignore!

  // queryMsg: QueryMsg // Only for passing back timing information! Query responses should be self contained
  strat?: DecisionStrategy;
  seenCombos = new Set<number>();

  actions: PokerBettingAction[] = [];
  info: Map<string, string> = new Map();
  nodeSerializer: NodeSerializer | undefined;
  comboSeriailizer: ComboSerializer | undefined;
  textEncoder = new TextDecoder();

  isDrawMasks = false;
  board: Card[] = [];

  bucketMapper?: BucketMapper; // Used for bucket modes, safe to ignore!

  progressCallback?: (n: number) => void;

  // To track progress!
  totalHands = 0;
  readHands = 0;

  constructor(
    /*query: QueryMsg, */ reader: UorgStreamReader,
    progressCallback: ((n: number) => void) | undefined
  ) {
    // this.queryMsg = query
    this.reader = reader;
    this.baseReader = reader;
    this.progressCallback = progressCallback;
  }

  drawHandSize(): number {
    if (this.info.get("HAND") === "DRAW,4") {
      return 4;
    }
    if (this.info.get("HAND") === "DRAW,5") {
      return 5;
    }
    throw new Error("Can't figure out hand size!");
  }

  async readAndProcessChunk(source: IUorgByteReader): Promise<number> {
    const nextChunk = await source.readIntLE();
    let size: number;
    if (nextChunk !== ChunkType.Done) {
      size = await source.readIntLE();
    } else {
      size = 0;
      // Should read a single "\n" after DONE.
      await source.readBytes(1);
    }
    let bytes: Uint8Array;
    if (size > 0) {
      bytes = await source.readBytes(size);
    } else {
      bytes = new Uint8Array(0);
    }
    await this.processChunk(nextChunk, size, bytes);
    return nextChunk;
  }

  async processChunkData(nextChunk: number, bytes: Uint8Array) {
    if (nextChunk === ChunkType.Done) {
      this.fillInNonReachHands();
      if (this.isDrawMasks && this.strat) {
        // Fix action listing for draw hands
        // I think we can eventually handle this better and just fill these in
        // earlier when parsing?
        this.strat.actionListing = {
          fullMasks: this.strat.actionListing.betting,
          isDiscardAction: this.isDrawMasks,
          betting:
            this.drawHandSize() === 5
              ? ["d0", "d1", "d2", "d3", "d4", "d5"]
              : ["d0", "d1", "d2", "d3", "d4"],
        };
      }
    } else if (nextChunk === ChunkType.Info) {
      this.readInfo(bytes);
    } else if (nextChunk === ChunkType.Prop) {
      this.readProps(bytes);
      this.nodeSerializer = new NodeSerializer(this.info);
      this.comboSeriailizer = new ComboSerializer(this.info);
      this.totalHands = this.maxHandsForGame(this.info.get("GAMETYPE"));
    }
  }

  maxHandsForGame(game: string | undefined): number {
    game = game?.toLowerCase();
    if (
      game?.startsWith("5omaha") ||
      game?.startsWith("five") ||
      game?.startsWith("draw")
    ) {
      return NUM_QUINTS;
    }
    if (game?.startsWith("omaha") || game?.startsWith("badugi")) {
      return NUM_QUADS;
    }
    if (game?.startsWith("shortdeck")) {
      return 630;
    }
    return 1326;
  }

  async processChunkDataPartTwo(
    nextChunk: number,
    size: number,
    bytes: Uint8Array
  ) {
    if (nextChunk === ChunkType.Opts) {
      const buff = new UorgBuff(bytes, size);
      readOptions(this.info, buff);
      this.nodeSerializer = new NodeSerializer(this.info);
      this.comboSeriailizer = new ComboSerializer(this.info);
      this.totalHands = Number(this.info.get("MAX_HANDS") || 0);
    } else if (nextChunk === ChunkType.Actions) {
      this.actions = readActions(bytes);
    } else if (nextChunk === ChunkType.CompressNextChunkZstd) {
      const decompressed = Zstd.decompress(bytes);
      await this.readAndProcessChunk(new UorgArrayByteReader(decompressed));
    } else if (nextChunk === ChunkType.Node) {
      if (this.strat === undefined) {
        //!! We should know if it's a discard action based on query properties!
        this.strat = new DecisionStrategy({
          betting: this.actions,
          isDiscardAction: false,
        });
      }
      if (
        this.nodeSerializer === undefined ||
        this.comboSeriailizer === undefined
      ) {
        throw new Error(
          "NODE chunks received but node and combo seriailizers are not initialized"
        );
      }
      const buff = new UorgBuff(bytes, size);

      readNodeData(
        buff,
        this.strat,
        this.seenCombos,
        this.info.has("CANON"),
        this.nodeSerializer,
        this.comboSeriailizer,
        (n) => {
          this.readHands += n;
          if (this.totalHands > 0) {
            this.progressCallback?.((100.0 * this.readHands) / this.totalHands);
          }
        }
      );
    }
  }

  async processChunkDataPartThree(
    nextChunk: number,
    size: number,
    bytes: Uint8Array
  ) {
    if (nextChunk === ChunkType.BucketNodes) {
      if (this.nodeSerializer === undefined) {
        throw new Error(
          "BucketNode chunk received but node seriailizer is not initialized"
        );
      }

      if (!this.bucketMapper) {
        this.bucketMapper = new BucketMapper(
          this.actions,
          this.info.has("IMPLICIT_BUCKETS")
        );
      }
      this.bucketMapper.readNodes(size, bytes, this.nodeSerializer);
    } else if (nextChunk === ChunkType.BucketHands) {
      if (
        this.bucketMapper === undefined ||
        this.nodeSerializer === undefined ||
        this.comboSeriailizer === undefined
      ) {
        throw new Error("BucketHands chunk received but missing required data");
      }
      if (this.strat === undefined) {
        this.strat = new DecisionStrategy({
          betting: this.actions,
          isDiscardAction: false,
        });
        // this.strat.query = this.queryMsg
      }
      this.bucketMapper.readHands(
        this.strat,
        this.seenCombos,
        size,
        bytes,
        this.comboSeriailizer,
        this.nodeSerializer
      );
    }
  }

  async processChunkDataPartFour(nextChunk: number) {
    if (nextChunk === ChunkType.ZstbBeginStream) {
      this.compressionStream = new UorgChunkReaderZstdDecompress(
        this.baseReader
      );
      this.reader = this.compressionStream;
    } else if (nextChunk === ChunkType.EndOfStream) {
      this.reader = this.baseReader;
    } else if (nextChunk === ChunkType.ZstdEndStream) {
      //no-op --- we shouldn't reach this. This will be read in
      // along with the ZstdStreamData chunks in UorgChunkReaderZstdDecompress!
    }
  }

  async processChunk(nextChunk: number, size: number, bytes: Uint8Array) {
    this.processChunkData(nextChunk, bytes);
    this.processChunkDataPartTwo(nextChunk, size, bytes);
    // BUCKET MODE --- Packing alternative for possible smaller queries in big games,
    //      can go without these for a while but probably want them eventually!?
    this.processChunkDataPartThree(nextChunk, size, bytes);
    // STREAMING MODE ZSTD --- This seems to perform worse than the regular ZSTD mode
    //  and is way more complicated. Safe to ignore these
    this.processChunkDataPartFour(nextChunk);
  }

  readNullTerminatedString(
    bytes: Uint8Array,
    offset: number
  ): [string | undefined, number] {
    if (offset >= bytes.length) {
      return [undefined, bytes.length];
    }

    let result = "";
    let i = offset;
    let x = bytes[i];
    i++;
    while (x !== 0 && i < bytes.length) {
      result += String.fromCharCode(x);
      x = bytes[i];
      i++;
    }
    return [result, i];
  }

  /**
   * Read info chunk. Alternate reading key value pairs from the byte stream
   */
  readInfo(bytes: Uint8Array) {
    let offset = 0;
    let key: string | undefined;
    let val: string | undefined;
    while (offset < bytes.length) {
      [key, offset] = this.readNullTerminatedString(bytes, offset);
      [val, offset] = this.readNullTerminatedString(bytes, offset);
      if (key && val) {
        this.info.set(key, val);
      }
    }

    const boardTxt = this.info.get("BOARD");
    if (boardTxt) {
      this.board = Card.parseArray(boardTxt);
    }
  }

  /**
   * Read props chunk! This replaces INFO, ACTS, and OPTS!
   */
  readProps(bytes: Uint8Array) {
    const textDecoder = new TextDecoder();
    const fullTxt = textDecoder.decode(bytes);

    for (const entry of fullTxt.split(";")) {
      if (entry.includes("=")) {
        const [key, val] = entry.split("=");
        this.info.set(key, val);
      } else {
        this.info.set(entry, "");
      }
    }

    const boardTxt = this.info.get("BOARD");
    if (boardTxt) {
      this.board = Card.parseArray(boardTxt);
    }

    const actionListTxt = this.info.get("ACTION_LIST");
    if (actionListTxt === "CONSIDERED_DISCARDS") {
      //We don't have a usual actions list here!
      //We'll fix this up and return back {d0,d1,d2,d3,d4,d5}
      //when returning to front end though!
      this.actions = [];
      this.isDrawMasks = true;
    } else if (actionListTxt) {
      this.actions = actionListTxt.split(",") as PokerBettingAction[];
      this.isDrawMasks = this.actions[0] === "0";
    }
  }

  /**
   * Depending on the game, iterate over all hands for that game
   * and add them to our non-reaching list in our result strat
   */
  fillInNonReachHands() {
    const boardTxt = this.info.get("BOARD") || "";
    const upcardsTxt = (this.info.get("UP_CARDS") || "").replace(",", "");
    const publicCards = Card.parseArray(boardTxt + upcardsTxt);
    const game = this.info.get("GAMETYPE")?.toLowerCase() || "";
    const numCards = numCardsForGame(game || "");

    //Turn off nonreaching hands for now to see how much space is saved!
    if (numCards >= 5) {
      return;
    }

    if (numCards >= 4) {
      const numCombos = numCombosFromCardCount(numCards);
      const numCanon = numCanonFromCardCount(numCards);
      if (this.info.has("CANON")) {
        this.addNonReachingCanonFromOrdinals(numCards, numCanon);
      } else {
        this.addNonReachingCombosFromOrdinals(publicCards, numCards, numCombos);
      }
    } else if (game === "shortdeck") {
      this.addNonReachingCombos(publicCards, ShortdeckPairs);
    } else {
      this.addNonReachingCombos(publicCards, Pairs);
    }
  }

  /**
   * Given a list of combos and the board, go through each combo and
   * see if it's "non-reaching". It should be a combo that we've not already
   * processed - check this.seenCombos, and also it should be a possible hand
   * i.e. not a hand that intersects with the board cards.
   */
  // Can continue counting progress in here as we add combos to "NonReaching"!
  addNonReachingCombos(board: Card[], comboList: Combo[]) {
    const boardMask = new CardMask(board);
    comboList.forEach((q) => {
      const inStrat = this.seenCombos.has(getOrdinal(q));
      if (!inStrat) {
        const insersectsBoard = boardMask.intersect(getCardArray(q));
        if (!insersectsBoard) {
          this.strat?.decisionNodes?.nonReaching.push(q);
        }
      }
    });
  }

  // Probably will never use this? Post flop we probably turn on sampling?
  // But it will be a model for how to handle Quads when I update them next.
  addNonReachingCombosFromOrdinals(
    board: Card[],
    numCards: number,
    numCombos: number
  ) {
    const boardMask = new CardMask(board);
    for (let ordinal = 0; ordinal < numCombos; ordinal++) {
      if (!this.seenCombos.has(ordinal)) {
        const combo = getU8ComboFromOrdinal(ordinal, numCards);
        if (!boardMask.intersect(getCardArray(combo))) {
          this.strat?.decisionNodes?.nonReaching.push(combo);
        }
      }
    }
  }

  addNonReachingCanonFromOrdinals(numCards: number, numCanon: number) {
    const lookupTable = getCanonicalComboLookupTables(numCards);
    for (let i = 0; i < numCanon; i++) {
      const ordinal = lookupTable.canonOrdinal[i];
      const inStrat = this.seenCombos.has(ordinal);
      if (!inStrat) {
        const combo = getU8ComboFromOrdinal(ordinal, numCards);
        this.strat?.decisionNodes?.addNonReachingCount(
          combo,
          lookupTable.canonIsoCount[i]
        );
      }
    }
  }

  async readEntireStream(): Promise<[DecisionStrategy, Card[]]> {
    let chunk: ChunkType | undefined;
    while (chunk !== ChunkType.Done) {
      chunk = await this.readAndProcessChunk(this.reader);
    }
    return [
      this.strat ||
        new DecisionStrategy({ betting: this.actions, isDiscardAction: false }),
      this.board,
    ];
  }
}

export async function parseQueryV2(
  uorgReader: UorgStreamReader,
  progressCallback: (n: number) => void
): Promise<[DecisionStrategy, Card[]]> {
  const parseContext = new ParseContext(uorgReader, progressCallback);
  return parseContext.readEntireStream();
}
