import type { Combo, Rank } from "../Combos";
import { Card, getCardArray, RANK_TXT } from "../Combos";
import type { Filter } from "./HandFilters";

// Uppercase version of suits. We need to match against these since we're
// making everything upper case for matching ranks A, K, Q, etc.
const SUIT_TXT_UPPER = ["C", "D", "H", "S"];

/**
 * Create a filter based on a combo search string.
 *
 * Supported for now are just RANKS or CARDS.
 * Case insensitive and optional commas between cards,ranks
 */
export function makeComboSearchFilter(textRaw: string): Filter | null {
  const text = textRaw.replace(",", "").toUpperCase();

  //Lists of ranks and cards to match
  let ranksToMatch: Rank[] = [];
  let cardsToMatch: number[] = [];

  // Parse the string to figure out what we want to match
  let i = 0;
  while (i < text.length) {
    const rank = RANK_TXT.indexOf(text[i]);
    const suit = SUIT_TXT_UPPER.indexOf(text[i + 1]);

    if (rank < 0) {
      // If next character isn't a rank, something went wrong, return null to signal invalid filter!
      return null;
    } else if (suit < 0) {
      // If it's not followed by a suit, then we're just matching this rank
      ranksToMatch.push(rank);
      i++;
    } else {
      // followed by a suit, match exact card
      cardsToMatch.push(Card.get(rank, suit).ordinal);
      i += 2;
    }
  }

  // Now! Sort descending to match the order we'll see Combos' cards in.
  ranksToMatch = ranksToMatch.sort((a, b) => b - a);
  cardsToMatch = cardsToMatch.sort((a, b) => b - a);

  // Filter will pass IF we match all of the ranks and cards in the search string.
  // Everything is sorted descending. Keep track of the next rank to match AND the next card
  // to match. Always try matching CARDS first, as this handles the case where we search something
  // like "AAd" - This should match AdAc, so we want to check off Ad rather than just the rank A.
  //
  //  For each card
  //    If current card matches our next card to match, increment that index.
  //    Otherwise, If current card matcher our next rank to match, increment that index.
  //
  //  If we match all expected ranks AND cards, then we succeed!
  return {
    passes: (combo: Combo /*, board: Card[]*/) => {
      let handIndex = 0;
      let rankMatcherIndex = 0;
      let cardMatcherIndex = 0;
      const cards = getCardArray(combo);
      while (
        (rankMatcherIndex < ranksToMatch.length ||
          cardMatcherIndex < cardsToMatch.length) &&
        handIndex < cards.length
      ) {
        const card = cards[handIndex];

        // Match next highest card if we can
        const cardMatchOrdinal = cardsToMatch[cardMatcherIndex];
        if (cardMatchOrdinal === card.ordinal) {
          cardMatcherIndex++;
        } else {
          // If no card to match, match next highest rank
          const rank = ranksToMatch[rankMatcherIndex];
          if (rank === cards[handIndex].rank) {
            rankMatcherIndex++;
          }
        }

        handIndex++;
      }
      return (
        rankMatcherIndex >= ranksToMatch.length &&
        cardMatcherIndex >= cardsToMatch.length
      );
    },
  };
}
