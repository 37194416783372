import type { Card, Combo } from "./Combos";
import { getOrdinal, Pairs } from "./Combos";
import { type Filter } from "./filters/HandFilters";
import type { ActionListing } from "./interfaces/action-listing.interface";
import type { PokerBettingAction } from "./types/poker-betting-action.type";

/**
 * Decision Strategy stuff!
 */

/**
 * Stores a hand(holecards) with corresponding actions,evs,reach
 */
export class NodeData {
  id = 0;
  holecards: Combo = Pairs[0];
  actions: number[] = [];
  expectedValues?: number[];
  reach = 1;
  count = 1;

  // Full mask data for draw hands
  fullMaskActionList?: string[];
  fullMaskActions?: number[];
  fullMaskEvs?: number[];

  numActions(): number {
    return this.actions?.length || 0;
  }

  //Computes EV over node. (weighted by action probs)
  getOverallEV(): number {
    if (this.actions && this.expectedValues) {
      let total = 0;
      for (let i = 0; i < this.expectedValues.length; i++) {
        total += this.expectedValues[i] * this.actions[i];
      }
      return total;
    }
    return 0;
  }

  getMaxEv(): number {
    if (this.expectedValues) {
      return Math.max(...this.expectedValues);
    }
    return 0;
  }

  /**
   * Assign id field based on the hand for this node.
   */
  withFixedId(): NodeData {
    this.id = getOrdinal(this.holecards);
    return this;
  }

  copyWithCombo(combo: Combo) {
    const result = new NodeData();
    result.actions = this.actions;
    result.expectedValues = this.expectedValues;
    result.reach = this.reach;
    result.holecards = combo;
    return result;
  }
}

/**
 * This is basically the same as NodeData. The only difference, is that we've dropped
 * the array of EVs and just take the max.
 */
export class AggregateData {
  probs: number[] = [];
  ev: number | undefined;
  reach = 0;
}

/**
 * Records sums and averages over nodes
 */
export class NodeAvgs {
  avgComputed = false;
  count = 0;
  nonReaching = 0;
  sumNode = new AggregateData();
  avgNode = new AggregateData();

  addNode(node: NodeData) {
    if (this.count === 0) {
      this.sumNode.probs = Array(node.numActions());
      this.sumNode.probs.fill(0);
    }
    if (node.actions) {
      for (let i = 0; i < node.numActions(); i++) {
        this.sumNode.probs[i] += node.reach * node.actions[i] * node.count;
      }
    }

    if (node.expectedValues) {
      if (this.sumNode.ev) {
        this.sumNode.ev += node.reach * node.getMaxEv() * node.count;
      } else {
        this.sumNode.ev = node.reach * node.getMaxEv() * node.count;
      }
    }

    this.count += node.count;
    this.sumNode.reach += node.reach * node.count;
    this.avgComputed = false;
  }

  getAvg(): AggregateData {
    if (!this.avgComputed && this.sumNode.probs) {
      this.avgNode.probs = this.sumNode.probs.map(
        (x) => x / this.sumNode.reach
      );
      if (this.sumNode.ev) {
        this.avgNode.ev = this.sumNode.ev / this.sumNode.reach;
      }

      this.avgNode.reach = this.sumNode.reach / (this.count + this.nonReaching);
      this.avgComputed = true;
    }
    return this.avgNode;
  }

  addNonReaching() {
    this.nonReaching++;
    this.avgComputed = false;
  }

  clear() {
    this.count = 0;
    this.sumNode = new AggregateData();
    this.avgNode = new AggregateData();
    this.avgComputed = false;
    this.nonReaching = 0;
  }
}

/**
 * Collection of NodeData. This can represent the full strategy,
 *  or the contents of a matrix cell. When nodes are added, we
 * keep track of stats for averaging probs, evs, reach.
 */
export class NodeGroup {
  nodeList: NodeData[] = [];
  summary: NodeAvgs = new NodeAvgs();
  nonReaching: Combo[] = [];

  add(node: NodeData) {
    this.nodeList.push(node);
    this.summary.addNode(node);
  }

  addNonReaching(combo: Combo) {
    this.nonReaching.push(combo);
    this.summary.addNonReaching();
  }

  addNonReachingCount(combo: Combo, count: number) {
    for (let i = 0; i < count; i++) {
      this.addNonReaching(combo);
    }
  }

  getAvg(): AggregateData {
    return this.summary.getAvg();
  }

  getTotalReach(): number {
    return this.summary.sumNode.reach;
  }

  getPercentReach(): number {
    return (
      (100 * this.summary.sumNode.reach) /
      (this.summary.count + this.nonReaching.length)
    );
  }
}

/**
 * The main thing I'm using everywhere is NodeGroup. Matrix cells
 * stores these, so this is being used instead of DecisionStrategy.
 *
 * DecisionStrategy is just what gets returned from parsing, and
 * includes the action listing. It might also include some other
 * things later, like game type.
 */
export class DecisionStrategy {
  actionListing: ActionListing;
  isDiscardAction = false;

  decisionNodes: NodeGroup = new NodeGroup();

  constructor(actions: ActionListing) {
    this.actionListing = actions;
  }
}

export function makeDecisionStratWithActions(
  actions: PokerBettingAction[]
): DecisionStrategy {
  return new DecisionStrategy({
    betting: actions,
    isDiscardAction: actions[0] === "0",
  });
}

export function filterDecisionStrat(
  game: string,
  strat: NodeGroup,
  board: Card[],
  filter: Filter
): NodeGroup {
  const result = new NodeGroup();
  for (const node of strat.nodeList) {
    if (filter.passes(node.holecards, board)) {
      result.add(node);
    }
  }
  for (const combo of strat.nonReaching) {
    if (filter.passes(combo, board)) {
      result.nonReaching.push(combo);
    }
  }
  return result;
}
