export enum CardSizeEnum {
  default = "default",
  small = "small",
  large = "large",
}

export enum CardSuitEnum {
  c = "clubs",
  d = "diamonds",
  h = "hearts",
  s = "spades",
  empty = "empty",
  unknown = "unknown",
}

export enum ShortSuitEnum {
  clubs = "c",
  diamonds = "d",
  hearts = "h",
  spades = "s",
}
