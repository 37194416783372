import { defineComponent as _defineComponent } from 'vue'
import CardsDesignSelect from "@/components/settings/CardsDesignSelect.vue";
import AppButton from "@/components/common/app/AppButton.vue";
import type { RaiseColors } from "@/stores/account";
import { type ActionNameForColorSet, useAccountStore } from "@/stores/account";
import { computed, ref, watch } from "vue";
import ReachTransparencySelect from "@/components/settings/ReachTransparencySelect.vue";
import ActionColorPicker from "@/components/settings/ActionColorPicker.vue";
import MatrixComponent from "@/components/common/MatrixComponent.vue";
import {
  stateInfoForPreview,
  matrixDataForPreview,
  actionListingForPreview,
} from "@/constants/matrixDataForPreview";
import { allStartingHands } from "@/constants/allStartingHands";
import type { AccountSettings } from "@/types/account";
import { assignValues, deepEqual } from "@/utils/utilityFunctions";
import { generateRaiseColor, RaiseSize } from "@/features/action/actionColor";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppearanceSettings',
  setup(__props, { expose: __expose }) {
  __expose();

const accountStore = useAccountStore();
const hasResetBeenMade = ref<boolean>(false);
const selectedSettings = ref<AccountSettings>({
  cardsDesign: accountStore.settings.cardsDesign,
  reachTransparency: accountStore.settings.reachTransparency,
  actionColors: {
    fold: accountStore.settings.actionColors.fold,
    call: accountStore.settings.actionColors.call,
    check: accountStore.settings.actionColors.check,
    raise: {
      default: accountStore.settings.actionColors.raise.default,
      small: accountStore.settings.actionColors.raise.small,
      medium: accountStore.settings.actionColors.raise.medium,
      large: accountStore.settings.actionColors.raise.large,
      overbet: accountStore.settings.actionColors.raise.overbet,
    },
    allIn: accountStore.settings.actionColors.allIn,
  },
});
const isSaveDisabled = computed(() => {
  return deepEqual<AccountSettings>(
    selectedSettings.value,
    accountStore.settings
  );
});
const actionConfigs = computed(() => {
  return [
    {
      color: selectedSettings.value.actionColors.fold,
      actionLabel: "Fold",
      actionKey: "fold",
    },
    {
      color: selectedSettings.value.actionColors.call,
      actionLabel: "Call",
      actionKey: "call",
    },
    {
      color: selectedSettings.value.actionColors.check,
      actionLabel: "Check",
      actionKey: "check",
    },
    {
      color: selectedSettings.value.actionColors.raise.default,
      actionLabel: "Raise",
      withRaiseTooltip: true,
      actionKey: "raise.default",
    },
    {
      color: selectedSettings.value.actionColors.raise.small,
      actionLabel: "Small",
      isRectangle: true,
      actionKey: "raise.small",
    },
    {
      color: selectedSettings.value.actionColors.raise.medium,
      actionLabel: "Medium",
      isRectangle: true,
      actionKey: "raise.medium",
    },
    {
      color: selectedSettings.value.actionColors.raise.large,
      actionLabel: "Large",
      isRectangle: true,
      actionKey: "raise.large",
    },
    {
      color: selectedSettings.value.actionColors.raise.overbet,
      actionLabel: "Overbet",
      isRectangle: true,
      actionKey: "raise.overbet",
    },
    {
      color: selectedSettings.value.actionColors.allIn,
      actionLabel: "All-In",
      actionKey: "allIn",
    },
  ];
});

const setActionColor = (actionKey: ActionNameForColorSet, color: string) => {
  hasResetBeenMade.value = false;
  if (actionKey in selectedSettings.value.actionColors) {
    (selectedSettings.value.actionColors as any)[actionKey] = color;
  } else if (actionKey.split(".").length === 2) {
    const raiseSize = actionKey.split(".")[1];
    selectedSettings.value.actionColors.raise[raiseSize as keyof RaiseColors] =
      color;
  }
};
const setReachTransparency = (value: number) => {
  selectedSettings.value.reachTransparency = value;
};
const setSelectedCardsDesign = (cardsDesign: string) => {
  selectedSettings.value.cardsDesign = cardsDesign;
};
const saveChanges = () => {
  accountStore.setSettings(selectedSettings.value);
};
const resetSettingsToDefault = () => {
  hasResetBeenMade.value = true;
  accountStore.setDefaultSettings();
  assignValues<AccountSettings>(selectedSettings.value, accountStore.settings);
};

watch(
  () => selectedSettings.value.actionColors.raise.default,
  (newV) => {
    if (!hasResetBeenMade.value) {
      selectedSettings.value.actionColors.raise.small = newV;
      selectedSettings.value.actionColors.raise.medium = generateRaiseColor(
        newV,
        RaiseSize.medium
      );
      selectedSettings.value.actionColors.raise.large = generateRaiseColor(
        newV,
        RaiseSize.large
      );
      selectedSettings.value.actionColors.raise.overbet = generateRaiseColor(
        newV,
        RaiseSize.overbet
      );
    }
  }
);

const __returned__ = { accountStore, hasResetBeenMade, selectedSettings, isSaveDisabled, actionConfigs, setActionColor, setReachTransparency, setSelectedCardsDesign, saveChanges, resetSettingsToDefault, CardsDesignSelect, AppButton, get useAccountStore() { return useAccountStore }, computed, ref, watch, ReachTransparencySelect, ActionColorPicker, MatrixComponent, get stateInfoForPreview() { return stateInfoForPreview }, get matrixDataForPreview() { return matrixDataForPreview }, get actionListingForPreview() { return actionListingForPreview }, get allStartingHands() { return allStartingHands }, get assignValues() { return assignValues }, get deepEqual() { return deepEqual }, get generateRaiseColor() { return generateRaiseColor }, get RaiseSize() { return RaiseSize } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})