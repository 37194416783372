import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { computed } from "vue";
import { useAccountStore } from "@/stores/account";
import { getActionPercentage } from "@/utils/utilityFunctions";

interface Props {
  item?: string;
  withoutItem?: boolean;
  action?: string;
  isSingleMatrix?: boolean;
  actionOfCard?: string[];
  reach?: number;
  selected?: boolean;
  isCellHidden?: boolean;
  isCellUnavailable?: boolean;
  reachTransparencyForPreview?: number;
  isTextWithShadow?: boolean;
  isFullHeightCell?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MatrixCard',
  props: {
    item: {},
    withoutItem: { type: Boolean },
    action: {},
    isSingleMatrix: { type: Boolean },
    actionOfCard: {},
    reach: {},
    selected: { type: Boolean },
    isCellHidden: { type: Boolean },
    isCellUnavailable: { type: Boolean },
    reachTransparencyForPreview: {},
    isTextWithShadow: { type: Boolean },
    isFullHeightCell: { type: Boolean }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

_useCssVars(_ctx => ({
  "3c767a06": (getReachValue.value),
  "773f8123": (getReachTransparency.value)
}))

const props = __props;
const accountStore = useAccountStore();

const getReachValue = computed(() => {
  if (props.reach) {
    return (100 - getActionPercentage(props.reach)).toString() + "%";
  }
  return "0%";
});
const getReachTransparency = computed(() => {
  return typeof props.reachTransparencyForPreview === "number"
    ? `rgba(0, 0, 0, ${props.reachTransparencyForPreview})`
    : `rgba(0, 0, 0, ${accountStore.settings.reachTransparency})`;
});
const matrixCardClasses = () => {
  return {
    "text-with-shadow": props.isTextWithShadow,
    "cell--hidden": props.isCellHidden,
    "cell--selected": props.selected,
    "cell--disabled": props.isCellUnavailable,
    "cell--bigger-text": props.isSingleMatrix,
    "cell--full-height-cell": props.isFullHeightCell,
  };
};

const __returned__ = { props, accountStore, getReachValue, getReachTransparency, matrixCardClasses, computed, get useAccountStore() { return useAccountStore }, get getActionPercentage() { return getActionPercentage } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})