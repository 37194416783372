import { computed, ref, shallowRef } from "vue";
import { defineStore } from "pinia";
import { useParsedLibDataStore } from "@/stores/parsedLibData";
import { useGameStore } from "@/stores/game";
import pokerApi from "@/services/pokerApi";
import { PlayerList } from "@/entities/Player";
import { getMatrix } from "@/composables/useMatrixData";
import {
  getEmptyActions,
  getEmptyAction,
  getOpenAction,
  getSkippedAction,
  getTerminatedAction,
  getToActAction,
  isActionWithData,
  isEmptyAction,
  isFoldAction,
  isSkippedAction,
  isTerminatedAction,
  isUiEmptyAction,
  isToActAction,
} from "@/features/sequence/action";
import {
  findAndSetConnectionName,
  isStudGameCategory,
} from "@/features/gameSettings";
import { getSeqFromSeqStateReq } from "@/features/queryHandlers";
import { isFoldActionName } from "@/features/action/actionGeneral";
import { isRoundOrGameEnd } from "@/features/sequence/utils";
import { getPlayersWithAdditionalInfo } from "@/features/playersWithAdditionalInfo";

import { SeqActionStateEnum, SeqActionUiStateEnum } from "@/enums/sequenceEnum";

import type { SeqAction, SeqActionAny } from "@/types/seqAction";
import type {
  ActionListingData,
  ActionListingReqParams,
} from "@/types/actionListingJson";
import type {
  PerformActionParams,
  PreflopSequenceMapRecord,
  SequenceStoreInitParams,
} from "@/types/sequence";
import { useUpCards } from "@/composables/useUpCards";
import { isPlayerActive } from "@/features/pokerPlayers";
import { PlayerCardTypeEnum } from "@/enums/playerEnum";

export const useSequenceStore = defineStore(
  "sequence",
  () => {
    const seqDictionary = shallowRef<Record<string, PreflopSequenceMapRecord>>(
      {}
    );
    const sequence = ref<SeqActionAny[]>([]);
    const seqPointer = ref(-1);
    const playersNum = ref(0);
    const playerList = ref<PlayerList>();
    const isFetchingActionListing = ref(false);
    const isFetchingSeqMap = ref(false);

    const gameStore = useGameStore();
    const { setMatrixData, clearMatrixData } = useParsedLibDataStore();
    const { upCards, setUpCards, initUpCards, setPlayerListInitialUpCards } =
      useUpCards();

    const activePlayerUpCards = computed(() => {
      if (playersWithAdditionalInfo.value) {
        const activePlayer = playersWithAdditionalInfo.value.find((player) =>
          isPlayerActive(player)
        );
        if (activePlayer) {
          return activePlayer.cardList
            .filter((card) => card.type === PlayerCardTypeEnum.upCard)
            .map((card) => card.value);
        } else {
          return [];
        }
      } else {
        return [];
      }
    });
    const activeAction = computed(() => {
      return sequence.value[seqPointer.value] as SeqAction;
    });
    const numCompletedActions = computed(() => {
      return sequence.value.filter((action) => !isEmptyAction(action)).length;
    });
    const isAllowedNextAction = computed(() => {
      return seqPointer.value < numCompletedActions.value - 1;
    });
    const isAllowedPrevAction = computed(() => {
      return seqPointer.value > 0;
    });
    const isPreflopEnd = computed(() => {
      return isTerminatedAction(activeAction.value);
    });
    const playersWithAdditionalInfo = computed(() => {
      return getPlayersWithAdditionalInfo(
        playerList.value?.data,
        sequence.value,
        seqPointer.value
      );
    });
    const isStudGame = computed(() => {
      return isStudGameCategory(gameStore.configByConnectionName?.gameCategory);
    });

    const checkPreviousFoldOfPlayer = (
      actionOrder: number,
      numOfPlayers: number,
      arr: SeqActionAny[]
    ) => {
      const getPlayerPrevActions = (_: SeqActionAny, index: number) =>
        index % numOfPlayers === actionOrder % numOfPlayers;
      return arr.filter(getPlayerPrevActions).some(isFoldAction);
    };
    const shiftSeqPointerNext = () => {
      const currentAction = () => sequence.value[seqPointer.value];
      if (isActionWithData(activeAction.value)) {
        changeActionState(SeqActionStateEnum.performed);
      }
      seqPointer.value++;
      currentAction().uiState = SeqActionUiStateEnum.visible;
      if (isSkippedAction(activeAction.value)) {
        shiftSeqPointer();
      }
      if (isActionWithData(activeAction.value)) {
        changeActionState(SeqActionStateEnum.toAct);
      }
      if (isTerminatedAction(activeAction.value)) {
        changeActionState(SeqActionStateEnum.performed);
      }
    };
    const shiftSeqPointerPrev = () => {
      const currentAction = () => sequence.value[seqPointer.value];
      if (seqPointer.value < playersNum.value) {
        if (
          isActionWithData(activeAction.value) ||
          isTerminatedAction(activeAction.value)
        ) {
          changeActionState(SeqActionStateEnum.uiEmpty);
        }
        seqPointer.value--;
        if (isSkippedAction(activeAction.value)) {
          shiftSeqPointer(false);
        }
        if (isActionWithData(activeAction.value)) {
          changeActionState(SeqActionStateEnum.toAct);
        }
      } else {
        currentAction().uiState = SeqActionUiStateEnum.hidden;
        seqPointer.value--;
        if (isSkippedAction(activeAction.value)) {
          shiftSeqPointer(false);
        }
        if (isActionWithData(activeAction.value)) {
          changeActionState(SeqActionStateEnum.toAct);
        }
      }
    };
    const shiftSeqPointer = (isNext = true) => {
      clearMatrixData();
      if (isNext) {
        shiftSeqPointerNext();
      } else {
        shiftSeqPointerPrev();
      }
    };
    const setSeqAction = (action: SeqActionAny, idx = seqPointer.value) => {
      sequence.value[idx] = action;
    };
    const addActionToSeq = (action: SeqActionAny) => {
      sequence.value.push(action);
    };
    const changeActionName = (actionName: string) => {
      const currentAction = sequence.value[seqPointer.value];
      if (isActionWithData(currentAction)) {
        currentAction.chosenAction = actionName;
      }
    };
    const changeActionState = (actionState: SeqActionStateEnum) => {
      sequence.value[seqPointer.value].state = actionState;
    };
    const addActionToSeqAndShiftPointer = (action: SeqActionAny) => {
      addActionToSeq(action);
      shiftSeqPointer();
    };
    const shiftPointerAndSetAction = (action: SeqActionAny) => {
      setSeqAction(action, seqPointer.value + 1);
      shiftSeqPointer();
    };
    const handlePerformAction = (
      seqStr: string,
      data: ActionListingData | PreflopSequenceMapRecord
    ) => {
      if (seqPointer.value + 1 < playersNum.value) {
        shiftPointerAndSetAction(getToActAction(seqStr, data));
        return;
      }
      handleSkipActions();
      addActionToSeqAndShiftPointer(getToActAction(seqStr, data));
    };
    const performRegularAction = (seqStr: string) => {
      if (seqDictionary.value[seqStr]) {
        return handlePerformAction(seqStr, seqDictionary.value[seqStr]);
      } else {
        isFetchingActionListing.value = true;
        return getActionListing(seqStr, { upCards: upCards.value.join(",") })
          .then((data) => {
            handlePerformAction(seqStr, data);
          })
          .finally(() => {
            isFetchingActionListing.value = false;
          });
      }
    };
    const performActionByActionName = async ({
      actionName,
      idx = seqPointer.value,
      resetCurrentActionStateToToAct = false,
    }: PerformActionParams) => {
      clearMatrixData();
      if (isFetchingActionListing.value) {
        return;
      }
      if (resetCurrentActionStateToToAct || idx !== seqPointer.value) {
        // Action when breadcrumb clicked and Action from tooltip
        while (idx < seqPointer.value) {
          shiftSeqPointer(false);
        }
        cutSeqAndSetEmptyActions(idx);
      }

      const seqStr =
        seqForRequest.value === "open"
          ? actionName
          : seqForRequest.value + actionName;
      if (!resetCurrentActionStateToToAct) {
        changeActionName(actionName);
        changeActionState(SeqActionStateEnum.performed);
        if (isRoundOrGameEnd(actionName)) {
          handleSkipActions();
          if (seqPointer.value + 1 < playersNum.value) {
            shiftPointerAndSetAction(
              getTerminatedAction(seqStr, activeAction.value.stateInfo)
            );
          } else {
            addActionToSeqAndShiftPointer(
              getTerminatedAction(seqStr, activeAction.value.stateInfo)
            );
          }
        } else {
          await performRegularAction(seqStr);
        }
      }
    };
    const performAction = async ({
      actionName,
      idx = seqPointer.value,
      resetCurrentActionStateToToAct = false,
    }: PerformActionParams) => {
      const action = sequence.value[idx];
      if (seqPointer.value + 1 >= playersNum.value) {
        sequence.value.splice(seqPointer.value + 1);
      }
      if (isActionWithData(action) && !isUiEmptyAction(action)) {
        await performActionByActionName({
          actionName,
          idx,
          resetCurrentActionStateToToAct,
        });
      } else if (isEmptyAction(action) || isUiEmptyAction(action)) {
        for (let i = seqPointer.value; i < idx; i++) {
          await performActionByActionName({
            actionName:
              activeAction.value.actionsList.find((nameOfAction) =>
                isFoldActionName(nameOfAction)
              ) || "",
          });
        }
        cutSeqAndSetEmptyActions(idx);
      }
    };
    const handleSkipActions = () => {
      let nextPlayerIdx = seqPointer.value + 1;
      while (
        checkPreviousFoldOfPlayer(
          nextPlayerIdx,
          playersNum.value,
          sequence.value
        )
      ) {
        addActionToSeq(getSkippedAction());
        nextPlayerIdx++;
      }
    };
    const cutSeqAndSetEmptyActions = (actionIdx: number) => {
      sequence.value.splice(seqPointer.value + 1);
      if (sequence.value.length < playersNum.value) {
        for (let i = 0; i < playersNum.value - actionIdx - 1; i++) {
          addActionToSeq(getEmptyAction());
        }
      }
    };
    const setInitSeqState = (params?: SequenceStoreInitParams) => {
      clearMatrixData();
      if (gameStore.configByConnectionName) {
        const gameConfig = gameStore.configByConnectionName;
        const { numPlayers } = gameConfig;
        playersNum.value = numPlayers;
        playerList.value = new PlayerList(gameConfig);
        sequence.value = [];
        seqPointer.value = 0;
        if (params?.upCards && isStudGame.value) {
          initUpCards(params.upCards);
          playerList.value = setPlayerListInitialUpCards(
            upCards.value,
            playerList.value
          );
        } else {
          initUpCards();
        }
        if (!params?.seq) {
          setSeqAction(getOpenAction(seqDictionary.value.open));
          sequence.value.push(...getEmptyActions(numPlayers - 1));
        } else {
          sequence.value = [...params.seq];
          seqPointer.value = params.seq.findIndex(
            (action) => isToActAction(action) || isTerminatedAction(action)
          );
        }
      }
    };
    const initPreflopSeqStore = async (params?: SequenceStoreInitParams) => {
      if (!params?.seq) {
        await Promise.all([
          getActionListing("open", {
            upCards: params?.upCards?.join(","),
          }).then(() => {
            setInitSeqState(params);
          }),
          fetchSeqDict(),
        ]);
      } else {
        fetchSeqDict().then(() => {
          setInitSeqState(params);
        });
      }
    };
    const getActionListing = async (
      seq: string,
      reqParams?: ActionListingReqParams
    ) => {
      const params = {
        betSequence: seq,
        ...(isStudGame.value && {
          upCards: reqParams?.upCards ? reqParams?.upCards : undefined,
        }),
      };
      const res = await pokerApi.fetchActionListingJson(params.betSequence, {
        upCards: params.upCards,
      });
      seqDictionary.value[seq] = {
        actionsList: res.data.data.actionsList,
        stateInfo: res.data.data.stateInfo,
      };
      return res.data.data;
    };
    const fetchSeqDict = async () => {
      isFetchingSeqMap.value = true;
      pokerApi
        .fetchPreflopSequenceJsonMap()
        .then((res) => {
          seqDictionary.value = res.data.data;
        })
        .finally(() => {
          isFetchingSeqMap.value = true;
        });
    };
    const seqForRequest = computed(() => {
      return activeAction.value ? activeAction.value.sequence : "open";
    });
    const fetchAndSetMatrix = () => {
      const params = {
        betSequence: seqForRequest.value,
        ...(isStudGame.value && { upCards: upCards.value }),
      };
      getMatrix(params.betSequence, {
        upCards: params.upCards,
      }).then(() => {
        setMatrixData(true);
      });
    };
    const settingsChangeHandler = async (upCardsArr?: string[]) => {
      await findAndSetConnectionName(true);
      await initPreflopSeqStore({ upCards: upCardsArr });
    };
    const setSeqStateFromQuery = (
      seq: string,
      params?: { upCards?: string[] }
    ) => {
      if (seq !== "open") {
        pokerApi
          .fetchSeqStateHistory(seq, params)
          .then(async (res) => {
            const newSeq = getSeqFromSeqStateReq(res.data.data, {
              withSkippedAndEmptyActions: true,
              isPreflop: true,
            });
            await initPreflopSeqStore({ seq: newSeq, ...params });
          })
          .catch(async () => {
            // Case when we have a lot of upCards but we get here. Question: how we should set initial set for stud games?
            await initPreflopSeqStore();
            gameStore.updatePageQueries(seqForRequest.value);
          });
      } else {
        initPreflopSeqStore();
      }
    };

    return {
      activeAction,
      activePlayerUpCards,
      fetchAndSetMatrix,
      initPreflopSeqStore,
      isAllowedNextAction,
      isAllowedPrevAction,
      isFetchingActionListing,
      isFetchingSeqMap,
      isPreflopEnd,
      isStudGame,
      performAction,
      playersWithAdditionalInfo,
      seqForRequest,
      seqPointer,
      sequence,
      setInitSeqState,
      setSeqStateFromQuery,
      settingsChangeHandler,
      shiftSeqPointer,
      upCardsModule: ref({
        cards: upCards,
        setCards: setUpCards,
      }),
    };
  },
  {
    share: {
      enable: false,
    },
  }
);
