export enum PlayerStateEnum {
  active = "active",
  current = "current",
  folded = "folded",
  regular = "regular",
}
export enum PlayerCardTypeEnum {
  regular = "regular",
  upCard = "upCard",
}
export enum PlayerCardStateEnum {
  draft = "draft",
  confirmed = "confirmed",
}
