export interface Lang {
  label: string;
  value: string;
}

export const langList: Lang[] = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "Ukrainian",
    value: "uk",
  },
  {
    label: "Chinese",
    value: "zh",
  },
  {
    label: "Portugese",
    value: "pt",
  },
  {
    label: "Spanish",
    value: "es",
  },
  {
    label: "French",
    value: "fr",
  },
  {
    label: "Italian",
    value: "it",
  },
  {
    label: "Thai",
    value: "th",
  },
  {
    label: "Romanian",
    value: "ro",
  },
  {
    label: "Polish",
    value: "pl",
  },
  {
    label: "Hungarian",
    value: "hu",
  },
  {
    label: "Korean",
    value: "ko",
  },
  {
    label: "German",
    value: "de",
  },
  {
    label: "Japanese",
    value: "ja",
  },
  {
    label: "Dutch",
    value: "nl",
  },
  {
    label: "Russian",
    value: "ru",
  },
  {
    label: "Hindi",
    value: "hi",
  },
];
