import { defineStore } from "pinia";
import { computed, ref, shallowRef } from "vue";
import pokerApi from "@/services/pokerApi";
import { getMatrixParamsOnStudy } from "@/features/study";
import { useParsedLibDataStore } from "@/stores/parsedLibData";
import { useGameStore } from "@/stores/game";
import { getMatrix } from "@/composables/useMatrixData";
import { PokerActionsEnum } from "@/poker-query-lib/enums/poker-actions.enum";
import { SeqActionStateEnum, SeqActionUiStateEnum } from "@/enums/sequenceEnum";
import {
  getEmptyActions,
  getBoardCardsAction,
  getEmptyAction,
  getOpenAction,
  getSkippedAction,
  getTerminatedAction,
  getToActAction,
  isActionWithData,
  isBoardCardsAction,
  isEmptyAction,
  isPreflopAction,
  isRoundEndingAction,
  isSkippedAction,
  isTerminatedAction,
  isUiEmptyAction,
  isToActAction,
} from "@/features/sequence/action";
import {
  findAndSetConnectionName,
  isDrawGameCategory,
  isFlopGameCategory,
} from "@/features/gameSettings";
import { getSeqFromSeqStateReq } from "@/features/queryHandlers";
import {
  checkPreviousFoldOfPlayer,
  getNumFinishedRounds,
} from "@/features/sequence/utils";
import { useBoardCards } from "@/composables/useBoardCards";
import { getPlayersWithAdditionalInfo } from "@/features/playersWithAdditionalInfo";
import { isPlayerFolded } from "@/features/pokerPlayers";
import {
  isSeqWidgetCompoundActionSingle,
  isSeqWidgetStreetActionGroup,
} from "@/features/sequence/widget/guards";
import { useUpCards } from "@/composables/useUpCards";
import { PlayerList } from "@/entities/Player";
import { isActionListingResponse } from "@/utils/utilityFunctions";

import type {
  PerformActionParams,
  PreflopSequenceMapRecord,
  SequenceStoreInitParams,
} from "@/types/sequence";
import type {
  SeqAction,
  SeqActionAny,
  SeqActionBoardCards,
} from "@/types/seqAction";
import {
  isCheckActionName,
  isFoldActionName,
  isGameEndingActionName,
  isRoundEndingActionName,
} from "@/features/action/actionGeneral";
import type {
  ActionListingData,
  ActionListingReqParams,
} from "@/types/actionListingJson";
import { getSeqWithGroups } from "@/features/sequence/widget/groups";
import type {
  SeqWidgetCardWidgetMouseLeave,
  SeqWidgetCompoundActionSingleOrStreetActionGroup,
  SeqWidgetStreetActionGroup,
} from "@/types/seqWidget";
import { GameCategoryEnum } from "@/enums/gameEnum";

export const useStudySequenceStore = defineStore(
  "study-sequence",
  () => {
    const seqDictionary = shallowRef<Record<string, PreflopSequenceMapRecord>>(
      {}
    );
    const playersNum = ref(0);
    const playerList = ref<PlayerList>();
    const seqPointer = ref(-1);
    const sequence = ref<SeqActionAny[]>([]);
    const showStudyViewLoading = ref(false);
    const isFetchingActionListing = ref(false);
    const isFetchingSeqMap = ref(false);

    const gameStore = useGameStore();
    const { setMatrixData, clearMatrixData } = useParsedLibDataStore();
    const {
      boardCards,
      boardCardsModalHandler,
      cardsActionIdx,
      cardsModalIsDisabledConfirm,
      closeBoardCardsModal,
      getBoardCards,
      getBoardCardsForRequest,
      getBoardCardsFromSeq,
      initBoardCards,
      isOpenBoardCardsModal,
      maxNumTableCards,
      modalBoardCards,
      nonUnselectableCards,
      openBoardCardsModal,
      removeCardInBoardCardsModal,
      resetBoardCardsModal,
      selectCardInBoardCardsModal,
      setBoardCards,
      setCardsActionIdx,
      updateBoardCards,
    } = useBoardCards(seqPointer, sequence);
    const { upCards, setUpCards, initUpCards, setPlayerListInitialUpCards } =
      useUpCards();

    const isStudGame = computed(() => {
      return (
        gameStore.configByConnectionName?.gameCategory === GameCategoryEnum.stud
      );
    });
    const numCompletedActions = computed(() => {
      return sequence.value.filter((action) => !isEmptyAction(action)).length;
    });
    const isCallEnd = (actionName = activeAction.value.chosenAction) => {
      return [
        `${PokerActionsEnum.CALL}/`,
        `${PokerActionsEnum.CALL}/$`,
      ].includes(actionName);
    };
    const isGameEnd = computed(() => {
      return isTerminatedAction(activeAction.value);
    });
    const isRoundEnd = computed(() => {
      return isRoundEndingAction(activeAction.value);
    });
    const activeAction = computed(() => {
      return sequence.value[seqPointer.value] as SeqAction;
    });
    const seqForRequest = computed(() => {
      return activeAction.value ? activeAction.value.sequence : "open";
    });
    const isCurrentActionOnPreflop = computed(() => {
      return isPreflopAction(seqPointer.value, sequence.value);
    });
    const seqPlayerIdx = computed(() => {
      return seqPointer.value % playersNum.value;
    });
    const isAllowedNextAction = computed(() => {
      return seqPointer.value < numCompletedActions.value - 1;
    });
    const isAllowedPrevAction = computed(() => {
      return seqPointer.value > 0;
    });
    const playersWithAdditionalInfo = computed(() => {
      return getPlayersWithAdditionalInfo(
        playerList.value?.data,
        sequence.value,
        seqPointer.value
      );
    });
    const activePlayersNum = computed(() => {
      return playersWithAdditionalInfo.value
        ? playersWithAdditionalInfo.value.filter(
            (player) => !isPlayerFolded(player)
          ).length
        : 0;
    });
    const playersNumInRound = computed(() => {
      const arr = getSeqWithGroups(sequence.value);
      const getFirstActionInGroup = (idx: number) => {
        const activeItem = arr.find(
          (item) =>
            (isSeqWidgetStreetActionGroup(item) &&
              item.data.some((action) => action.order === idx)) ||
            (isSeqWidgetCompoundActionSingle(item) && item.data.order === idx)
        ) as SeqWidgetCompoundActionSingleOrStreetActionGroup;
        if (activeItem && isSeqWidgetStreetActionGroup(activeItem)) {
          return activeItem.data[0].seqAction as SeqAction;
        } else if (activeItem && isSeqWidgetCompoundActionSingle(activeItem)) {
          const activeItemIdx = arr.findIndex((item) => item === activeItem);
          for (let i = activeItemIdx; i < arr.length; i++) {
            const arrItem = arr[i];
            if (isSeqWidgetStreetActionGroup(arrItem)) {
              return arrItem.data[0].seqAction as SeqAction;
            }
          }
          return null;
        } else {
          return null;
        }
      };
      const firstActionInGroup = getFirstActionInGroup(seqPointer.value);
      return firstActionInGroup
        ? firstActionInGroup.stateInfo.folded.filter((item) => !item).length
        : playersNum.value;
    });
    const setSeqAction = (action: SeqActionAny, idx = seqPointer.value) => {
      sequence.value[idx] = action;
    };
    const addActionToSeq = (action: SeqActionAny) => {
      sequence.value.push(action);
    };
    const changeActionName = (newActionName: string) => {
      const currentAction = sequence.value[seqPointer.value];
      if (isActionWithData(currentAction)) {
        currentAction.chosenAction = newActionName;
      }
    };
    const changeActionState = (state: SeqActionStateEnum) => {
      sequence.value[seqPointer.value].state = state;
    };
    const addActionToSeqAndShiftPointer = (action: SeqActionAny) => {
      addActionToSeq(action);
      shiftSeqPointer();
    };
    const shiftPointerAndSetAction = (action: SeqActionAny) => {
      setSeqAction(action, seqPointer.value + 1);
      shiftSeqPointer();
    };
    // Perform action handlers ↓ ↓ ↓
    const isActionOnFirstLevel = computed(() => {
      const arr = getSeqWithGroups(sequence.value);
      const activeGroup = arr.find(
        (item) =>
          isSeqWidgetStreetActionGroup(item) &&
          item.data.some((action) => action.order === seqPointer.value)
      ) as SeqWidgetStreetActionGroup;
      if (activeGroup) {
        const firstActionInGroupOrder = activeGroup.data[0].order;
        return (
          seqPointer.value - firstActionInGroupOrder < playersNumInRound.value
        );
      } else {
        return false;
      }
    });
    const isFirstActionOnFirstLevel = computed(() => {
      const arr = getSeqWithGroups(sequence.value);
      const activeGroup = arr.find(
        (item) =>
          isSeqWidgetStreetActionGroup(item) &&
          item.data.some((action) => action.order === seqPointer.value)
      ) as SeqWidgetStreetActionGroup;
      if (activeGroup) {
        const firstActionInGroupOrder = activeGroup.data[0].order;
        return seqPointer.value === firstActionInGroupOrder;
      } else {
        return false;
      }
    });
    const handlePerformAction = (
      seqStr: string,
      data: ActionListingData | PreflopSequenceMapRecord
    ) => {
      if (isActionListingResponse(data) && data.isDiscardAction) {
        addActionToSeqAndShiftPointer(getToActAction(seqStr, data));
      } else {
        if (isActionOnFirstLevel.value) {
          shiftPointerAndSetAction(getToActAction(seqStr, data));
          return;
        }

        handleSkipActions();

        addActionToSeqAndShiftPointer(getToActAction(seqStr, data));
      }
    };
    const performRegularAction = async (seqStr: string) => {
      if (seqDictionary.value[seqStr]) {
        handlePerformAction(seqStr, seqDictionary.value[seqStr]);
        return Promise.resolve();
      } else {
        isFetchingActionListing.value = true;
        return getActionListing(seqStr, {
          upCards: upCards.value.join(","),
        }).then((data) => {
          handlePerformAction(seqStr, data);
        });
      }
    };
    const performRoundAction = async (
      seqStr: string,
      idx: number,
      actionName: string
    ) => {
      if (isDrawGameCategory(gameStore.configByConnectionName?.gameCategory)) {
        await performRegularAction(seqStr);
      } else if (
        isFlopGameCategory(gameStore.configByConnectionName?.gameCategory)
      ) {
        openBoardCardsModal(idx + 1, actionName);
        changeActionName(actionName);
      }
    };
    const performEndAction = async (seqStr: string) => {
      if (isActionOnFirstLevel.value) {
        shiftPointerAndSetAction(
          getTerminatedAction(seqStr, activeAction.value.stateInfo)
        );
      } else {
        handleSkipActions();
        addActionToSeqAndShiftPointer(
          getTerminatedAction(seqStr, activeAction.value.stateInfo)
        );
      }
    };
    // Perform action handlers ↑ ↑ ↑
    const shiftSeqPointerNext = () => {
      const currentAction = () => sequence.value[seqPointer.value];
      if (isActionWithData(activeAction.value)) {
        changeActionState(SeqActionStateEnum.performed);
      }
      seqPointer.value++;
      currentAction().uiState = SeqActionUiStateEnum.visible;
      if (
        isSkippedAction(activeAction.value) ||
        isBoardCardsAction(activeAction.value)
      ) {
        if (isBoardCardsAction(activeAction.value)) {
          sequence.value
            .slice(
              seqPointer.value + 1,
              seqPointer.value + 1 + playersNumInRound.value
            )
            .forEach(
              (action) => (action.uiState = SeqActionUiStateEnum.visible)
            );
        }
        shiftSeqPointer();
      }
      if (isActionWithData(activeAction.value)) {
        changeActionState(SeqActionStateEnum.toAct);
      }
      if (isTerminatedAction(activeAction.value)) {
        changeActionState(SeqActionStateEnum.performed);
      }
    };
    const shiftSeqPointerPrev = () => {
      const currentAction = () => sequence.value[seqPointer.value];
      if (isActionOnFirstLevel.value && !isFirstActionOnFirstLevel.value) {
        if (
          isActionWithData(activeAction.value) ||
          isTerminatedAction(activeAction.value)
        ) {
          changeActionState(SeqActionStateEnum.uiEmpty);
        }
        seqPointer.value--;
        if (isSkippedAction(activeAction.value)) {
          shiftSeqPointer(false);
        }
        if (isActionWithData(activeAction.value)) {
          changeActionState(SeqActionStateEnum.toAct);
        }
      } else {
        currentAction().uiState = SeqActionUiStateEnum.hidden;
        seqPointer.value--;
        if (
          isSkippedAction(activeAction.value) ||
          isBoardCardsAction(activeAction.value)
        ) {
          if (isBoardCardsAction(activeAction.value)) {
            for (let i = seqPointer.value; i < sequence.value.length; i++) {
              sequence.value[i].uiState = SeqActionUiStateEnum.hidden;
            }
          }
          shiftSeqPointer(false);
        }
        if (isActionWithData(activeAction.value)) {
          changeActionState(SeqActionStateEnum.toAct);
        }
      }
    };
    const shiftSeqPointer = (isNext = true) => {
      clearMatrixData();
      if (isNext) {
        shiftSeqPointerNext();
      } else {
        shiftSeqPointerPrev();
      }
    };
    const handleSkipActions = () => {
      let nextPlayerIdx = seqPointer.value + 1;
      while (
        checkPreviousFoldOfPlayer(
          nextPlayerIdx,
          playersNum.value,
          sequence.value
        )
      ) {
        addActionToSeq(getSkippedAction());
        nextPlayerIdx++;
      }
    };
    const handleBoardCardsModalClose = () => {
      closeBoardCardsModal();
      changeActionState(SeqActionStateEnum.toAct);
    };
    const handleBoardCardsModalConfirm = () => {
      clearMatrixData();
      boardCardsModalHandler();
      closeBoardCardsModal();
      while (cardsActionIdx.value < seqPointer.value) {
        shiftSeqPointer(false);
      }
      sequence.value.splice(cardsActionIdx.value);
      addActionToSeq(getBoardCardsAction(boardCards.value));
      const activeActionSequence =
        activeAction.value.sequence + activeAction.value.chosenAction;
      getActionListing(activeActionSequence, {
        boardCards: boardCards.value.join(""),
      }).then((data) => {
        addActionToSeqAndShiftPointer(
          getToActAction(activeActionSequence, data)
        );
        sequence.value.push(...getEmptyActions(activePlayersNum.value - 1));
      });
    };
    const handleCardWidgetSelect = (data: SeqWidgetCardWidgetMouseLeave) => {
      const boardCardsAction = sequence.value[
        data.order
      ] as SeqActionBoardCards;
      boardCardsAction.boardCards[boardCardsAction.boardCards.length - 1] =
        data.card;
      setBoardCards(boardCardsAction.boardCards);
      setCardsActionIdx(data.order);
      handleBoardCardsModalConfirm();
    };
    const performActionByActionName = async ({
      actionName,
      resetCurrentActionStateToToAct = false,
      idx = seqPointer.value,
    }: PerformActionParams) => {
      clearMatrixData();
      if (isFetchingActionListing.value) {
        return;
      }
      if (resetCurrentActionStateToToAct || idx !== seqPointer.value) {
        // Action when breadcrumb clicked and Action from tooltip
        while (idx < seqPointer.value) {
          shiftSeqPointer(false);
        }
        updateBoardCards(idx, resetCurrentActionStateToToAct);
        cutSeqAndSetEmptyActions();
      }

      const seqStr =
        seqForRequest.value === "open"
          ? actionName
          : seqForRequest.value + actionName;
      if (!resetCurrentActionStateToToAct) {
        changeActionName(actionName);
        changeActionState(SeqActionStateEnum.performed);
        if (isRoundEndingActionName(actionName)) {
          await performRoundAction(seqStr, idx, actionName);
        } else if (isGameEndingActionName(actionName)) {
          await performEndAction(seqStr);
        } else {
          await performRegularAction(seqStr);
          cutSeqAndSetEmptyActions();
        }
      }
    };
    const performAction = async ({
      actionName,
      idx = seqPointer.value,
      resetCurrentActionStateToToAct = false,
    }: PerformActionParams) => {
      const action = sequence.value[idx];
      if (isActionWithData(action) && !isUiEmptyAction(action)) {
        await performActionByActionName({
          actionName,
          idx,
          resetCurrentActionStateToToAct,
        });
      } else if (isEmptyAction(action) || isUiEmptyAction(action)) {
        for (let i = seqPointer.value; i < idx; i++) {
          await performActionByActionName({
            actionName:
              activeAction.value.actionsList.find(
                (actionNameFromList) =>
                  isFoldActionName(actionNameFromList) ||
                  isCheckActionName(actionNameFromList)
              ) || "",
          });
        }
      }
    };
    const setInitSeqState = (params?: SequenceStoreInitParams) => {
      clearMatrixData();
      if (gameStore.configByConnectionName) {
        const gameConfig = gameStore.configByConnectionName;
        const { numPlayers } = gameConfig;
        playersNum.value = numPlayers;
        playerList.value = new PlayerList(gameConfig);
        sequence.value = [];
        seqPointer.value = 0;
        if (params?.upCards && isStudGame.value) {
          initUpCards(params.upCards);
          playerList.value = setPlayerListInitialUpCards(
            upCards.value,
            playerList.value
          );
        } else {
          initUpCards();
        }
        if (!params?.seq) {
          setSeqAction(getOpenAction(seqDictionary.value.open));
          sequence.value.push(...getEmptyActions(numPlayers - 1));
          initBoardCards();
        } else {
          sequence.value = [...params.seq];
          seqPointer.value = params.seq.findIndex(
            (action) => isToActAction(action) || isTerminatedAction(action)
          );
          const cards = getBoardCards();
          initBoardCards(cards);
        }
      }
    };
    const initStudySeqStore = async (params?: SequenceStoreInitParams) => {
      if (!params?.seq) {
        await Promise.all([
          getActionListing("open", {
            upCards: params?.upCards?.join(","),
          }).then(() => {
            setInitSeqState(params);
          }),
          fetchSeqDict(),
        ]);
      } else if (params.seq && params?.seqStr) {
        getActionListing(params.seqStr, {
          boardCards: getBoardCardsFromSeq(params.seq)?.join(""),
          upCards: params?.upCards?.join(","),
        }).then(() => {
          fetchSeqDict();
          setInitSeqState(params);
        });
      }
    };
    const getActionListing = (
      seqStr: string,
      reqParams?: ActionListingReqParams
    ) => {
      const params = {
        boardCards: reqParams?.boardCards
          ? reqParams.boardCards
          : getBoardCardsForRequest().join(""),
        upCards: reqParams?.upCards ? reqParams?.upCards : undefined,
      };
      isFetchingActionListing.value = true;
      return pokerApi
        .fetchActionListingJson(seqStr, params)
        .then((res) => {
          seqDictionary.value[seqStr] = {
            actionsList: res.data.data.actionsList,
            stateInfo: res.data.data.stateInfo,
          };
          return res.data.data;
        })
        .finally(() => {
          isFetchingActionListing.value = false;
        });
    };
    const cutSeqAndSetEmptyActions = () => {
      sequence.value.splice(seqPointer.value + 1);
      if (isActionOnFirstLevel.value) {
        const seqArr = getSeqWithGroups(sequence.value);
        const activeGroup = seqArr.find(
          (item) =>
            isSeqWidgetStreetActionGroup(item) &&
            item.data.some((action) => action.order === seqPointer.value)
        ) as SeqWidgetStreetActionGroup;
        const firstLevelInGroup = activeGroup.data.slice(
          0,
          playersNumInRound.value
        );
        const indexOfActiveItem = firstLevelInGroup.findIndex(
          (item) => item.order === seqPointer.value
        );
        const amountOfEmpties =
          playersNumInRound.value - (indexOfActiveItem + 1);
        for (let i = 0; i < amountOfEmpties; i++) {
          addActionToSeq(getEmptyAction());
        }
      }
    };
    const fetchSeqDict = async () => {
      isFetchingSeqMap.value = true;
      pokerApi
        .fetchPreflopSequenceJsonMap()
        .then((res) => {
          seqDictionary.value = res.data.data;
        })
        .finally(() => {
          isFetchingSeqMap.value = true;
        });
    };
    const fetchAndSetMatrix = () => {
      if (gameStore.connectionName) {
        showStudyViewLoading.value = true;
        const numFinishedRounds = getNumFinishedRounds(
          seqPointer.value,
          sequence.value
        );
        const params = getMatrixParamsOnStudy(
          activeAction.value.sequence,
          numFinishedRounds,
          boardCards.value,
          upCards.value
        );
        getMatrix(...params)
          .then(() => {
            setMatrixData(true);
          })
          .finally(() => {
            showStudyViewLoading.value = false;
          });
      }
    };
    const settingsChangeHandler = async (upCardsArr?: string[]) => {
      await findAndSetConnectionName(false);
      await initStudySeqStore({ upCards: upCardsArr });
    };
    const setSeqStateFromQuery = (
      seq: string,
      options?: {
        boardCards?: string[];
        upCards?: string[];
      }
    ) => {
      if (seq !== "open") {
        pokerApi
          .fetchSeqStateHistory(seq, { upCards: options?.upCards })
          .then(async (res) => {
            const sequenceFromReq = getSeqFromSeqStateReq(res.data.data, {
              boardCards: options?.boardCards,
              withSkippedAndEmptyActions: true,
            });
            const lastActionWithDataInSeq = sequenceFromReq.find((action) =>
              isToActAction(action)
            ) as SeqAction;
            await initStudySeqStore({
              seq: sequenceFromReq,
              seqStr: lastActionWithDataInSeq.sequence,
              upCards: options?.upCards,
            });
          })
          .catch(async () => {
            // Case when we have a lot of upCards but we get here. Question: how we should set initial set for stud games?
            await initStudySeqStore();
            gameStore.updatePageQueries(seqForRequest.value);
          });
      } else {
        initStudySeqStore();
      }
    };

    return {
      activeAction,
      fetchAndSetMatrix,
      initStudySeqStore,
      isActionOnFirstLevel,
      isAllowedNextAction,
      isAllowedPrevAction,
      isCallEnd,
      isCurrentActionOnPreflop,
      isFetchingActionListing,
      isGameEnd,
      isRoundEnd,
      performAction,
      playerList,
      playersNumInRound,
      playersWithAdditionalInfo,
      seqForRequest,
      seqPlayerIdx,
      seqPointer,
      sequence,
      setInitSeqState,
      setSeqStateFromQuery,
      settingsChangeHandler,
      shiftSeqPointer,
      showStudyViewLoading,
      boardCardsModule: ref({
        cards: boardCards,
        maxNumTableCards,
        nonUnselectableCards,
        changeCardWithCardWidget: handleCardWidgetSelect,
        cardsModal: {
          cards: modalBoardCards,
          isOpen: isOpenBoardCardsModal,
          isDisabledConfirm: cardsModalIsDisabledConfirm,
          open: openBoardCardsModal,
          close: handleBoardCardsModalClose,
          confirm: handleBoardCardsModalConfirm,
          reset: resetBoardCardsModal,
          removeCard: removeCardInBoardCardsModal,
          selectCard: selectCardInBoardCardsModal,
        },
      }),
      upCardsModule: ref({
        cards: upCards,
        setCards: setUpCards,
      }),
    };
  },
  {
    share: {
      enable: false,
    },
  }
);
