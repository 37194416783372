import { createI18n } from "vue-i18n";
import { nextTick } from "vue";
import enMessages from "./locales/en.json";

const i18n = createI18n({
  legacy: false,
  locale: "en",
  fallbackLocale: "en",
  messages: {
    en: enMessages,
  },
});

export const setLocale = (locale: any) => {
  i18n.global.locale.value = locale;
  document.querySelector("html")?.setAttribute("lang", locale);
};

export const loadLocaleMessages = async (locale: string) => {
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
  );

  i18n.global.setLocaleMessage(locale, messages.default);
  setLocale(locale);

  return nextTick();
};

export default i18n;
