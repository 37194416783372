import * as Zstd from "fzstd";
import { ChunkType } from "./ParseQueryV2";

/**
 * Common interface for reading streams of bytes.
 *
 * Both the old v1 and new v2 query parsers will read bytes from
 * one of these.
 *
 * The bytes that we're reading might come from a few sources, each
 * implementation of IUorgByteReader will let us read from a different
 * one.
 *
 *   * UorgStreamReader
 *          Wraps an input stream for a query response,
 *          this is the most common case.
 *
 *   * UorgArrayByteReader
 *          Wraps an array of bytes. When parsing a compressed zstd chunk,
 *          in a v2 query, we'll want to read a new chunk from the
 *          decompressed bytes, so we'll wrap that with:
 *
 * The last two might not be very relevant.
 * They're only for the *worse* "zstd_stream" mode.
 *
 *  * UorgChunkReader
 *      Wraps a UorgStreamReader, reads out ZDAT chunks. And let's us
 *      read just the data portions of these chunks, stripping off the
 *      headers. It'll end once we hit the ZEND chunk
 *
 *  * UorgChunkReaderZstdDecompress
 *      Wraps UorgChunkReader, and hands the data to a zstd decompression
 *      stream. This will let us read bytes from that decompressed stream!
 *
 * This is a lot of effort, and seems to perform worse than the much simpler ZSTD
 * reply format.
 *
 */

export interface IUorgByteReader {
  readBytes(n: number): Promise<Uint8Array>;
  bytesRead: number;

  readIntLE(): Promise<number>;
}

/**
 * Wrap an array of bytes so we can read lines out as well as binary chunks
 */
export class UorgArrayByteReader implements IUorgByteReader {
  bytes: Uint8Array;
  bytesRead: number;
  decoder = new TextDecoder();

  constructor(buffer: Uint8Array) {
    this.bytes = buffer;
    this.bytesRead = 0;
  }

  readLine(): string {
    const newLineOffset = this.bytes
      .slice(this.bytesRead)
      .findIndex((val: number) => val === 10);
    const slice = this.bytes.slice(
      this.bytesRead,
      this.bytesRead + newLineOffset
    );
    this.bytesRead += newLineOffset + 1; // this should skip over '\n'
    return this.decoder.decode(slice);
  }

  async readBytes(n: number): Promise<Uint8Array> {
    const slice = this.bytes.slice(this.bytesRead, this.bytesRead + n);
    this.bytesRead += n;
    return slice;
  }

  async readIntLE(): Promise<number> {
    const bytes = await this.readBytes(4);
    const dataView = new DataView(bytes.buffer, bytes.byteOffset);
    return dataView.getInt32(0, true);
  }

  /* 
    previewBytes(n: number) : number[] {
        const save = this.bytesRead;
        const result = this.readBytesToArray(n)
        this.bytesRead = save
        return result
    }*/
}

/**
 * Wraps a ReadableStreamReader<Uint8Array> so we can read lines of text, arrays of floats,
 *  and other things when parsing a DecisionStrategy returned from the server
 */
export class UorgStreamReader implements IUorgByteReader {
  allocatedBufferForReturning = new Uint8Array(new ArrayBuffer(8192));
  processingBytes = new Uint8Array(4);
  decoder = new TextDecoder();

  reader: ReadableStreamDefaultReader<Uint8Array>;

  private done = false;

  currentChunk: Uint8Array | undefined;
  index = 0;
  bytesRead = 0;

  constructor(stream: ReadableStreamDefaultReader<Uint8Array>) {
    this.reader = stream;
    this.done = false;
  }

  async updateChunk() {
    if (this.needsMoreData()) {
      this.currentChunk = undefined as Uint8Array | undefined;
      if (!this.done) {
        const { done, value } = await this.reader.read();
        this.done = done;
        this.currentChunk = value;
        this.index = 0;
      }
    }
  }

  async isDone(): Promise<boolean> {
    await this.updateChunk();
    return this.done;
  }

  needsMoreData(): boolean {
    return this.remainingInChunk() <= 0;
  }

  remainingInChunk(): number {
    if (this.currentChunk) {
      return this.currentChunk.byteLength - this.index;
    }
    return 0;
  }

  async readLine(): Promise<string> {
    await this.updateChunk();
    if (this.currentChunk) {
      const newLineOffset = this.currentChunk
        .slice(this.index)
        .findIndex((val: number) => val === 10);
      if (newLineOffset >= 0) {
        const slice = this.currentChunk.slice(
          this.index,
          this.index + newLineOffset
        );
        this.index += newLineOffset + 1; // this should skip over '\n'
        return this.decoder.decode(slice);
      } else {
        const partial = this.decoder.decode(
          this.currentChunk.slice(this.index)
        );
        this.currentChunk = undefined as Uint8Array | undefined;
        const rest = await this.readLine();
        return partial + rest;
      }
    }

    throw new Error("End of stream");
  }

  async handleBufferOverflow(toCopy: Uint8Array, read: number): Promise<void> {
    if (
      read + toCopy.byteLength + 10 >
      this.allocatedBufferForReturning.byteLength
    ) {
      throw new Error("Oh no! Buffer is too big!");
    }
  }

  async readBytes(n: number): Promise<Uint8Array> {
    if (n === 0) {
      return new Uint8Array(0);
    }

    await this.updateChunk();
    if (this.currentChunk) {
      if (n <= this.remainingInChunk()) {
        const slice = this.currentChunk.slice(this.index, this.index + n);
        this.index += n;
        this.bytesRead += n;
        return slice;
      } else {
        if (n > this.allocatedBufferForReturning.byteLength) {
          this.allocatedBufferForReturning = new Uint8Array(n * 1.1);
        }

        let read = 0;
        while (read < n && this.currentChunk) {
          //Read all of current chunk
          const leftToRead = n - read;
          const end = Math.min(
            leftToRead + this.index,
            this.currentChunk.byteLength
          );
          const toCopy = this.currentChunk.subarray(this.index, end);
          this.handleBufferOverflow(toCopy, read);
          this.allocatedBufferForReturning.set(toCopy, read);
          read += end - this.index;
          this.index += end - this.index;

          await this.updateChunk();
        }
        this.bytesRead += n;
        return this.allocatedBufferForReturning.slice(0, n);
      }
    }
    throw new Error("End of stream!");
  }

  async readByte(): Promise<number> {
    const array = await this.readBytes(1);
    return array[0];
  }

  async readIntLE(): Promise<number> {
    const bytesValue = await this.readBytes(4);
    const dataView = new DataView(bytesValue.buffer, bytesValue.byteOffset);
    return dataView.getInt32(0, true);
  }

  async readFloat32Array(n: number): Promise<number[]> {
    const bytes = await this.readBytes(4 * n);
    const dataView = new DataView(bytes.buffer, bytes.byteOffset);
    const result = [];
    for (let i = 0; i < n; i++) {
      result.push(dataView.getFloat32(i * 4, true));
    }

    return result;
  }

  async readBytesToArray(n: number): Promise<number[]> {
    const bytes = await this.readBytes(n);
    return Array.from(bytes);
  }

  async readScaledArray(
    probMultiplier: number,
    length: number
  ): Promise<number[]> {
    let result: number[] = [];
    if (probMultiplier !== 100) {
      result = await this.readFloat32Array(length);
      result = result.map((x) => x / probMultiplier);
    } else {
      result = await this.readBytesToArray(length);
    }

    return result;
  }

  async readScaledNumber(probMultiplier: number): Promise<number> {
    const array = await this.readScaledArray(probMultiplier, 1);
    return array[0];
  }

  async checkHeader(n: number): Promise<number[]> {
    if (this.index === 0) {
      const save = this.index;
      const savedByteCount = this.bytesRead;
      const result = await this.readBytesToArray(n);
      this.index = save;
      this.bytesRead = savedByteCount;
      return result;
    } else {
      throw Error(
        "Should only call check header as first thing we do with stream!"
      );
    }
  }
}

///////////////////////////////////////////////////////////////////////
// These are for the ZSTD_STREAM mode. No need to worry about them!
//  The regular ZSTD mode will be better!

/**
 * Wraps a UorgStreamReader, and reads out chunks that are marked as 'ZSTR'
 */
export class UorgChunkReader implements IUorgByteReader {
  reader: UorgStreamReader;
  bytesRead = 0;

  currentChunk: Uint8Array | undefined;
  offset = 0;
  currentSize = 0;

  constructor(base: UorgStreamReader) {
    this.reader = base;
  }

  async readBytes(n: number): Promise<Uint8Array> {
    let partial = undefined as Uint8Array | undefined;
    let read = 0;
    if (this.currentChunk && this.offset < this.currentSize) {
      // If we have enough data remaining!
      if (this.offset + n <= this.currentSize) {
        partial = this.currentChunk.slice(this.offset, this.offset + n);
        this.offset += n;
        return partial;
      }
      // Otherwise, read whatever is left, and then we'll have to fetch some more!
      else {
        partial = this.currentChunk.slice(this.offset);
        read = this.currentSize - this.offset;
      }
    }

    await this.fetchChunk();

    const remaining = await this.readBytes(n - read);
    if (partial) {
      const result = new Uint8Array(partial.byteLength + remaining.byteLength);
      result.set(partial);
      result.set(remaining, partial.length);
      return result;
    }
    return remaining;
  }

  async fetchChunk() {
    /*const header =*/ await this.reader.readIntLE(); //should check that header is of expected types!
    const size = await this.reader.readIntLE();
    this.currentChunk = await this.reader.readBytes(size);
    this.offset = 0;
    this.currentSize = size;
  }

  async readIntLE(): Promise<number> {
    const bytesVal = await this.readBytes(4);
    const dataView = new DataView(bytesVal.buffer, bytesVal.byteOffset);
    return dataView.getInt32(0, true);
  }
}

/**
 * Wraps a UorgStreamReader, reads chunk and DECOMPRESSES as a zstd stream
 */
export class UorgChunkReaderZstdDecompress implements IUorgByteReader {
  reader: UorgStreamReader;
  bytesRead = 0;

  currentChunk: Uint8Array | undefined;
  chunkQueue: Array<Uint8Array> = [];
  offset = 0;

  decompressor: Zstd.Decompress;
  isDone: boolean;

  constructor(base: UorgStreamReader) {
    this.isDone = false;
    this.reader = base;
    this.decompressor = new Zstd.Decompress((data, final) =>
      this.zstdCallback(data, final)
    );
  }

  zstdCallback(data: Uint8Array, final = false) {
    if (data.byteLength > 0) {
      this.chunkQueue.push(data);
    }
    this.isDone = final;
  }

  async readBytes(n: number): Promise<Uint8Array> {
    // Special case where we can fill the entire request using just the current chunk
    if (this.currentChunk && this.offset + n < this.currentChunk.byteLength) {
      const res = this.currentChunk.slice(this.offset, this.offset + n);
      this.offset += n;
      return res;
    }

    // Otherwise,
    const result = new Uint8Array(n);
    let read = 0;
    while (read < n) {
      if (!this.currentChunk) {
        await this.nextChunk();
      } else {
        const remaining = n - read;
        if (this.offset + remaining < this.currentChunk.byteLength) {
          result.set(this.currentChunk.slice(this.offset, remaining), read);
          read += remaining;
          this.offset += remaining;
        } else if (this.currentChunk.byteLength - this.offset > 0) {
          const available = this.currentChunk.byteLength - this.offset;
          result.set(this.currentChunk.slice(this.offset, available), read);
          this.currentChunk = undefined as Uint8Array | undefined;
          read += available;
          this.offset += available;
        } else {
          this.currentChunk = undefined as Uint8Array | undefined;
        }
      }
    }

    // Try to preload whatever's next, this way we'll know if we EOF!
    if (!this.currentChunk && !this.isEOF()) {
      await this.nextChunk();
    }

    return result;
  }

  async nextChunk() {
    while (this.chunkQueue.length === 0 && !this.isDone) {
      await this.readNextChunkFromBaseStream();
    }

    this.currentChunk = this.chunkQueue.shift();
    this.offset = 0;
  }

  isEOF(): boolean {
    return this.isDone && this.currentChunk === undefined;
  }

  async readNextChunkFromBaseStream() {
    if (!this.isDone) {
      const header = await this.reader.readIntLE();
      const size = await this.reader.readIntLE();
      const compressed = await this.reader.readBytes(size);

      if (header === ChunkType.ZstdStreamData && size > 0) {
        this.decompressor.push(compressed);
      } else if (header === ChunkType.ZstdEndStream) {
        this.decompressor.push(new Uint8Array(0), true);
        this.isDone = true;
      } else {
        let str = "";
        str += `${(header >>> 24) & 0xff},`;
        str += `${(header >>> 16) & 0xff},`;
        str += `${(header >>> 8) & 0xff},`;
        str += `${header & 0xff}`;
        throw new Error(
          `Unexpected header in zstd stream handler: ${header}[${str}]`
        );
      }
    }
  }

  async readIntLE(): Promise<number> {
    const fourBytes = await this.readBytes(4);
    const dataView = new DataView(fourBytes.buffer, fourBytes.byteOffset);
    return dataView.getInt32(0, true);
  }
}
