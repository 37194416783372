import { defineComponent as _defineComponent } from 'vue'
import HeaderComponent from "@/components/layout/HeaderComponent.vue";
import { useUserStore } from "@/stores/user";
import { loadLocaleMessages } from "@/i18n";
import { useAccountStore } from "@/stores/account";
import { useLayoutStore } from "@/stores/layout";


export default /*@__PURE__*/_defineComponent({
  __name: 'MainLayout',
  setup(__props, { expose: __expose }) {
  __expose();

const userStore = useUserStore();
const accountStore = useAccountStore();
const layoutStore = useLayoutStore();

if (userStore.accessToken) {
  userStore.getMe().then(() => {
    loadLocaleMessages(userStore.currentUser?.language as string);
    if (!userStore.currentUser?.settings) {
      userStore.updateUser({ settings: accountStore.defaultSettings });
    } else {
      const settingsFromApi = userStore.currentUser.settings;
      delete settingsFromApi.id;
      accountStore.setSettings(settingsFromApi, true);
    }
  });
}
const onMouseLeave = (event: MouseEvent) => {
  if (event.clientY > 0) {
    layoutStore.changeAppHeaderInAbsolutePosState(false);
  }
};

const __returned__ = { userStore, accountStore, layoutStore, onMouseLeave, HeaderComponent, get useUserStore() { return useUserStore }, get loadLocaleMessages() { return loadLocaleMessages }, get useAccountStore() { return useAccountStore }, get useLayoutStore() { return useLayoutStore } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})