import type { Card } from "./Combos";

/**
 * Given a list of cards, and a number, iterate over all subsets
 * of cards of that length.
 */
export class ComboIterator {
  private readonly cards: Card[];
  private readonly numCards: number;

  // This tracks the next set of indices that we're going to return
  private indices: number[];
  private done: boolean;

  constructor(cards: Card[], k: number) {
    this.cards = cards;
    this.numCards = k;
    this.indices = Array.from({ length: this.numCards }, (_, index) => index);
    this.done = false;
  }

  hasNext(): boolean {
    return !this.done;
  }

  next(): Card[] {
    const result = this.indices.map((i) => this.cards[i]);
    this.updateIndices();
    return result;
  }

  /**
   * Update to the next set of indices. Increment the right-most index that
   * isn't already maxes out.
   *
   * i.e.
   * if we have three indices [0,1,2] for five cards (indices run 0 to 4),
   * We increment the 2 as much as we can
   *
   * [0, 1, 2]
   * [0, 1, 3]
   * [0, 1, 4]
   *
   * Until its at its max possible index, then we increment the next right most, 1 here
   *
   * [0, 2, 3]
   *
   * When we do, make all subsequent indices as small as possible
   *
   * [0, 2, 4]
   * [1, 2, 3]
   * [1, 2, 4]
   * [1, 3, 4]
   * [2, 3, 4]
   *
   * k is the index that we're going to increment, then we'll set each subsequent index
   * to consecutive values.
   */
  updateIndices() {
    let k = this.indices.length - 1;
    const unusedCount = this.cards.length - this.indices.length;
    while (k >= 0 && this.indices[k] === k + unusedCount) {
      k--;
    }

    if (k >= 0) {
      let index = this.indices[k];
      for (let i = k; i < this.indices.length; i++) {
        index++;
        this.indices[i] = index;
      }
    } else {
      this.done = true;
    }
  }
}
