import { ref } from "vue";
import { defineStore } from "pinia";
import authApi from "@/services/authApi";
import type { UserInfo } from "@/types/user";
import userApi from "@/services/userApi";

export const useUserStore = defineStore(
  "user",
  () => {
    const currentUser = ref<UserInfo | null>(null);
    const accessToken = ref(localStorage.getItem("accessToken"));
    const refreshToken = ref(localStorage.getItem("refreshToken"));
    const rememberMe = ref(false);
    const rememberedEmail = ref<string>("");

    const setTokens = (payload: {
      accessToken: string;
      refreshToken: string;
      rememberMe?: boolean;
    }): void => {
      refreshToken.value = payload.refreshToken;
      accessToken.value = payload.accessToken;
      if (typeof payload.rememberMe === "boolean") {
        rememberMe.value = payload.rememberMe;
      }
      localStorage.setItem("accessToken", payload.accessToken);
      if (rememberMe.value || localStorage.getItem("refreshToken")) {
        localStorage.setItem("refreshToken", payload.refreshToken);
      }
    };

    const setRememberedEmail = (value: string) => {
      rememberedEmail.value = value;
    };

    const clear = () => {
      currentUser.value = null;
      accessToken.value = "";
      refreshToken.value = "";
      rememberMe.value = false;
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
    };

    const logout = () => {
      return authApi.logout().then((res) => {
        clear();
        return res;
      });
    };

    const getMe = () => {
      return userApi.getMe().then((res) => {
        currentUser.value = res.data.data;
        return res;
      });
    };

    const updateUser = (data: Partial<UserInfo>) => {
      return userApi.updateUser(data).then((res) => {
        currentUser.value = res.data.data;
        return res;
      });
    };

    return {
      accessToken,
      clear,
      currentUser,
      getMe,
      logout,
      refreshToken,
      rememberMe,
      rememberedEmail,
      setRememberedEmail,
      setTokens,
      updateUser,
    };
  },
  {
    persist: true,
    share: {
      initialize: false,
    },
  }
);
