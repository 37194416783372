import { defineComponent as _defineComponent } from 'vue'
import type { ToastOptions } from "vue3-toastify";

interface Props {
  closeToast: (e?: MouseEvent) => void;
  toastProps: ToastOptions;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AppToaster',
  props: {
    closeToast: { type: Function },
    toastProps: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();



const __returned__ = {  }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})