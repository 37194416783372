import { defineComponent as _defineComponent } from 'vue'
import { onBeforeUnmount, watch } from "vue";

interface Props {
  isOpen: boolean;
  modalClass?: string;
  isCloseButton?: boolean;
}
interface Emits {
  (e: "close"): void;
}


export default /*@__PURE__*/_defineComponent({
  ...{
  inheritAttrs: false,
},
  __name: 'AppModal',
  props: {
    isOpen: { type: Boolean },
    modalClass: {},
    isCloseButton: { type: Boolean, default: true }
  },
  emits: ["close"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const emits = __emit;

const close = () => {
  emits("close");
};
const keyPress = (event: KeyboardEvent) => {
  if (event && event.code === "Escape") {
    close();
  }
};


onBeforeUnmount(() => {
  document.removeEventListener("keydown", keyPress);
});
watch(
  () => props.isOpen,
  (newValue) => {
    if (newValue) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  },
  {
    immediate: true,
  }
);

document.addEventListener("keydown", keyPress);

const __returned__ = { props, emits, close, keyPress, onBeforeUnmount, watch }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})