import { getPlayerStackWithContrib } from "@/features/pokerTable";
import {
  isActionWithData,
  isFoldAction,
  isTerminatedAction,
} from "@/features/sequence/action";
import { PlayerWithAdditionalInfo } from "@/entities/Player";
import { SeqActionStateEnum } from "@/enums/sequenceEnum";
import { PlayerStateEnum } from "@/enums/playerEnum";
import { PokerActionsEnum } from "@/poker-query-lib/enums/poker-actions.enum";
import type { SeqActionAny } from "@/types/seqAction";
import type { Player } from "@/entities/Player";

const isPlayerFromSeqFolded = (
  sequence: SeqActionAny[],
  seqPointer: number,
  playerIdx: number
) => {
  const playerActions = sequence.filter((action, idx) => {
    if (isActionWithData(action)) {
      return action.stateInfo.playerIndex === playerIdx && idx < seqPointer;
    }
    return false;
  });
  return playerActions.some((action) => isFoldAction(action));
};

const isCallEnd = (actionValue: string, actionState: SeqActionStateEnum) => {
  return (
    actionState === SeqActionStateEnum.performed &&
    [`${PokerActionsEnum.CALL}/`, `${PokerActionsEnum.CALL}/$`].includes(
      actionValue
    )
  );
};

const isActivePlayer = (activeAction: SeqActionAny, playerIndex: number) => {
  return (
    isActionWithData(activeAction) &&
    playerIndex === activeAction.stateInfo.playerIndex &&
    !isTerminatedAction(activeAction)
  );
};

const getPlayerState = (
  sequence: SeqActionAny[],
  seqPointer: number,
  activeAction: SeqActionAny,
  playerIndex: number
) => {
  const isFoldedState = isPlayerFromSeqFolded(
    sequence,
    seqPointer,
    playerIndex
  );
  const isActiveState = isActivePlayer(activeAction, playerIndex);
  if (isFoldedState) {
    return PlayerStateEnum.folded;
  } else if (isActiveState) {
    return PlayerStateEnum.active;
  }
  return PlayerStateEnum.regular;
};

const getActionValue = (
  sequence: SeqActionAny[],
  activeAction: SeqActionAny
) => {
  const findLastActionWithValue = () => {
    for (let i = sequence.length - 1; i >= 0; i--) {
      if ("chosenAction" in sequence[i]) {
        return sequence[i];
      }
    }
    return null;
  };
  const action = isTerminatedAction(activeAction)
    ? findLastActionWithValue()
    : activeAction;
  return action && isActionWithData(action) ? action.chosenAction : "";
};

const getStackWithContrib = (
  sequence: SeqActionAny[],
  activeAction: SeqActionAny,
  playerIndex: number
) => {
  const actionValue = getActionValue(sequence, activeAction);
  const stateInfo =
    isTerminatedAction(activeAction) || isActionWithData(activeAction)
      ? activeAction.stateInfo
      : undefined;
  const { stack, contrib } = getPlayerStackWithContrib(
    playerIndex,
    stateInfo,
    isCallEnd(actionValue, activeAction.state)
  );

  return {
    contrib,
    stack,
  };
};

export const getPlayersWithAdditionalInfo = (
  playerList: Player[] | undefined,
  sequence: SeqActionAny[],
  seqPointer: number
) => {
  if (!playerList) {
    return [];
  }
  const activeAction = sequence[seqPointer];

  return playerList.map((player) => {
    const { playerIndex } = player;
    const playerState = getPlayerState(
      sequence,
      seqPointer,
      activeAction,
      playerIndex
    );
    const stackWithContrib = getStackWithContrib(
      sequence,
      activeAction,
      playerIndex
    );

    return new PlayerWithAdditionalInfo(player, stackWithContrib, playerState);
  });
};
