import { createRouter, createWebHistory } from "vue-router";
import routes from "@/router/routes";
import { useGameStore } from "@/stores/game";
import routesName from "@/constants/routesName";
import { useUserStore } from "@/stores/user";
import { useParsedLibDataStore } from "@/stores/parsedLibData";
import {
  handleProtectedRoute,
  isGameSetupWithoutQuery,
  shouldRedirectUnauthorizedUser,
} from "@/features/routerGuardFunctions";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const gameStore = useGameStore();
  const userStore = useUserStore();
  const hasAccessToken = userStore.accessToken;
  gameStore.setCurrentPageName(to.name as string);

  if (shouldRedirectUnauthorizedUser(to, hasAccessToken)) {
    return next({ name: routesName.preflop });
  }

  if (isGameSetupWithoutQuery(to)) {
    return next({
      name: routesName.gameSetup,
      query: { redirect: "/preflop" },
    });
  }

  const protectedRouteResult = handleProtectedRoute(to, gameStore, userStore);

  if (protectedRouteResult.shouldClearMatrix) {
    const { clearMatrixData } = useParsedLibDataStore();
    clearMatrixData();
  }

  if (protectedRouteResult.shouldRedirect) {
    return next({
      name: protectedRouteResult.redirectName,
      query: { redirect: protectedRouteResult.redirectQuery },
    });
  }

  if (protectedRouteResult.shouldContinue) {
    return next();
  }

  return next();
});

router.afterEach(() => {
  const gameStore = useGameStore();
  gameStore.setIsSharingLinkInvalid(false);
});

export default router;
