import { vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "filter" }
const _hoisted_2 = ["type", "value", "disabled"]
const _hoisted_3 = { class: "filter__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      class: "filter__input",
      type: $props.type ? $props.type : 'checkbox',
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.model) = $event)),
      value: $props.value,
      onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.clearSelection($props.value))),
      disabled: $props.isDisabled
    }, null, 8, _hoisted_2), [
      [_vModelDynamic, $setup.model]
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["filter__wrapper", { 'filter__wrapper--disabled': $props.isDisabled }])
    }, [
      _createElementVNode("span", _hoisted_3, _toDisplayString($setup.defineOutput($props.label)), 1)
    ], 2)
  ]))
}