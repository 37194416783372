import { defineComponent as _defineComponent } from 'vue'
import { computed, ref } from "vue";

interface Props {
  isActiveFilters?: boolean;
  isDimmed?: boolean;
  showLabel?: boolean;
  queryAreaMode?: boolean;
  isGameEnd?: boolean;
}
interface Emits {
  (e: "click-on-query-area"): void;
  (e: "reset-filters"): void;
  (e: "on-reset"): void;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'NoDataOverlay',
  props: {
    isActiveFilters: { type: Boolean },
    isDimmed: { type: Boolean },
    showLabel: { type: Boolean },
    queryAreaMode: { type: Boolean },
    isGameEnd: { type: Boolean }
  },
  emits: ["click-on-query-area", "reset-filters", "on-reset"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();



const emits = __emit;

const isWrapperHover = ref<boolean>(false);
const logoSrc = computed(() => {
  return isWrapperHover.value
    ? require("@/assets/icons/logo.svg")
    : require("@/assets/icons/white-logo-icon.svg");
});

const resetFilters = () => {
  emits("reset-filters");
};
const onReset = () => {
  emits("on-reset");
};

const __returned__ = { emits, isWrapperHover, logoSrc, resetFilters, onReset, computed, ref }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})