const routesName = {
  preflop: "preflop",
  quiz: "quiz",
  study: "study",
  login: "login",
  registration: "registration",
  gameSetup: "gameSetup",
  resetPassword: "resetPassword",
  forgotPassword: "forgotPassword",
  verifyEmail: "verifyEmail",
  authRedirect: "authRedirect",
  settings: "settings",
  accountSettings: "accountSettings",
  subscription: "subscription",
};

export default routesName;
