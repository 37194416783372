import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "color-picker__wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "color-picker__tooltip"
}
const _hoisted_3 = { id: "color-picker" }
const _hoisted_4 = { class: "action-label" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "color-picker__header" }
const _hoisted_7 = { class: "color-picker__title" }
const _hoisted_8 = { class: "controls" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.withRaiseTooltip)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "color-picker__tooltip-icon",
          onMouseover: _cache[0] || (_cache[0] = ($event: any) => ($setup.isShowTooltip = true)),
          onMouseleave: _cache[1] || (_cache[1] = ($event: any) => ($setup.isShowTooltip = false))
        }, null, 32))
      : _createCommentVNode("", true),
    _createVNode(_Transition, {
      name: "fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        ($setup.isShowTooltip)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[3] || (_cache[3] = [
              _createElementVNode("div", { class: "tooltip__row" }, [
                _createElementVNode("p", { class: "tooltip__row-text" }, "Small"),
                _createElementVNode("p", { class: "tooltip__row-text" }, "Below 40%")
              ], -1),
              _createElementVNode("div", { class: "tooltip__row" }, [
                _createElementVNode("p", { class: "tooltip__row-text" }, "Medium"),
                _createElementVNode("p", { class: "tooltip__row-text" }, "41% - 69%")
              ], -1),
              _createElementVNode("div", { class: "tooltip__row" }, [
                _createElementVNode("p", { class: "tooltip__row-text" }, "Large"),
                _createElementVNode("p", { class: "tooltip__row-text" }, "70% - 100%")
              ], -1),
              _createElementVNode("div", { class: "tooltip__row" }, [
                _createElementVNode("p", { class: "tooltip__row-text" }, "Overbet"),
                _createElementVNode("p", { class: "tooltip__row-text" }, "Above 100%")
              ], -1)
            ])))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "action",
        onClick: $setup.showPicker
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["action-color", { 'action-color--rectangle': $props.isRectangle }])
        }, null, 2),
        _createElementVNode("div", _hoisted_4, _toDisplayString($props.actionLabel), 1)
      ]),
      ($setup.isShowPicker)
        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode($setup["ColorPicker"], {
              "pure-color": $setup.selectedColor,
              "onUpdate:pureColor": _cache[2] || (_cache[2] = ($event: any) => (($setup.selectedColor) = $event)),
              "disable-history": true,
              "disable-alpha": true,
              "is-widget": true,
              "picker-container": "#color-picker",
              format: $props.format ? $props.format : 'rgb',
              shape: "square"
            }, {
              extra: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("p", _hoisted_7, _toDisplayString($props.actionLabel), 1),
                  _createElementVNode("div", {
                    class: "color-picker__cross-icon",
                    onClick: $setup.toggleShowPicker
                  })
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode($setup["AppButton"], {
                    class: "controls-button--reset",
                    "is-outline": true,
                    onOnClick: $setup.resetColor
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("reset")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode($setup["AppButton"], {
                    class: "controls-button--confirm",
                    "is-disabled": $setup.isDisabledConfirm,
                    onOnClick: $setup.confirmSelectedColor
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("confirm")), 1)
                    ]),
                    _: 1
                  }, 8, ["is-disabled"])
                ])
              ]),
              _: 1
            }, 8, ["pure-color", "format"])
          ])), [
            [_directive_click_outside, () => ($setup.isShowPicker = false)]
          ])
        : _createCommentVNode("", true)
    ])
  ]))
}