import { defineComponent as _defineComponent } from 'vue'
import { ref } from "vue";

interface Props {
  position?: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AppDropdown',
  props: {
    position: { default: "right bottom" }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;

const isOpen = ref<boolean>(false);

const __returned__ = { props, isOpen, ref }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})