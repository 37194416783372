import { axiosNoCache } from "@/services/api/index";
import type { ApiClientResponse } from "@/types/api/ApiClientResponse";

export default {
  login(payload: {
    email: string;
    password: string;
  }): Promise<
    ApiClientResponse<{ accessToken: string; refreshToken: string }>
  > {
    return axiosNoCache.post("auth/sign-in", payload);
  },
  logout() {
    return axiosNoCache.post("auth/logout");
  },
  registration(payload: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }): Promise<
    ApiClientResponse<{ accessToken: string; refreshToken: string }>
  > {
    return axiosNoCache.post("auth/sign-up", payload);
  },
  refreshToken(payload: {
    refreshToken: string;
  }): Promise<
    ApiClientResponse<{ accessToken: string; refreshToken: string }>
  > {
    return axiosNoCache.post("auth/refresh-tokens", payload);
  },
  forgotPassword(payload: {
    email: string;
  }): Promise<
    ApiClientResponse<{ accessToken: string; refreshToken: string }>
  > {
    return axiosNoCache.patch("auth/forgot-password", payload);
  },
  resetPassword(payload: {
    password: string;
    token: string;
  }): Promise<ApiClientResponse<null>> {
    return axiosNoCache.patch("auth/reset-password", payload);
  },
  verifyEmail(
    token: string
  ): Promise<ApiClientResponse<{ accessToken: string; refreshToken: string }>> {
    return axiosNoCache.patch("auth/verify-email", { token });
  },
  retryEmailVerification(email: string): Promise<ApiClientResponse<null>> {
    return axiosNoCache.patch("auth/retry-email-verification", { email });
  },
  verifyOAuthCode(
    code: string,
    authProvider: string
  ): Promise<ApiClientResponse<{ accessToken: string; refreshToken: string }>> {
    return axiosNoCache.get(`auth/${authProvider}/redirect?code=${code}`, {
      params: {
        callbackUrl:
          process.env.VUE_APP_LOCAL_OAUTH === "true"
            ? `http://localhost:8080/auth/${authProvider}/redirect`
            : null,
      },
    });
  },
  triggerOAuth(
    authProvider: string
  ): Promise<ApiClientResponse<{ redirectUrl: string }>> {
    return axiosNoCache.get(`auth/${authProvider}`, {
      params: {
        callbackUrl:
          process.env.VUE_APP_LOCAL_OAUTH === "true"
            ? `http://localhost:8080/auth/${authProvider}/redirect`
            : null,
      },
    });
  },
};
