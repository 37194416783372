import { CardSuitEnum } from "@/enums/cardEnum";
import { PlayerCardStateEnum, PlayerCardTypeEnum } from "@/enums/playerEnum";
import type { ShortSuitEnum } from "@/enums/cardEnum";
import type { PlayerCard } from "@/types/player";

export const parseCardsFromString = (cardString?: string): string[] => {
  if (!cardString) {
    return [];
  }

  const cardRegex = /[2-9TJQKA][chds]/g;

  return cardString.match(cardRegex) || [];
};

export const createDeck = (isShortDeck = false): string[] => {
  const suits = ["s", "h", "d", "c"];
  const fullDeckRanks = [
    "A",
    "K",
    "Q",
    "J",
    "T",
    "9",
    "8",
    "7",
    "6",
    "5",
    "4",
    "3",
    "2",
  ];
  const shortDeckRanks = ["A", "K", "Q", "J", "T", "9", "8", "7", "6"];

  const ranks = isShortDeck ? shortDeckRanks : fullDeckRanks;
  const deck: string[] = [];

  suits.forEach((suit) => {
    ranks.forEach((rank) => {
      deck.push(`${rank}${suit}`);
    });
  });

  return deck;
};

export const createCardSequence = (
  suit: ShortSuitEnum,
  excludeCards: string[],
  isShortDeck = false
): string[] => {
  const defaultDeckRanks = [
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "T",
    "J",
    "Q",
    "K",
    "A",
  ];
  const shortDeckRanks = ["6", "7", "8", "9", "T", "J", "Q", "K", "A"];
  const ranks = isShortDeck ? shortDeckRanks : defaultDeckRanks;

  return ranks
    .map((rank) => rank + suit)
    .filter((card) => !excludeCards.includes(card));
};

export const sortCards = (cards: string[]) => {
  const rankOrder = "AKQJT98765432";
  const suitOrder = "shdc";
  return cards.sort(
    (a, b) =>
      rankOrder.indexOf(a[0]) - rankOrder.indexOf(b[0]) ||
      suitOrder.indexOf(a[1]) - suitOrder.indexOf(b[1])
  );
};

export const getRandomCards = (
  numberOfCards: number,
  excludedCards: string[] = []
) => {
  const deck = createDeck();
  const filteredDeck = deck.filter((card) => !excludedCards.includes(card));
  const shuffledDeck = filteredDeck
    .map((card) => ({
      card,
      sortKey: crypto.getRandomValues(new Uint32Array(1))[0] / (2 ** 32 - 1),
    }))
    .sort((a, b) => a.sortKey - b.sortKey)
    .map(({ card }) => card);

  return shuffledDeck.slice(0, numberOfCards);
};

export const createEmptyDraftUpCard = () => {
  return {
    isBack: false,
    state: PlayerCardStateEnum.draft,
    type: PlayerCardTypeEnum.upCard,
    value: CardSuitEnum.empty,
  };
};

export const isCardEmpty = (card: string) => card === CardSuitEnum.empty;
export const isCardDraft = (card: PlayerCard) => {
  return "state" in card && card.state === PlayerCardStateEnum.draft;
};

export const findLowestCardIndex = (cards: string[]) => {
  const valueRank = {
    "2": 1,
    "3": 2,
    "4": 3,
    "5": 4,
    "6": 5,
    "7": 6,
    "8": 7,
    "9": 8,
    T: 9,
    J: 10,
    Q: 11,
    K: 12,
    A: 13,
  };

  const getCardValue = (card: string) => {
    const cardValue = card.slice(0, -1) as keyof typeof valueRank;
    return valueRank[cardValue];
  };

  let lowestIndex = 0;
  let lowestValue = getCardValue(cards[0]);

  for (let i = 1; i < cards.length; i++) {
    const currentValue = getCardValue(cards[i]);

    if (currentValue < lowestValue) {
      lowestValue = currentValue;
      lowestIndex = i;
    }
  }

  return lowestIndex;
};
