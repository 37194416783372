import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "@/styles/styles.scss";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { PiniaSharedState } from "pinia-shared-state";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { clickOutside } from "@/features/clickOutside";
import { RecycleScroller } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import Vue3Toasity, { type ToastContainerOptions } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import i18n from "@/i18n";
import Vue3ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";
import VueTippy from "vue-tippy";
import "tippy.js/dist/tippy.css";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
pinia.use(PiniaSharedState({ enable: true, initialize: true, type: "native" }));

const app = createApp(App);
app.directive("click-outside", clickOutside);
app.component("RecycleScroller", RecycleScroller);

const toastConfig = {
  autoClose: 3000,
  clearOnUrlChange: false,
  hideProgressBar: true,
  closeOnClick: false,
  dangerouslyHTMLString: true,
  icon: false,
  closeButton: false,
} as ToastContainerOptions;

const vueTippyProps = {
  defaultProps: {
    delay: [150, 0],
    duration: [0, 0],
  },
};

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    app,
    dsn: "https://8fd98e923d1e9d40a5a817f9dd663a9c@sentry.onix.team/203",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          "onix-systems-vue-poker-study-platform.staging.onix.ua",
          /^\//,
        ],
      }),
    ],
    tracesSampleRate: 1.0,
    environment: process.env.VUE_APP_ENV || "development",
    logErrors: true,
  });
}

app
  .use(pinia)
  .use(router)
  .use(Vue3ColorPicker)
  .use(Vue3Toasity, toastConfig)
  .use(i18n)
  .use(VueTippy, vueTippyProps)
  .mount("#app");
